<template>
  <div :class="'memePercent'">
    <img :src="img" />
  </div>
</template>

<script>
export default {
  props: ["uncovorScore", "img"],
  data() {
    return {};
  },
  mounted: function () {
    // console.log(this.uncovorScore);

    // let score = 64;

    // let quartileRange = [
    //   [0, 25],
    //   [25, 50],
    //   [50, 75],
    //   [75, 100],
    // ];

    // let quartileNo;

    // quartileRange.some(function (item, index) {
    //   if (score < item[1]) {
    //     quartileNo = index;

    //     // m = [score, eligibility, probability]
    //     let m = [score, 0];
    //     // console.log(m); // 17

    //     let m0 = [(m[0] / 5 - 1) * 5, 0];
    //     let m1 = [(m[0] / 5 + 1) * 5, 0];

    //     let prob;

    //     if (m0[0] > item[0] && m1[0] < item[1]) {
    //       prob = [1 / 5, 3 / 5, 1 / 5];
    //     } else if (m0[0] < item[0]) {
    //       prob = [0, 3 / 4, 1 / 4];
    //     } else if (m1[0] > item[1]) {
    //       prob = [1 / 4, 3 / 4, 0];
    //     }

    //     console.log(prob);
    //     console.log(
    //       ((m0[0] * prob[0] + m[0] * prob[1] + m1[0] * prob[2]) /
    //         (m0[0] + m[0] + m1[0])) *
    //         1
    //     );

    //     // if (m0[0] < item[0]) {
    //     //   m0[1] = false;
    //     // }

    //     // if (m1[0] > item[1]) {
    //     //   m1[1] = false;
    //     // }

    //     // let finalQuartile = [m0];

    //     return true;
    //   }
    // });

    // console.log(quartileNo);
  },
};
</script>

<style lang="scss" scoped>
.memePicker {
  img {
    max-width: 540px;
  }
}
</style>
