<template>
  <ValidationObserver ref="observer" v-slot="{ invalid }">
    <div class="salaryDetails">
      <div class="form-fields">
        <div class="form-bottom">
          <div class="input-groups">

            <!-- Base salary -->
            <div
              :class="`input-field ${
                salaryDetails.baseSalary.length > 0 ||
                show.salaryDetailsActive.baseSalary
                  ? 'active'
                  : ''
              }`"
            >
              <ValidationProvider :rules="{ required: true }" v-slot="{ errors }">
                <MyInput
                  v-model="salaryDetails.baseSalary"
                  id="baseSalary"
                  type="text"
                  format="currency"
                  labeltype="INR"
                  :className="`${errors.length > 0 ? 'error' : ''}`"
                  placeholder=" "
                  v-on:inputFocus="show.salaryDetailsActive.baseSalary = true"
                  v-on:inputBlur="show.salaryDetailsActive.baseSalary = false"
                />
                <span class="inputError">{{ errors[0] }}</span>
                <span class="inputMessage" v-if="salaryDetails.baseSalary">{{ inWords(salaryDetails.baseSalary) }}</span>
                <span class="placeholder">Annual Base Salary</span>
              </ValidationProvider>
              <div class="__tooltip">
                <div class="__tooltip--icon" @mouseover="showTooltip = 'baseSalary'" @mouseleave="showTooltip = ''"><ExclamationCircle></ExclamationCircle></div>
                <div v-if="showTooltip === 'baseSalary'" class="__tooltip--context-wrap">
                  <div class="__tooltip--context">
                    <p class="__tooltip--context-bold">What is base salary ?</p>
                    <p>It's a fixed amount paid to you in return for the work performed.</p>
                  </div>
                  <FeatherSVG class="__tooltip--feather"></FeatherSVG>
                </div>
              </div>
            </div>

            <!-- Relocation bonus -->
            <div
              :class="`input-field ${
                salaryDetails.relocationBonus.length > 0 ||
                show.salaryDetailsActive.relocationBonus
                  ? 'active'
                  : ''
              }`"
            >
              <MyInput
                labeltype="INR"
                type="text"
                format="currency"
                v-model="salaryDetails.relocationBonus"
                id="relocationBonus"
                placeholder=" "
                v-on:inputFocus="
                  show.salaryDetailsActive.relocationBonus = true
                "
                v-on:inputBlur="
                  show.salaryDetailsActive.relocationBonus = false
                "
              />
              <span class="placeholder">Relocation Bonus</span>
              <span class="inputMessage" v-if="salaryDetails.relocationBonus">{{ inWords(salaryDetails.relocationBonus) }}</span>
              <div class="__tooltip">
                <div class="__tooltip--icon" @mouseover="showTooltip = 'relocationBonus'" @mouseleave="showTooltip = ''"><ExclamationCircle></ExclamationCircle></div>
                <div v-if="showTooltip === 'relocationBonus'" class="__tooltip--context-wrap left-side">
                  <div class="__tooltip--context">
                    <p class="left-side">This is the sum total of all other benefits (gratuity, PF, meal, home internet, WFH setup, gym reimbursements, etc.) on an annual basis.</p>
                  </div>
                  <FeatherSVG class="__tooltip--feather"></FeatherSVG>
                </div>
              </div>
            </div>

            <!-- Sign-on 1 -->
            <div
              :class="`input-field ${
                salaryDetails.signOnBonus1.length > 0 ||
                show.salaryDetailsActive.signOnBonus1
                  ? 'active'
                  : ''
              }`"
            >
              <MyInput
                labeltype="INR"
                type="text"
                format="currency"
                v-model="salaryDetails.signOnBonus1"
                id="signOnBonus1"
                placeholder=" "
                v-on:inputFocus="show.salaryDetailsActive.signOnBonus1 = true"
                v-on:inputBlur="show.salaryDetailsActive.signOnBonus1 = false"
              />
              <span class="placeholder">Sign on bonus year 1</span>
              <span class="inputMessage" v-if="salaryDetails.signOnBonus1">{{ inWords(salaryDetails.signOnBonus1) }}</span>
              <div class="__tooltip">
                <div class="__tooltip--icon" @mouseover="showTooltip = 'signOnBonus1'" @mouseleave="showTooltip = ''"><ExclamationCircle></ExclamationCircle></div>
                <div v-if="showTooltip === 'signOnBonus1'" class="__tooltip--context-wrap">
                  <div class="__tooltip--context">
                    <p>Variable bonus provided/accrued to you as a function of your above expectations or outstanding performance. Typically this can range from 10-30% of your base for engineering roles and upto 100% for Investment Banking roles.</p>
                  </div>
                  <FeatherSVG class="__tooltip--feather"></FeatherSVG>
                </div>
              </div>
            </div>

            <!-- Sign-on 2 -->
            <div
              :class="`input-field ${
                salaryDetails.signOnBonus2.length > 0 ||
                show.salaryDetailsActive.signOnBonus2
                  ? 'active'
                  : ''
              }`"
            >
              <MyInput
                labeltype="INR"
                type="text"
                format="currency"
                v-model="salaryDetails.signOnBonus2"
                id="signOnBonus2"
                placeholder=" "
                v-on:inputFocus="show.salaryDetailsActive.signOnBonus2 = true"
                v-on:inputBlur="show.salaryDetailsActive.signOnBonus2 = false"
              />
              <span class="placeholder"> Sign on bonus year 2 </span>
              <span class="inputMessage" v-if="salaryDetails.signOnBonus1">{{ inWords(salaryDetails.signOnBonus2) }}</span>
              <div class="__tooltip">
                <div class="__tooltip--icon" @mouseover="showTooltip = 'signOnBonus2'" @mouseleave="showTooltip = ''"><ExclamationCircle></ExclamationCircle></div>
                <div v-if="showTooltip === 'signOnBonus2'" class="__tooltip--context-wrap left-side">
                  <div class="__tooltip--context">
                    <p class="left-side">Variable bonus provided/accrued to you as a function of your above expectations or outstanding performance. Typically this can range from 10-30% of your base for engineering roles and upto 100% for Investment Banking roles</p>
                  </div>
                  <FeatherSVG class="__tooltip--feather"></FeatherSVG>
                </div>
              </div>
            </div>

            <!-- Performance Bonus -->
            <div
              :class="`input-field ${
                salaryDetails.performanceBonus.length > 0 ||
                show.salaryDetailsActive.performanceBonus
                  ? 'active'
                  : ''
              }`"
            >
              <MyInput
                labeltype="INR"
                type="text"
                format="currency"
                v-model="salaryDetails.performanceBonus"
                id="performanceBonus"
                placeholder=" "
                v-on:inputFocus="
                  show.salaryDetailsActive.performanceBonus = true
                "
                v-on:inputBlur="
                  show.salaryDetailsActive.performanceBonus = false
                "
              />
              <span class="placeholder">Performance bonus</span>
              <span class="inputMessage" v-if="salaryDetails.performanceBonus">{{ inWords(salaryDetails.performanceBonus) }}</span>
              <div class="__tooltip">
                <div class="__tooltip--icon" @mouseover="showTooltip = 'performanceBonus'" @mouseleave="showTooltip = ''"><ExclamationCircle></ExclamationCircle></div>
                <div v-if="showTooltip === 'performanceBonus'" class="__tooltip--context-wrap">
                  <div class="__tooltip--context">
                    <p>Variable bonus provided/accrued to you as a function of your above expectations or outstanding performance. Typically this can range from 10-30% of your base for engineering roles and upto 100% for Investment Banking roles.</p>
                  </div>
                  <FeatherSVG class="__tooltip--feather"></FeatherSVG>
                </div>
              </div>
            </div>

            <!-- Retention Bonus -->
            <div
              :class="`input-field ${
                salaryDetails.retentionBonus.length > 0 ||
                show.salaryDetailsActive.retentionBonus
                  ? 'active'
                  : ''
              }`"
            >
              <MyInput
                labeltype="INR"
                type="text"
                format="currency"
                v-model="salaryDetails.retentionBonus"
                id="retentionBonus"
                placeholder=" "
                v-on:inputFocus="show.salaryDetailsActive.retentionBonus = true"
                v-on:inputBlur="show.salaryDetailsActive.retentionBonus = false"
              />
              <span class="placeholder">Retention bonus</span>
              <span class="inputMessage" v-if="salaryDetails.retentionBonus">{{ inWords(salaryDetails.retentionBonus) }}</span>
              <div class="__tooltip">
                <div class="__tooltip--icon" @mouseover="showTooltip = 'retentionBonus'" @mouseleave="showTooltip = ''"><ExclamationCircle></ExclamationCircle></div>
                <div v-if="showTooltip === 'retentionBonus'" class="__tooltip--context-wrap left-side">
                  <div class="__tooltip--context">
                    <p class="left-side">Typically provided with a clawback, retention bonus or loyalty bonus or deferred bonus is the bonus that is provided to you so as to create a barrier to exit for the employee. All retention bonus's are paid at the start of the year but are subject to clawback if the employee decides to quit within the same year.</p>
                  </div>
                  <FeatherSVG class="__tooltip--feather"></FeatherSVG>
                </div>
              </div>
            </div>

            <div class="form-subtitle">
              <div class="form-subtitle__container">
                <span>Your stock grants</span> 
                <div class="__tooltip">
                  <div class="__tooltip--icon" @mouseover="showTooltip = 'totalStockGrant'" @mouseleave="showTooltip = ''"><ExclamationCircle></ExclamationCircle></div>
                  <div v-if="showTooltip === 'totalStockGrant'" class="__tooltip--context-wrap">
                    <div class="__tooltip--context">
                      <p>The total USD value of your ESOPs/RSUs/stock grants, if applicable.</p>
                    </div>
                    <FeatherSVG class="__tooltip--feather"></FeatherSVG>
                  </div>
                </div>
              </div>
            </div>

            <!-- Total Stocks Grant -->
            <div
              :class="`input-field ${
                salaryDetails.totalStocksGrant.length > 0 ||
                show.salaryDetailsActive.totalStocksGrant
                  ? 'active'
                  : ''
              }`"
            >
              <MyInput
                labeltype="USD"
                type="text"
                format="currency"
                v-model="salaryDetails.totalStocksGrant"
                id="totalStocksGrant"
                placeholder=" "
                v-on:inputFocus="show.salaryDetailsActive.totalStocksGrant = true"
                v-on:inputBlur="show.salaryDetailsActive.totalStocksGrant = false"
              />
              <span class="placeholder">Total stocks grant</span>
              <span class="inputMessage" v-if="salaryDetails.totalStocksGrant">{{ inMillions(salaryDetails.totalStocksGrant) }}</span>
            </div>

            <!-- Vesting Schedule -->
            <div
              :class="`input-field type-dropdown ${
                salaryDetails.vestingSchedule.schedule || show.vestingDropdown
                  ? show.vestingDropdown
                    ? 'dropdown active'
                    : 'active'
                  : ''
              }`"
            >
              <MyInput
                type="text"
                id="vestingSchedule"
                v-model="salaryDetails.vestingSchedule.schedule"
                name="vestingSchedule"  
                autocomplete="off"
                placeholder=" "
                v-on:inputFocus="show.vestingDropdown = true"
                v-on:inputBlur="show.vestingDropdown = false"
              />
              <span class="placeholder">Vesting Schedule</span>
              <ul
                class="search-dropdown"
                v-if="
                  vestingScheduleList.length > 0 &&
                  show.vestingDropdown
                "
              >
                <li
                  :key="'v'+vest.id"
                  v-for="vest in vestingScheduleList"
                  @mousedown="
                    () => {
                      salaryDetails.vestingSchedule.schedule = vest.schedule;
                      salaryDetails.vestingSchedule.id = vest.id;
                      show.vestingDropdown = false;
                    }
                  "
                >
                  <small>{{ vest.schedule.replaceAll(" ", ", ") }}</small>
                </li>
              </ul>
            </div>

            <!-- Valuation at grant -->
            <div
              :class="`input-field ${
                salaryDetails.valuationAtGrant.length > 0 ||
                show.salaryDetailsActive.valuationAtGrant
                  ? 'active'
                  : ''
              }`"
            >
              <MyInput
                labeltype="USD"
                type="text"
                format="currency"
                v-model="salaryDetails.valuationAtGrant"
                id="valuationAtGrant"
                placeholder=" "
                v-on:inputFocus="show.salaryDetailsActive.valuationAtGrant = true"
                v-on:inputBlur="show.salaryDetailsActive.valuationAtGrant = false"
              />
              <span class="placeholder">Valuation at grant</span>
              <span class="inputMessage" v-if="salaryDetails.valuationAtGrant">{{ inMillions(salaryDetails.valuationAtGrant) }}</span>
              <div v-if="valGrantTooltip" class="__tooltip">
                <div class="__tooltip--icon" @mouseover="showTooltip = 'valuationAtGrant'" @mouseleave="showTooltip = ''"><ExclamationCircle></ExclamationCircle></div>
                <div v-if="showTooltip === 'valuationAtGrant'" class="__tooltip--context-wrap">
                  <div class="__tooltip--context">
                    <p>This is your company's valuation at the grant date. For public listed companies this is going to be the market capitalisation as found on your grant letter or on Yahoo Finance as on that date. For private listed companies, this could be the company's valuation at its last fund raise before the grant date.</p>
                  </div>
                  <FeatherSVG class="__tooltip--feather"></FeatherSVG>
                </div>
              </div>
            </div>

            

            <!-- Other benefits -->
            <!-- <div
              :class="`input-field ${
                salaryDetails.otherBenefits.length > 0 ||
                show.salaryDetailsActive.otherBenefits
                  ? 'active'
                  : ''
              }`"
            >
              <MyInput
                v-numericOnly
                labeltype="INR"
                type="number"
                v-model="salaryDetails.otherBenefits"
                id="otherBenefits"
                placeholder=" "
                v-on:inputFocus="show.salaryDetailsActive.otherBenefits = true"
                v-on:inputBlur="show.salaryDetailsActive.otherBenefits = false"
              />
              <span class="placeholder">Other benefits</span>
              <span class="inputMessage" v-if="show.salaryDetailsActive.otherBenefits">{{ inWords(salaryDetails.otherBenefits) }}</span>
              <div class="__tooltip">
                <div class="__tooltip--icon" @mouseover="showTooltip = 'otherBenefits'" @mouseleave="showTooltip = ''"><ExclamationCircle></ExclamationCircle></div>
                <div v-if="showTooltip === 'otherBenefits'" class="__tooltip--context-wrap left-side">
                  <div class="__tooltip--context">
                    <p class="left-side">Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis nemo voluptas corporis optio excepturi eum voluptate sunt dolore, molestias blanditiis illum porro ipsam, velit assumenda. Voluptas cumque pariatur libero sint.</p>
                  </div>
                  <FeatherSVG class="__tooltip--feather"></FeatherSVG>
                </div>
              </div>
            </div> -->
          </div>
          <div class="salaryDetails__button_section">
            <MyButton width="18%" type="tertiary" @click="() => $emit('back')"> Back </MyButton>
            <MyButton
              width="78%"
              :loading="salaryDetailsLoading"
              :disabled="invalid"
              v-on:click="conifrmSalary"
            >
              <template v-if="type == 'edit'"> Save details </template>
              <template v-else> Tell me my self worth </template>
            </MyButton>
          </div>
          <div class="terms">
            By proceeding, you agree to our
            <router-link to="/terms-conditions">
              terms and conditions
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <Modal
      :show="show.confirmSalary"
      :showLeftPart="false"
      :customClass="'my_modal_wrapper confirmSalary'"
      :title="'Proceed with the entered salary?'"
      v-on:closeModal="closeModal"
    >
      <div class="confirmSalary-content">
        <div class="confirmSalary-content__heading">
          <WarningTria></WarningTria><h4>Proceed with the entered salary?</h4>
        </div>
        <p>
          Please note that you will not be able to change the salary details
          later.
        </p>
        <p>
          Your profile approval depends on this data. Please note that your
          profile can be banned if a discrepancy is found.
        </p>
        <img src="@/assets/images/self-worth/confirm-salary.png" />

        <div class="btn-group">
          <MyButton
            :class="'back-btn'"
            :disabled="invalid"
            v-on:click="closeModal"
          >
            Go back
          </MyButton>
          <MyButton
            :loading="salaryDetailsLoading"
            :disabled="invalid"
            v-on:click="salaryDetailsBtnClick"
          >
            Got it, proceed
          </MyButton>
        </div>
      </div>
    </Modal>
  </ValidationObserver>
</template>

<script>
import MyInput from "../../components/input.vue";
import MyButton from "../../components/button.vue";
import Modal from "../../components/modal.vue";
import { ValidationProvider, extend, ValidationObserver } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { api } from "../../services/http-common.js";
import ExclamationCircle from "../../assets/svg/icons/exclamation-circle.svg?inline";
import WarningTria from "../../assets/svg/warning-tria.svg?inline";
import FeatherSVG from "../../assets/svg/feather.svg?inline";
import { cleanObject, inWords, inMillions, toNumber } from '../../helpers/utils'
import { numericOnly } from "../../helpers/directives";

extend("required", {
  ...required,
  message: "This field is required",
});

export default {
  directives: { numericOnly },
  data() {
    return {
      salaryDetailsLoading: false,
      show: {
        salaryDetails: true,
        salaryDetailsActive: {
          baseSalary: false,
          relocationBonus: false,
          signOnBonus1: false,
          signOnBonus2: false,
          retentionBonus: false,
          totalStocksGrant: false,
          valuationAtGrant: false,
          performanceBonus: false,
          otherBenefits: false,
        },
        confirmSalary: false,
        vestingDropdown: false,
      },
      salaryDetails: {
        baseSalary: "",
        relocationBonus: "",
        signOnBonus1: "",
        signOnBonus2: "",
        retentionBonus: "",
        totalStocksGrant: "",
        valuationAtGrant: "",
        performanceBonus: "",
        otherBenefits: "",
        vestingSchedule: {schedule: "", id: ""}
      },
      vestingScheduleList: [],
      showTooltip: '',
      windowWidth: ''
    };
  },
  props: ["loadingBtn", "type", "currentSalaryDetails"],
  components: {
    MyInput,
    MyButton,
    ValidationProvider,
    ValidationObserver,
    Modal,
    ExclamationCircle,
    FeatherSVG,
    WarningTria
  },
  mounted: function () {
    const cachedSalaryDetails = JSON.parse(localStorage.getItem('salaryDetails') ?? "{}")
    if (this.type == "edit") {
      this.salaryDetails = {
        baseSalary: this.currentSalaryDetails.base_salary_amount.toString(),
        relocationBonus:
          this.currentSalaryDetails.relocation_bonus_amount.toString(),
        signOnBonus1:
          this.currentSalaryDetails.sign_on_bonus.amount_1.toString(),
        signOnBonus2:
          this.currentSalaryDetails.sign_on_bonus.amount_2.toString(),
        retentionBonus:
          this.currentSalaryDetails.retention_bonus.amount_1.toString(),
        performanceBonus:
          this.currentSalaryDetails.performance_bonus_amount.toString(),
        otherBenefits: this.currentSalaryDetails.other_benefits.amount.toString(),
        totalStocksGrant: this.currentSalaryDetails.annual_esops.amount.toString(),
        vestingSchedule: {
          schedule: this.currentSalaryDetails.annual_esops.vesting_schedule
        },
        valuationAtGrant: this.currentSalaryDetails.annual_esops.valuation.toString(),
      };
    } else if(cachedSalaryDetails){
      this.salaryDetails = {
        ...this.salaryDetails,
        ...cachedSalaryDetails
      }
    }
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },
  computed: {
    userDetails() {
      return this.$store.state.userDetails;
    },
    valGrantTooltip() {
      return this.show.vestingDropdown ? this.windowWidth > 992 : true;
    }
  },
  methods: {
    inWords,
    inMillions,
    conifrmSalary() {
      document.querySelector("html").classList.add("noScroll");
      this.show.confirmSalary = true;
    },
    closeModal() {
      this.show.confirmSalary = false;
      document.querySelector("html").classList.remove("noScroll");
    },
    salaryDetailsBtnClick() {
      let current_workex_id;
      if (this.userDetails.work_ex?.length) {
        const {work_ex: workEx} = this.userDetails
        const filtered = workEx?.filter((item) => item.is_current);
        if (filtered.length) {
          current_workex_id = filtered[filtered.length - 1];
        } else {
          current_workex_id = workEx.length && workEx[workEx.length - 1];
        }
      }

      let salaryDetailsData = {
        // extra_data: {}, // what can this be?
        // is_current: true,
        // annual_ctc_amount: 0, // annual ctc - this is not asked in the form, only base salary
 
        sign_on_bonus: {
          amount_1: toNumber(this.salaryDetails.signOnBonus1),
          amount_2: toNumber(this.salaryDetails.signOnBonus2),
        },

        retention_bonus: {
          amount_1: toNumber(this.salaryDetails.retentionBonus)
        },

        annual_esops: {
          amount: toNumber(this.salaryDetails.totalStocksGrant),
          vesting_schedule: this.salaryDetails.vestingSchedule.schedule,
          valuation: toNumber(this.salaryDetails.valuationAtGrant),
        },

        // uploaded_salary_slip_url: "#", // how to get this?

        // retention_bonus_amount_2: this.salaryDetails.lastHikeDelta, // is this last hike delta?
        other_benefits: {
          amount: toNumber(this.salaryDetails.otherBenefits)
        },

        user_id: this.userDetails.id,
        workex_id: current_workex_id?.id
      };

      if (this.salaryDetails.performanceBonus) {
        salaryDetailsData.performance_bonus_amount = toNumber(this.salaryDetails.performanceBonus);
        // salaryDetailsData.performance_bonus_currency = 'INR';
      }

      if (this.salaryDetails.baseSalary) {
        salaryDetailsData.base_salary_amount = toNumber(this.salaryDetails.baseSalary);
        // salaryDetailsData.base_salary_currency = 'INR';
      }

      if (this.salaryDetails.relocationBonus) {
        salaryDetailsData.relocation_bonus_amount = toNumber(this.salaryDetails.relocationBonus);
        // salaryDetailsData.relocation_bonus_currency = 'INR';
      }

      salaryDetailsData = cleanObject(salaryDetailsData);

      let self = this;

      this.$refs.observer.validate().then((result) => {
        if (!result) {
          // eslint-disable-next-line
          console.log("Form validation errors!");
          return;
        }
        self.salaryDetailsLoading = true;

        api
          .post(`api/v1/salary/details/`, salaryDetailsData)
          .then(() => {
            this.fetchUserDetails();
            this.$emit("salaryDetailsBtnClick");
            self.salaryDetailsLoading = false;
            self.closeModal();
            // console.log(response);
          })
          .catch((error) => {
            self.salaryDetailsLoading = false;
            console.log(error);
          });
      });
    },
    async fetchUserDetails() {
      const response = await api.get(`api/v1/user/profile`)
      this.$store.commit("setUser", response.data);
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    }
  },
  beforeDestroy() { 
    localStorage.setItem('salaryDetails', JSON.stringify(this.salaryDetails))
    window.removeEventListener('resize', this.onResize); 
  },
  created() {
    api.get(`api/v1/vesting_schedule/all`).then((response) => {
      this.vestingScheduleList = response.data.data;
    }).catch(console.warn);
  }
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/mixins';
.salaryDetails {
  .form-fields {
    padding: 40px;
    margin-top: -28px;
    margin-bottom: -28px;

    .form-top {
      width: fit-content;
      span {
        color: #333;

        display: block;
        margin-bottom: 16px;
        text-align: left;
      }
      label {
        color: #333;
        font-weight: 500;
        padding-right: 16px;
        padding-left: 6px;
      }

      input {
        width: fit-content;
        cursor: pointer;
      }
    }

    .form-bottom {
      margin-top: 25px;
      .input-groups {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-top: 2px;

        .form-subtitle {
          width: 100%;
          margin-top: 30px;
          margin-bottom: -15px;
          display: flex;
          align-items: center;
          span {
            margin-right: 10px;
          }
          &__container {
            width: 165px;
            position: relative;
            text-align: left;
            padding-top: 7px;
            .__tooltip{
              right: 10px;
              top: 8px;
            }
          }
        }
        .input-field {
          width: 48%;
          margin-top: 28px;
          position: relative;
          @include respond(lg) {
            width: 100%;
          }

          span.placeholder {
            position: absolute;
            left: 58px;
            top: 0px;
            transform: translate(0, 16.5px);
            width: fit-content !important;
            color: #bbbbbb;
            padding: 0 8px;
            z-index: 0;
            transition: all 0.1s linear;
          }
          &.type-dropdown {
            span.placeholder {
              left: 14px;
            }
          }

          &.active {
            .placeholder {
              top: 0px;
              transform: translate(0, -12px);
              width: fit-content !important;
              z-index: 10;
              transition: all 0.1s linear;
              background: white;
            }
            ul.search-dropdown {
              display: block;
            }
          }

          input {
            position: relative;
            z-index: 9;
            background: none !important;
          }

          input:focus ~ .placeholder {
            top: 0;
            transform: translate(0, -12px);
            z-index: 10;
            transition: all 0.1s linear;
            background: white;
          }

          // Dropdown styles
          &.type-dropdown:after {
            content: "";
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='11' viewBox='0 0 15 11' fill='none'%3E%3Cpath d='M13.7826 1.26978C13.3879 0.851943 12.7232 0.851943 12.3285 1.26978L8.22701 5.61257C7.83239 6.03041 7.16761 6.03041 6.77299 5.61257L2.67146 1.26978C2.27684 0.851943 1.61205 0.851943 1.21743 1.26978L0.648478 1.8722C0.284495 2.25759 0.284495 2.86005 0.648478 3.24545L6.77299 9.73022C7.16761 10.1481 7.83239 10.1481 8.22701 9.73022L14.3515 3.24545C14.7155 2.86005 14.7155 2.25759 14.3515 1.8722L13.7826 1.26978Z' fill='%23C7C7C7'/%3E%3C/svg%3E");
            display: block;
            width: 15px;
            position: absolute;
            height: 10px;
            top: 20px;
            right: 16px;
            transition: all 0.15s linear;
          }

          &.dropdown {
            & > :first-child {
              width: 100%;
            }
          }
          ul.search-dropdown {
            display: none;
            position: absolute;
            border: 1px solid #0000004d;
            background: white;
            border-radius: 0 0 10px 10px;
            margin-top: 48px;
            z-index: 999;
            max-height: 200px;
            overflow: scroll;
            width: 100%;
            li {
              text-align: left;
              padding: 10px 16px;
              cursor: pointer;

              &:hover {
                background: #f0f2e3;
              }
            }
          }
        }
        .__tooltip {
          // width: 555px;
          // height: 455px;
          // padding: 10px;
          border-radius: 10px;
          position: absolute;
          top: 18px;
          right: 18px;
          width: 20px;
          height: 20px;
          z-index: 999;
          background-color: #FFF;
          
          &--context-wrap {
            transition: all .2s;
            opacity: 1;
            text-align: left;
            width: 250px;
            height: auto;
            border: 1.3px solid rgba(195,159,88,0.60);
            position: absolute;
            left: 2.2em;
            top: -1.8em;
            border-radius: 10px;
            background-color: white;
            &.left-side {
              transform: rotate(0deg) scaleX(-1);
              left: -16.6em;
            }
            &:not(.left-side) {
              @include respond(md) {
                transform: rotate(0deg) scaleX(-1);
                left: -16.6em;
              }
            }
          }
          &--context {
            border-radius: 8px;
            padding: 15px;
            background: rgb(255,255,255);
            background: linear-gradient(90deg, rgba(255,255,255,1) 44%, rgba(195,159,88,0.60) 100%, rgba(195,159,88,1) 100%);
            &-bold {
              font-weight: bold;
            }
            p {
              font-size: 0.8rem;
              padding-right: 30px;
            }
            .left-side {
              transform: rotate(0deg) scaleX(-1);
              padding-left: 30px;
              padding-right: 0;
            }
            &:not(.left-side) {
              @include respond(md) {
                p {
                  transform: rotate(0deg) scaleX(-1);
                  padding-left: 30px;
                  padding-right: 0;
                }
              }
            }
          }
          &--context-wrap:before {
            content: " ";
            position: absolute;
            background-color: white;
            width: 20px;
            height: 21px;
            top: 27px;
            left: -11px;
            transform: rotate(43deg);
            border-left: 1.3px solid rgba(195,159,88,0.60);
            border-bottom: 1.3px solid rgba(195,159,88,0.60);
            border-radius: 0 3em 8em / 0.5em 3em 0;
          }
          &--feather {
            position: absolute;
            right: -6%;
            bottom: 0%;
          }
        }

      }
    }
  }

  .terms {
    padding-top: 22px;
    line-height: 22px;
    padding-bottom: 20px;
    a {
      color: #9faa22;
      text-decoration: none;
      cursor: pointer!important;;
    }
  }
  &__button_section {
    display: flex;
    justify-content: space-between;
    @include respond(sm) {
      display: block;
      :deep(.my_button_container) {
        width: 100% !important;
      }
    }
  }
}

input {
  width: 100%;
  padding: 18px 38px 14px 18px;
  font-weight: 400;
  font-size: 16px;
  color: black;
  outline: none;
  border-radius: 10px;
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.18);

  &::placeholder {
    color: #adadad;
  }

  &:focus {
    border: 1px solid #0000004d !important;
  }

  &.error {
    border-color: red;
  }
}

.inputError {
  display: flex;
  text-align: left;
  margin-top: 2px;
  align-self: flex-start;
  font-size: 12px;
  color: red;
}
.inputMessage {
  display: flex;
  text-align: left;
  margin-top: 2px;
  align-self: flex-start;
  font-size: 12px;
  max-width: 100%;
  white-space: pre-wrap;
  text-transform: capitalize;
}

.confirmSalary-content {
  padding: 6%;
  text-align: left;

  h4 {
    font-size: 32px;
    font-weight: bold;
    @include respond(md) {
      font-size: 16px;
    }
  }

  p {
    color: #333333;
    font-size: 14px;
    line-height: 20px;
    width: 50%;
    margin-bottom: 16px;
  }

  .btn-group {
    display: flex;
    margin-top: 40px;
    @include respond(sm) {
      position: absolute;
      z-index: 1;
    }

    .back-btn {
      margin-right: 16px;
    }
  }

  img {
    position: absolute;
    right: 0;
    max-width: 300px;
    bottom: 0;
  }
  &__heading {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    & > :first-child {
      margin-right: 15px;
    }
  }
}

::v-deep {
  .my_button_wrapper {
    button {
      margin-top: 16px;
      padding: 14px 40px;
    }
  }

  .input-field.group {
    .my_input_wrapper {
      width: 100%;
    }
  }

  .my_input_wrapper {
    z-index: 9;
    input {
      background: none !important;
    }
  }

  .form-bottom {
    button {
      width: 100%;
      margin-top: 32px;
    }
  }

  .back-btn {
    button {
      background: #dbdeba !important;
      color: black !important;

      &:hover {
        box-shadow: none !important;
      }
    }
  }

  @media (max-width: 768px) {
    .btn-group {
      button {
        padding: 14px 28px !important;
      }
    }

    .confirmSalary .modal_content_wrapper .modal_content {
      overflow: hidden !important;
    }
  }
}

@media (max-width: 768px) {
  .salaryDetails {
    .my_input_wrapper input {
      width: 100%;
    }
  }

  .confirmSalary-content {
    padding: 9%;
    margin-top: 60px;

    p {
      width: 100%;
    }

    .btn-group {
      margin-top: 0;
    }

    img {
      right: -6px;
      bottom: -4px;
      max-width: 250px;
    }
  }
}
</style>
