<template>
  <footer>
    <div class="footer_content_wrapper">
      <div class="top_content">
        <img src="@/assets/images/logo_full.svg" alt="UNCOVOR" />
      </div>
      <div class="bottom_content">
        <div class="left_content">
          <a target="_blank" href="https://www.instagram.com/uncovorapp">Instagram</a>
          <a target="_blank" href="https://twitter.com/uncovorapp">Twitter</a>
        </div>
        <div class="right_content">
          <router-link to="/about"> About us</router-link>
          <router-link to="/terms-conditions"> Terms & Conditions</router-link>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import '../assets/scss/mixins';

footer {
  background: var(--bg);
  display: flex;
  flex-direction: column;
  margin-top: 38px;
  padding: 40px 40px 0 40px;
  align-items: center;
  justify-content: space-between;

  .footer_content_wrapper {
    width: 100%;
  }

  .top_content {
    text-align: left;
    border-bottom: 1px solid #5e5e5e;
    padding-bottom: 33px;
  }

  .bottom_content {
    padding: 24px 0;
    display: flex;
    justify-content: space-between;

    a {
      text-decoration: none;
      font-weight: 500;
      font-size: 20px;
      color: var(--bg-text);

      &:not(:last-child) {
        margin-right: 40px;
      }
    }
  }
}

@media (max-width: 768px) {
  footer {
    padding: 40px 0px 0 40px;

    .top_content {
      padding-bottom: 16px;
    }

    .bottom_content {
      flex-direction: column;

      .left_content,
      .right_content {
        display: block;

        a {
          margin-right: 0;
          font-size: 14px;
          display: block;
          width: fit-content;
          padding-bottom: 20px;
        }
      }
    }

    .top_content {
      border-bottom: none;
    }
  }
}

footer {
  @include respond(sm) {
    padding: 40px 20px 0 20px; // SCREEN-PADDING
  }
}</style>
