<template>
  <div class="waitlist_page_wrapper">
    <div class="top_header_section">
      <div class="top_banner">
        <Header />
      </div>
      <div class="section_content">
        <div class="left_part">
          <div class="main_title">You’re on our waitlist now!</div>
          <div class="secondary_title">
            We shall email you when we enable the access to you. Until then keep
            referring to move up the waitlist
          </div>
          <div class="wait_list_wrapper">
            <img src="../assets/images/waitlist/frame.png" alt="" />
            <div class="wait_list">
              <p class="help_text">YOUR CURRENT POSITION</p>
              <p
                class="number"
                :class="{
                  'medium-position': this.waitlistNo.length >= 6,
                  'high-position': this.waitlistNo.length >= 10,
                }"
              >
                {{ !this.waitlistNo ? "-" : this.waitlistNo }}
              </p>
            </div>
          </div>
          <p class="secondary_title">
            As per your current position, you would receive
            {{ currentUserPennis }} trons to view others’ salaries. But this
            is bound to change based on your referral count and that of other
            users.
          </p>
        </div>
        <div class="right_part">
          <img src="../assets/images/waitlist/top-banner-right.png" alt="" />
        </div>
      </div>
    </div>

    <div class="know_your_self_worth">
      <div class="content_wrapper">
        <div class="section_left">
          <div class="image_wrapper">
            <div class="image_wrapper-bg" />
            <img
              data-aos="fade-up"
              src="@/assets/images/waitlist/know-your-self-worth.png"
            />
          </div>
        </div>
        <div class="section_right" data-aos="fade-up">
          <div class="title"><span>Know your</span>Self Worth</div>
          <div class="description">
            Do you think you’re at your fullest earning potential right now?
            Don’t you want to understand what your real worth is in the
            competitive salary market? Figure it out now!
          </div>
          <div class="button_wrapper">
            <router-link :to="{ name: 'SelfWorth' }">
              Know your self worth
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="want_more_pennies_wrapper">
      <div class="content_wrapper">
        <div class="left_part">
          <div class="section_title">Want more trons?</div>
          <div class="section_secondary_title">
            Share your unique referral link to your friends and colleagues to
            move up the waitlist and win more trons
          </div>
          <div class="input_wrapper">
            <MyInput
              :value="'https://uncovor.com/#/?ref=' + this.referralCode"
              :readonly="true"
            />
            <span class="copy_button" @click="copy_referralcode">
              <img src="../assets/images/waitlist/copy.svg" alt="" />
            </span>
          </div>
          <div class="social_media_wrapper">
            <a
              target="_blank"
              href="
              https://www.linkedin.com/sharing/share-offsite/?url=https://uncovor.com"
            >
              <img
                src="../assets/images/waitlist/linkedin.svg"
                alt="LinkedIn"
                srcset=""
              />
            </a>
            <a
              target="_blank"
              :href="
                'https://www.facebook.com/sharer/sharer.php?u=https://uncovor.com/#/?ref=' +
                this.referralCode
              "
            >
              <img
                src="../assets/images/waitlist/facebook.svg"
                alt="Facebook"
                srcset=""
              />
            </a>
            <a
              target="_blank"
              :href="
                'https://twitter.com/intent/tweet?text=Join&nbsp;me&nbsp;on&nbsp;Uncovor&amp;url=https://uncovor.com/#/?ref=' +
                this.referralCode
              "
            >
              <img
                src="../assets/images/waitlist/twitter.svg"
                alt="Twitter"
                srcset=""
              />
            </a>
            <a
              target="_blank"
              :href="
                'whatsapp://send?text=Joinhttps://uncovor.com/#/?ref=' +
                this.referralCode
              "
            >
              <img
                src="../assets/images/waitlist/whatsapp.svg"
                alt="Whatsapp"
                srcset=""
              />
            </a>
          </div>
          <div class="waitlist_chart_wrapper">
            <div class="table_header">
              <div class="position">Waitlist Position</div>
              <div class="trons_value">Trons</div>
            </div>
            <div class="row">
              <div class="position">1 - 2,000</div>
              <div class="trons_value">10,000</div>
            </div>
            <div class="row">
              <div class="position">2,001 - 5,000</div>
              <div class="trons_value">5,000</div>
            </div>
            <div class="row">
              <div class="position">5,001 - 10,000</div>
              <div class="trons_value">1,000</div>
            </div>
            <div class="row">
              <div class="position">10,001 - 25,000</div>
              <div class="trons_value">500</div>
            </div>
            <div class="row">
              <div class="position">25,001 - 50,000</div>
              <div class="trons_value">100</div>
            </div>
          </div>
        </div>
        <div class="right_part">
          <img src="../assets/images/waitlist/want-more-trons.png" alt="" />
        </div>
      </div>
    </div>

    <div class="move_up_waiting_list_wrapper">
      <div class="section_left">
        <div class="image_wrapper">
          <img
            data-aos="fade-up"
            src="../assets/images/waitlist/move-up-waitlist.png"
          />
        </div>
      </div>
      <div class="section_right" data-aos="fade-up">
        <div class="title">Move up the Waitlist</div>
        <div class="description">
          Upload your salary slip to move up the waitlist and earn more trons
        </div>
        <template v-if="this.verification_status == 0">
          <MyButton v-on:click="uploadSalarySlip" type="accent">
            Upload salary slip
          </MyButton>
        </template>
        <template v-else-if="this.verification_status == 1">
          <MyButton type="disabled"> Salary slip verified </MyButton>
        </template>
        <template v-else-if="this.verification_status == 2">
          <MyButton :disabled="true"> Salary slip under review </MyButton>
        </template>
        <template v-else-if="this.verification_status == 3">
          <MyButton type="disabled"> Salary slip rejected </MyButton>
        </template>
        <template v-else-if="this.verification_status == 4">
          <MyButton type="disabled">
            Re upload salary slip after 6 Months
          </MyButton>
        </template>
      </div>
    </div>

    <!-- <div class="move_up_waiting_list_wrapper">
      <div class="left_part">
        <div class="section_title">Move up the Waitlist</div>
        <div class="section_secondary_title">
          Upload your salary slip to move up the waitlist and earn more trons
        </div>
        <template v-if="this.verification_status == 0">
          <MyButton v-on:click="uploadSalarySlip" type="accent">
            Upload salary slip
          </MyButton>
        </template>
        <template v-else-if="this.verification_status == 1">
          <MyButton type="disabled"> Salary slip verified </MyButton>
        </template>
        <template v-else-if="this.verification_status == 2">
          <MyButton type="disabled"> Salary slip under review </MyButton>
        </template>
        <template v-else-if="this.verification_status == 3">
          <MyButton type="disabled"> Salary slip rejected </MyButton>
        </template>
        <template v-else-if="this.verification_status == 4">
          <MyButton type="disabled">
            Re upload salary slip after 6 Months
          </MyButton>
        </template>
      </div>
      <div class="right_part">
        <img src="../assets/images/waitlist/move-up-waitlist.png" alt="" />
      </div>
    </div> -->

    <FooterCanvas />

    <Footer></Footer>
    <UploadPayslip
      :show="showUploadPaySlip"
      v-on:closeModal="this.closeModal"
    />
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import MyInput from "../components/input.vue";
import MyButton from "../components/button.vue";
import FooterCanvas from "./Home/FooterCanvas.vue";
import UploadPayslip from "../components/upload-payslip.vue";
import { api } from "../services/http-common.js";
import { publishEvent } from "../helpers/Observer";
import { KEYWORDS } from "../helpers/constants";
import Footer from "../components/Footer.vue";

export default {
  components: {
    Header,
    MyInput,
    MyButton,
    UploadPayslip,
    FooterCanvas,
    Footer,
  },
  data() {
    return {
      showUploadPaySlip: false,
      waitlistNo: "-",
      referralCode: "",
      verification_status: 0,
      currentUserPennis: "()",
    };
  },
  methods: {
    uploadSalarySlip() {
      this.showUploadPaySlip = true;
      document.querySelector("html").classList.add("noScroll");
    },
    closeModal(data) {
      if (data.status === "UPLOADED") {
        this.fetchUserDetails();
      }
      this.showUploadPaySlip = false;
      document.querySelector("html").classList.remove("noScroll");
    },
    logout() {
      this.$store.commit("setAuthLogout", false);
    },
    copy_referralcode() {
      let copyText = document.querySelector(".my_input_wrapper input");
      copyText.select();
      document.execCommand("copy");
    },
    fetchUserDetails() {
      publishEvent(KEYWORDS.SHOW_LOADER);
      let self = this;
      api
        .get(`api/v1/user/profile`)
        .then((response) => {
          this.$store.commit("setUser", response.data);
          self.waitlistNo = (response.data.waitlist || "").toString();
          self.referralCode = response.data.referral_code;
          self.verification_status = response.data.verification_status;
          publishEvent(KEYWORDS.HIDE_LOADER);
        })
        .catch((e) => {
          publishEvent(KEYWORDS.HIDE_LOADER);
          console.log(e);
        });
    },
  },
  created() {
    publishEvent(KEYWORDS.SHOW_LOADER);
    api
      .get(`api/v1/pennies/waitlist`)
      .then((response) => {
        if (response.data && response.data.data && response.data.data.coins) {
          this.currentUserPennis = response.data.data.coins;
        }
      })
      .catch((e) => {
        // publishEvent(KEYWORDS.HIDE_LOADER);
        console.error(e);
      });
    this.fetchUserDetails();
  },
};
</script>

<style lang="scss" scoped>
@import '../assets/scss/mixins';
.waitlist_page_wrapper {
  overflow: hidden;
  text-align: left;
  background: var(--bg);

  .top_header_section {
    background: var(--bg);
    max-width: 1340px;
    margin: auto;
    padding-bottom: 48px;
    // overflow: hidden;

    .top_banner {
      .logo {
        padding: 50px 0 60px 0;
        img {
          width: 150px;
          height: 30px;
        }
      }
    }
    .section_content {
      margin: 0 40px;
      @include respond(sm) {
        margin: 0 20px; // SCREEN-PADDING
      }
      .left_part {
        width: 50%;
      }
      .right_part {
        width: 50%;
        position: relative;
        img {
          width: 100%;
          position: absolute;
          bottom: -110px;
          right: -5vw;
          max-width: 570px;
          z-index: 0;
        }
      }
      display: flex;
      .main_title {
        color: white;
        font-weight: 800;
        font-size: 46px;
      }
      p.secondary_title {
        width: 100%;
        opacity: 1;
      }
      .secondary_title {
        font-weight: 500;
        font-size: 21px;
        line-height: 36px;
        color: #f2f4f9;
        opacity: 0.7;
        max-width: 100%;
        padding: 24px 0;
      }
      .wait_list_wrapper {
        position: relative;
        display: inline-block;
        .wait_list {
          position: absolute;
          top: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          .help_text {
            font-size: 16px;
            font-weight: 500;
            line-height: 27px;
            color: white;
          }
          .number {
            font-weight: 800;
            font-size: 80px;
            color: var(--bg-text);
            background: transparent;
            height: auto;
            margin: 0;
            padding: 0;
            line-height: 1;
            &.medium-position {
              font-size: 50px;
            }
            &.high-position {
              font-size: 30px;
            }
          }
        }
      }
    }
  }

  .know_your_self_worth {
    background: var(--bg-accent);
    padding: 100px 40px 20% 40px;
    z-index: 99;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      width: 50%;
      height: 100%;
      max-height: 600px;
      top: 0;
      right: 0;
      background-size: cover !important;
      background: url("../assets/images/waitlist/self-worth-bg.png");
    }
    .content_wrapper {
      display: flex;
      width: 100%;

      .section_left {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-content: flex-start;
        justify-content: center;

        .image_wrapper {
          max-height: 540px;
          max-width: 540px;
          min-width: 340px;
          min-height: 340px;
          height: 100%;
          width: 100%;
          position: relative;
        }
      }

      .section_right {
        margin-top: 12%;
        margin-left: 8%;
        @include respond(sm) {
          margin-left: 20px; // SCREEN-PADDING
        }

        .title {
          font-weight: 800;
          font-size: 58px;
          line-height: 60px;
          color: #181815;
          span {
            font-family: BrotherLine !important;
            font-weight: 400;
            font-size: 100px;
            display: block;
            margin-bottom: 18px;
          }
        }

        .description {
          color: #181815;
          font-weight: normal;
          font-size: 24px;
          line-height: 140%;
          max-width: 500px;
          margin-top: 28px;
        }
      }
    }

    .image_wrapper-bg {
      height: 100%;
      width: 100%;
      background: #fddb3a;
      position: absolute;
      border-radius: 50%;
    }

    .button_wrapper {
      a {
        text-decoration: none;
        font-size: 18px;
        font-weight: bold;
        background: var(--bg);
        padding: 18px 24px;
        border-radius: 8px;
        color: white;
        margin-top: 32px;
        display: inline-block;
        cursor: pointer !important;
      }
    }
  }

  .want_more_pennies_wrapper {
    background: var(--bg);
    padding: 0 40px 50px 40px;
    z-index: 999;
    position: relative;
    @include respond(sm) {
      padding: 0 20px 50px 20px; // SCREEN-PADDING
    }

    &:before {
      content: "";
      position: absolute;
      top: -200px;
      left: 0px;
      width: 100vw;
      display: block;
      height: 210px;
      z-index: 9;
      background: url("../assets/images/home/bg-vector/bg-vector-3.svg") center
        center/cover no-repeat;
    }

    &:after {
      content: "";
      position: absolute;
      bottom: -180px;
      left: 0px;
      width: 100vw;
      display: block;
      z-index: 999;
      height: 210px;

      background: url("../assets/images/home/bg-vector/bg-vector-4.svg") center
        center/cover no-repeat;
    }

    .content_wrapper {
      display: flex;
    }

    .left_part {
      width: 50%;
      margin-top: -6%;
      z-index: 99;
      .section_title {
        font-weight: 800;
        font-size: 46px;
        line-height: 60px;
        color: var(--bg-accent);
      }
      .section_secondary_title {
        font-weight: 500;
        font-size: 21px;
        line-height: 36px;
        color: white;
        width: 75%;
        opacity: 0.7;
      }
      .input_wrapper {
        display: flex;
        margin-top: 25px;
        margin-bottom: 32px;
        width: 75%;
        .my_input_wrapper {
          width: 100%;
          input {
            border: 1px solid white !important;
            color: #191915b3 !important;
          }
        }
        .copy_button {
          background: white;
          position: relative;
          left: -11px;
          border-left: 1px solid #d1d1d1;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 80px;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          cursor: pointer;
        }
      }
      .social_media_wrapper {
        a {
          margin-right: 16px;
          img {
            border-radius: 50%;
          }
        }
      }
      .waitlist_chart_wrapper {
        background: #fddb3a;
        border-radius: 20px;
        padding: 44px 46px;
        color: var(--bg);
        margin-top: 60px;
        .table_header {
          font-weight: bold;
          font-size: 28px;
          line-height: 35px;
          margin-bottom: 52px;
          display: flex;
        }
        .row {
          font-size: 26px;
          line-height: 32px;

          display: flex;
          &:not(:last-child) {
            margin-bottom: 32px;
          }
        }
        .position {
          width: 60%;
        }
      }
    }
    .right_part {
      width: 50%;
      position: relative;
      z-index: 99;
      img {
        width: 100%;
        position: absolute;
        bottom: -110px;
        max-width: 570px;
        right: -5vw;
      }
    }
  }

  .move_up_waiting_list_wrapper {
    background: var(--bg-accent);
    padding: 0% 0 0% 10%;
    display: flex;
    position: relative;
    align-items: center;
    @include respond(sm) {
      padding: 0% 0 0% 20px; // SCREEN-PADDING
    }

    &:after {
      content: "";
      position: absolute;
      bottom: -168px;
      left: 0px;
      width: 100vw;
      display: block;
      z-index: 99;
      height: 210px;

      background: url("../assets/images/waitlist/bg-vector.svg") center
        center/cover no-repeat;
    }

    .image_wrapper {
      img {
        margin-top: -12%;
        margin-bottom: -6%;
      }
    }

    .section_right {
      margin-top: 20%;
      z-index: 100;

      .title {
        font-weight: 800;
        font-size: 46px;
        line-height: 130%;
        color: #181815;
      }

      .description {
        font-weight: 500;
        font-size: 21px;
        line-height: 36px;
        color: #181815;
        width: 75%;
        opacity: 0.7;
      }
    }
    .left_part {
      width: 50%;
      .section_title {
        font-weight: 800;
        font-size: 46px;
        line-height: 60px;
        color: #cbd83f;
      }
      .section_secondary_title {
        font-size: 27px;
        line-height: 46px;
        color: #ffffff;
        margin-bottom: 32px;
        width: 75%;
      }
    }
    .right_part {
      img {
        position: absolute;
        top: -137px;
        right: 0;
        z-index: 999999;
      }
    }
  }

  ::v-deep {
    .my_button_wrapper button {
      padding: 12px 18px 12px 18px;
      color: #181815 !important;
      line-height: 100%;

      &:disabled {
        opacity: 0.7;
      }
    }

    .move_up_waiting_list_wrapper button {
      text-decoration: none;
      font-size: 18px;
      font-weight: bold;
      background: var(--bg) !important;
      padding: 18px 24px;
      border-radius: 8px;
      color: var(--bg-accent) !important;
      margin-top: 18px;
    }

    .my_input_wrapper {
      width: 100%;
      input {
        border: 1px solid white !important;
        color: #191915b3 !important;
      }
    }

    .footer_canvas {
      margin-top: -42px;
      &:before {
        display: none;
      }
    }
  }
}

.logout {
  position: absolute;
  right: 3%;
  top: 60px;
  color: white;
  cursor: pointer;
}

@media (max-width: 768px) {
  .section_content {
    flex-direction: column;
    // padding: 0 8%;
    .left_part {
      width: 100% !important;

      .main_title {
        font-size: 32px !important;
        max-width: 75% !important;
      }
    }

    .right_part {
      width: 100% !important;
      img {
        bottom: -75px !important;
        right: -10% !important;
        max-width: 180px !important;
      }
    }

    .secondary_title {
      font-size: 14px !important;
      line-height: 24px !important;
      padding: 16px 0 24px 0 !important;
    }

    p.secondary_title {
      width: 50% !important;
    }

    .wait_list_wrapper {
      transform: scale(0.9);
      margin-left: -5%;
    }
  }

  .want_more_pennies_wrapper {
    // padding: 40px 0 0 10% !important;
    flex-direction: column;

    &:before {
      top: -75px !important;
      // background-size: contain !important;
      height: 80px !important;
    }

    &:after {
      bottom: -74px !important;
      height: 80px !important;
    }

    .left_part {
      width: 100% !important;
      .section_title {
        font-size: 32px !important;
        line-height: 42px !important;
      }

      .section_secondary_title {
        font-size: 14px !important;
        line-height: 22px !important;
        margin-top: 16px;
      }

      .input_wrapper {
        width: 100% !important;
        margin-bottom: 26px !important;
      }

      .social_media_wrapper a img {
        max-width: 36px;
      }

      .waitlist_chart_wrapper {
        margin-top: 40px !important;
      }
    }

    .right_part {
      width: 100% !important;
      height: 420px !important;
      margin-top: 46px;

      img {
        bottom: 0 !important;
        right: 0;
        width: auto !important;
        max-height: 420px;
        right: 18% !important;
      }
    }

    .waitlist_chart_wrapper {
      padding: 24px !important;
      width: calc(100vw - 80px);

      .table_header {
        align-items: center;
        margin-bottom: 20px !important;
      }

      .position {
        font-size: 16px;
        line-height: 24px;
      }

      .trons_value {
        font-size: 16px;
      }
    }

    .content_wrapper {
      flex-direction: column;
      padding-right: 40px;
    }
  }

  .move_up_waiting_list_wrapper {

    flex-direction: column-reverse;
    height: auto !important;

    &:after {
      bottom: -65px !important;
      height: 80px !important;
    }

    .section_right {
      width: 100% !important;
      margin-top: 20% !important;
      margin-bottom: 30px;

      .title {
        font-size: 32px !important;
        max-width: 80% !important;
        line-height: 42px !important;
      }

      .description {
        font-size: 14px !important;
        line-height: 24px !important;
        width: 85% !important;
        margin-top: 8px;
      }
    }

    .section_left {
      display: none;
      // img {
      //   position: relative !important;
      //   margin-bottom: 0px !important;
      //   margin-top: 0px !important;
      //   width: 114% !important;
      //   margin-left: -12% !important;
      // }
    }
  }

  .logout {
    right: 8%;
  }

  .waitlist_page_wrapper .know_your_self_worth {
    padding: 80px 0 20% 0;
    &:before {
      left: 25% !important;
      width: 75% !important;
      background-size: contain !important;
      background-repeat: no-repeat !important;
    }

    .content_wrapper {
      flex-direction: column !important;
      // padding: 0 8%;
      padding-right: 40px;

      .image_wrapper {
        display: flex;
        justify-content: center;

        img {
          max-width: 75%;
        }
      }

      .section_right {
        .title {
          font-size: 32px;

          span {
            font-size: 46px !important;
            margin-bottom: 0;
            line-height: 28px;
          }
        }

        .description {
          font-size: 14px;
          margin-top: 0;
        }
      }
    }

    .button_wrapper a {
      margin-top: 24px !important;
    }
  }
  .footer_canvas {
    background: transparent;
  }
}

@include respond(sm) {
  .want_more_pennies_wrapper {

    .right_part img {
      right: 0 !important;
    }
    .waitlist_chart_wrapper {
      width: calc(100vw - 40px);
    }
  }
}
</style>
