<template>
  <div class="selfWorth">
    <div class="content_wrapper">
      <Header />
      <div class="main_title">
        <span>Know your</span><p>Self worth</p>
      </div>
      <SelfWorthForm />
      <div class="selfWorth-info">
        <div class="info-left">
          <div class="main_title">
            Is hiding salary
            <br><span>Bad for you?</span>
          </div>
          <p>
            Companies collude and don't want you to know everyone’s salary so
            that they can rip you off. This is how information asymmetry and
            capitalism works.
          </p>
        </div>
        <div class="info-right">
          <div class="info-item">
            <div class="info-icon">
              <div class="info-icon-bg"></div>
              <img
                data-aos="fade-up"
                src="@/assets/images/self-worth/low-job-satisfaction.svg"
              />
            </div>
            <div class="info-item-desc">
              <span>Lowers job satisfaction</span>
              <p>
                Creates a political, bureaucratic environment that leads to a
                toxic workplace.
              </p>
            </div>
          </div>
          <div class="info-item">
            <div class="info-icon">
              <div class="info-icon-bg"></div>
              <img
                data-aos="fade-up"
                style="margin-top: 12px"
                src="@/assets/images/self-worth/self-worth.svg"
              />
            </div>
            <div class="info-item-desc">
              <span>Lowers your self-worth</span>
              <p>
                Because you don’t have enough data, you are not confident of
                what value you deliver.
              </p>
            </div>
          </div>
          <div class="info-item">
            <div class="info-icon">
              <div class="info-icon-bg"></div>
              <img
                data-aos="fade-up"
                style="margin-top: 12px"
                src="@/assets/images/self-worth/emotional-wellbeing.svg"
              />
            </div>
            <div class="info-item-desc">
              <span>Affects emotional wellbeing</span>
              <p>
                Creates anxiety and doubt if a peer who is delivering lesser
                value is earning more.
              </p>
            </div>
          </div>
          <div class="info-item">
            <div class="info-icon">
              <div class="info-icon-bg"></div>
              <img
                data-aos="fade-up"
                style="margin-top: 8px"
                src="@/assets/images/self-worth/career-choices.svg"
              />
            </div>
            <div class="info-item-desc">
              <span>Suboptimal career choices</span>
              <p>
                In absence of data, you are much more likely to make a
                suboptimal move in choosing best paymasters
              </p>
            </div>
          </div>
          <div class="info-item">
            <div class="info-icon">
              <div class="info-icon-bg"></div>
              <img
                data-aos="fade-up"
                style="margin-top: 12px"
                src="@/assets/images/self-worth/underpaid.svg"
              />
            </div>
            <div class="info-item-desc">
              <span>Lowers trust, feel underpaid</span>
              <p>
                People’s pay should be determined by how well they discharge a
                job, not how well they can negotiate or speak.
              </p>
            </div>
          </div>
          <div class="info-item">
            <div class="info-icon">
              <div class="info-icon-bg"></div>
              <img
                data-aos="fade-up"
                style="margin-top: 8px"
                src="@/assets/images/self-worth/information-power.svg"
              />
            </div>
            <div class="info-item-desc">
              <span>Information is power</span>
              <p>
                Employers collude to pay you sub-market salaries. It’s time,
                employees start colluding to earn what their true worth is.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import { initializeApp } from "firebase/app";
import firebaseConfig from "../../helpers/firebaseConfig";
import SelfWorthForm from "./SelfWorthForm.vue";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

export default {
  components: { SelfWorthForm, Header, Footer },
  mounted: () => {},
  methods: {
    logout() {
      this.$store.commit("setAuthLogout", false);
    },
  },
  beforeCreate: () => {
    initializeApp(firebaseConfig);
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/mixins';
.selfWorth {
  background: #181815;
  overflow: hidden;

  .logo {
    padding: 50px 0 80px 0;
    img {
      width: 150px;
      height: 24px;
    }
  }

  .main_title {
    color: var(--bg-text);
    font-weight: 800;
    font-size: 66px;
    margin: 0 auto;
    line-height: 82px;
    margin-top: -18px !important;

    span {
      font-family: BrotherLine !important;
      position: relative;
      color: white;
      font-size: 74px;
      text-align: center;
      display: block;
      margin-bottom: -34px;
      font-weight: 400;
    }
  }

  .selfWorth-info {
    display: flex;
    margin-top: 100px !important;
    max-width: 1140px;
    margin: auto 40px;
    @include respond(sm) {
      margin: 0 20px; // SCREEN-PADDING
    }

    .info-left {
      width: 40%;

      .main_title {
        font-size: 58px;
        margin: 0 auto;
        text-align: left;
        span {
          font-size: 86px;
          text-align: left;
          display: block;
          margin: -18px 0;
          font-weight: 400;
        }
      }

      p {
        width: 60%;
        margin-top: 30px;
        text-align: left;
        color: white;
        line-height: 24px;
      }
    }

    .info-right {
      width: 60%;
      display: flex;
      flex-wrap: wrap;
      .info-item {
        width: 50%;
        display: flex;
        padding-bottom: 40px;

        &:nth-child(even) {
          padding-left: 50px !important;
        }
        .info-icon {
          width: 56px;
          height: 56px;
          background: #cbd93e;
          border-radius: 100%;
          overflow: hidden;

          img {
            margin-top: 4px;
          }
        }

        .info-item-desc {
          width: calc(100% - 56px);
          padding: 0 0 24px 24px;

          span {
            font-size: 24px;
            line-height: 28px;
            color: white;
            font-weight: bold;
            text-align: left;
            display: block;
          }

          p {
            text-align: left;
            line-height: 22px;
            color: #ffffff99;
            margin: 12px 0;
          }
        }
      }
    }
  }

  .logout {
    position: absolute;
    right: 3%;
    top: 60px;
    color: white;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    .logo {
      display: none;
      padding: 50px 0 64px 0;
    }

    .main_title {
      font-size: 42px;
      line-height: 64px;
      margin-top: -24px;
      margin-bottom: 60px;

      span {
        font-size: 48px;
      }
    }

    .bottom-image {
      display: flex !important;
      margin-bottom: 8px !important;

      img {
        height: 320px;
      }
    }

    .selfWorth-info {
      margin-top: 40px !important;
      flex-direction: column;

      .info-left {
        width: 100%;
        // padding: 0 8%;

        .main_title {
          font-size: 28px;
          line-height: 72px;

          span {
            font-size: 48px;
            margin: -36px 0;
          }
        }

        p {
          width: 100%;
        }
      }

      .info-right {
        width: 100% !important;
        flex-direction: column !important;
        padding: 8% 0;
        padding-bottom: 0;

        .info-item {
          width: 100% !important;
          padding-bottom: 0;

          .info-icon {
            width: 48px !important;
            height: 48px !important;
          }

          .info-item-desc {
            span {
              font-size: 16px;
              line-height: 16px;
            }

            p {
              margin: 8px 0;
              font-size: 14px;
            }
          }

          &:nth-child(even) {
            padding-left: 0 !important;
          }
        }
      }
    }
  }
}
</style>
