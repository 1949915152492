/* eslint-disable */
/**
 * Job Family (Job Domain)
 */
export const JOB_FAMILY = [
    {
      "id": "904323e2-da4e-414d-acbb-3a0ba5774cc3",
      "name": "Business Analyst"
    },
    {
      "id": "e3078191-c66d-46ee-8d27-40ef6a9810b0",
      "name": "Data Scientist"
    },
    {
      "id": "0e0f13a5-2e92-4eb1-9f85-c197eea1f7c3",
      "name": "Hardware Engineer"
    },
    {
      "id": "d48c9e2e-65d7-49ab-8a2c-3907299c111d",
      "name": "Management Consultant"
    },
    {
      "id": "d2247714-8baa-49a1-8acb-71183140c500",
      "name": "Marketing"
    },
    {
      "id": "5fe36050-1f15-455b-a6d4-fe7ebc0e6347",
      "name": "Product Designer"
    },
    {
      "id": "4c3840a9-e88f-4a4a-aaeb-7ef16de2f540",
      "name": "Product Manager"
    },
    {
      "id": "519544e0-4fa8-4490-8432-71d341b0afa9",
      "name": "Recruiter"
    },
    {
      "id": "192df485-b042-4652-806e-ecab1eff300e",
      "name": "Sales"
    },
    {
      "id": "560ed38b-3b71-4891-ae8c-cf9354cf3127",
      "name": "Software Engineer"
    },
    {
      "id": "5b60ab13-e50d-452b-a99f-aecc926c06bb",
      "name": "Software Engineering Manager"
    },
    {
      "id": "d792101d-0d59-479f-85e5-6cc49544e839",
      "name": "Solution Architect"
    },
    {
      "id": "fee67757-ea90-48e0-b9be-b1dea71d7f98",
      "name": "Technical Program Manager"
    }
  ]

/**
 * Job Title
 */
export const JOB_TITLE = [
    {
      "id": "f9b3f996-2f1c-11ed-a261-0242ac120002",
      "name": "Database administrator"
    },
    {
      "id": "f9b3feaa-2f1c-11ed-a261-0242ac120002",
      "name": "Backend Engineer"
    },
    {
      "id": "f9b3fff4-2f1c-11ed-a261-0242ac120002",
      "name": "Frontend Engineer"
    },
    {
      "id": "f9b40134-2f1c-11ed-a261-0242ac120002",
      "name": "Information system architect"
    },
    {
      "id": "f9b4026a-2f1c-11ed-a261-0242ac120002",
      "name": "Business analyst"
    },
    {
      "id": "f9b40396-2f1c-11ed-a261-0242ac120002",
      "name": "IT project manager"
    },
    {
      "id": "f9b404ae-2f1c-11ed-a261-0242ac120002",
      "name": "Web designer"
    },
    {
      "id": "f9b405d0-2f1c-11ed-a261-0242ac120002",
      "name": "SEO consultant"
    },
    {
      "id": "f9b406fc-2f1c-11ed-a261-0242ac120002",
      "name": "Data scientist"
    },
  ]

export const LOCATION_ARRANGEMENT = [{name: "In Office", id: 'in_office'}, {name: "Fully Remote", id: "remote"}, {name: "Hybrid", id: "hybrid"}];
