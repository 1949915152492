<template>
  <div>
    <div class="about_header">
      <div class="content_wrapper">
        <Header />
        <div class="our_vision">
          <div class="our_vision_left">
            <img src="@/assets/images/about/our_vision_left.png" />
          </div>
          <div class="our_vision_center">
            <h1>Our Vision</h1>
            <h4>Bring transparency and fairness to the salary structure</h4>
          </div>
          <div class="our_vision_right">
            <img src="@/assets/images/about/our_vision_right.png" />
          </div>
        </div>
      </div>
    </div>
    <div class="who_we_are">
      <div class="content_wrapper">
        <div class="left-section">
          <img src="@/assets/images/about/who_we_are.png" />
        </div>
        <div class="right-section">
          <h1>Who we are</h1>
          <ul>
            <li>
              Rebels, Misfits who want to make salaries fair and equitable
            </li>
            <li>
              We want to change the equation which is currently heavily skewed
              towards the employer
            </li>
            <li>
              We want society to open up about salaries. Stop treating it as a
              taboo
            </li>
            <li>
              Bring transparency and make the world a better, happier and more
              trustworthy place
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="our_story">
      <div class="content_wrapper">
        <h1>Our Story</h1>
        <h4>is the same as your story</h4>
        <p>
          Who realised that our employers were squeezing us out - paying us
          below market competitive salaries
        </p>
        <p>
          Felt bad, hurt, exploited and decided to take up the mantle of
          cleaning the system up
        </p>
      </div>
    </div>

    <div class="about_quote">
      <div class="content_wrapper">
        <div class="quote">
          Uncovor is our attempt to remove the shenanigans around salary and to
          create a world where we would feel safe, trusted, protected, wanted
          and overall happier
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "../components/Header";
import Footer from "../components/Footer";

export default {
  components: { Header, Footer },
  data() {
    return {};
  },
  methods: {},
  computed: {},
};
</script>

<style lang="scss" scoped>
@import '../assets/scss/mixins';
.about_header {
  overflow: hidden;
  text-align: left;
  background: var(--bg);
  // padding: 0 10%;

  .our_vision {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin: -6% 40px -6px 40px;
    @include respond(sm) {
      margin: -6% 20px -6px 20px; // SCREEN-PADDING
    }

    .our_vision_center {
      text-align: center;
      margin-bottom: 17%;
      display: block;
      position: relative;

      h1 {
        font-family: BrotherLine !important;
        font-weight: 400;
        font-size: 86px;
        display: block;
        margin-bottom: 18px;
        color: var(--bg-text);
      }

      h4 {
        font-weight: 500;
        font-size: 36px;
        line-height: 150%;
        color: white;
      }
    }
  }
}

.who_we_are {
  padding-top: 4%;
  margin-right: 40px;
  .content_wrapper {
    display: flex;

    .right-section {
      text-align: left;
      h1 {
        font-family: BrotherLine !important;
        font-weight: 400;
        font-size: 86px;
        display: block;
        margin-bottom: 18px;
        color: var(--bg);
      }

      ul {
        margin-left: 20px;
      }

      li {
        font-weight: 500;
        font-size: 24px;
        line-height: 170%;
        margin-left: 10px;
        padding-left: 10px;
        color: #181815b3;

        &:before {
          content: "";
          display: inline-block;
          width: 12px;
          height: 12px;
          background: black;
          border-radius: 100%;
          vertical-align: middle;
          margin-left: -30px;
          margin-right: 10px;
        }
      }
    }
  }
}

.our_story {
  margin: 0 40px 2% 40px;
  h1 {
    font-family: BrotherLine !important;
    font-weight: 400;
    font-size: 86px;
    display: block;
    margin-bottom: 0;
    color: var(--bg);
  }

  h4 {
    font-weight: 500;
    font-size: 36px;
    line-height: 130%;
    margin-bottom: 20px;
    color: var(--bg);
  }

  p {
    font-weight: 500;
    font-size: 24px;
    line-height: 200%;
    /* or 41px */
    text-align: center;
    color: #181815b3;
  }
}

.about_quote {
  margin: 0 40px 4%;

  .quote {
    background: var(--bg-accent);
    border-radius: 60px;
    padding: 4% 8%;
    font-weight: 700;
    font-size: 31px;
    line-height: 160%;
    color: #181815b3;
  }
}

::v-deep {
  .footer_canvas {
    top: 0px;
    &:before {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  .about_header {
    padding: 0 !important;
  }
  .our_vision {
    flex-direction: column-reverse;
    margin-top: 4%;
    align-items: center !important;

    padding: 0 8%;

    .our_vision_left {
      display: flex;
      justify-content: center;
      img {
        width: 75%;
      }
    }

    .our_vision_right {
      display: flex;
      justify-content: center;
      img {
        width: 75%;
      }
    }

    .our_vision_center {
      margin-bottom: 42px !important;
      h1 {
        font-size: 42px !important;
        margin-top: 42px !important;
      }

      h4 {
        font-size: 16px !important;
      }
    }
  }

  .who_we_are {
    // padding: 0 8%;
    margin: 40px;

    .content_wrapper {
      flex-direction: column;
    }

    .left-section {
      img {
        width: 75%;
      }
    }

    .right-section {
      h1 {
        font-size: 42px !important;
        text-align: center;
      }

      li {
        font-size: 18px !important;

        &:before {
          width: 8px;
          height: 8px;
        }
      }
    }
  }

  .our_story {
    padding: 0 8%;

    .content_wrapper {
      h1 {
        margin-top: 60px;
        font-size: 42px !important;
      }

      h4 {
        font-size: 21px;
      }

      p {
        font-size: 18px;
      }
    }
  }

  .about_quote {
    padding: 8%;
    .quote {
      border-radius: 20px;
      padding: 8%;
      font-size: 18px;
    }
  }
}

// SCREEN-PADDING
@include respond(sm) {
  .who_we_are {
    margin: 20px;
  }
  .our_story {
    padding: 0;
    margin: 0 20px 2% 20px;
  }
  .about_quote {
    padding: 0;
    margin: 0 20px;
  }
}
</style>
