<template>
  <MyModal
      :show="show"
      :showLeftPart="false"
      title="Choose Profile Image"
      @closeModal="$emit('closeModal')"
      :customClass="'profile-image-modal'"
    >
      <div class="modal-container">
        <div class="container-head-center">
          <h4>Choose Profile Image</h4>
        </div>
        <div class="images">
          <div
            :class="{ active: selectedImage == 1 }"
            @click="selectImage(require('../assets/Profile45.png'), 1)"
            class="image"
          >
            <img src="../assets/Profile45.png" />
          </div>
          <div
            :class="{ active: selectedImage == 2 }"
            @click="selectImage(require('../assets/Profile46.png'), 2)"
            class="image"
          >
            <img src="../assets/Profile46.png" />
          </div>
          <div
            :class="{ active: selectedImage == 3 }"
            @click="selectImage(require('../assets/Profile47.png'), 3)"
            class="image"
          >
            <img src="../assets/Profile47.png" />
          </div>
          <div
            :class="{ active: selectedImage == 4 }"
            @click="selectImage(require('../assets/Profile48.png'), 4)"
            class="image"
          >
            <img src="../assets/Profile48.png" />
          </div>
          <div
            :class="{ active: selectedImage == 5 }"
            @click="selectImage(require('../assets/Profile49.png'), 5)"
            class="image"
          >
            <img src="../assets/Profile49.png" />
          </div>
          <div
            :class="{ active: selectedImage == 6 }"
            @click="selectImage(require('../assets/Profile50.png'), 6)"
            class="image"
          >
            <img src="../assets/Profile50.png" />
          </div>
          <div
            :class="{ active: selectedImage == 7 }"
            @click="selectImage(require('../assets/Profile51.png'), 7)"
            class="image"
          >
            <img src="../assets/Profile51.png" />
          </div>
          <div
            :class="{ active: selectedImage == 8 }"
            @click="selectImage(require('../assets/Profile52.png'), 8)"
            class="image"
          >
            <img src="../assets/Profile52.png" />
          </div>
          <div
            :class="{ active: selectedImage == 9 }"
            @click="selectImage(require('../assets/Profile53.png'), 9)"
            class="image"
          >
            <img src="../assets/Profile53.png" />
          </div>
          <div
            :class="{ active: selectedImage == 10 }"
            @click="selectImage(require('../assets/Profile54.png'), 10)"
            class="image"
          >
            <img src="../assets/Profile54.png" />
          </div>
          <div
            :class="{ active: selectedImage == 11 }"
            @click="selectImage(require('../assets/Profile55.png'), 11)"
            class="image"
          >
            <img src="../assets/Profile55.png" />
          </div>
        </div>
        <div class="change-image-btn">
          <MyButton
            width="180px"
            :disabled="!selectedImage"
            v-on:click="updateUserImage"
          >
            Change
          </MyButton>
        </div>
      </div>
    </MyModal>

  <!-- <div class="profile-image-modal">
    
  </div> -->
</template>

<script>
import MyModal from "../components/modal.vue";
import { api } from "../services/http-common.js";
import MyButton from "./button.vue";

export default {
  data: function () {
    return {
      publicPath: process.env.BASE_URL,
      selectedImage: "",
      selectedImageUrl: "",
      closeTheModal: true
    };
  },
  props: {
    userDetails: {},
    show: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    MyButton,
    MyModal
  },
  computed: {
    isDefaultSlotExists() {
      return !!this.$slots.default;
    },
  },
  methods: {
    selectImage(imageUrl, index) {
      this.selectedImageUrl = imageUrl;
      this.selectedImage = index;
    },
    updateUserImage() {
      const payload = {};
      payload.email = this.userDetails.email;
      payload.image = "https://uncovor.com" + this.selectedImageUrl;
      payload.type = "user";
      api
        .put("/api/v1/user/", payload, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(() => {
          this.$emit("updateUserDetails");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
@import '../assets/scss/mixins';
.profile-image-modal {
  :deep(.modal_content_wrapper) {
    @include respond(md) {
      padding-top: 0 !important;
    }
    .default_content {
      width: 100% !important;
    }
  }
  .modal-container {
    padding: 24px;
    @include respond(md) {
      padding-top: 64px;
    }
  }
  h4 {
    font-size: 28px;
    font-weight: bold;
    color: black;
    margin-bottom: 12px;
    text-align: center;
  }

  .images > img {
    width: 18%;
    margin: 0 1%;
    position: relative;
  }

  .images > img:before {
    position: absolute;
    content: "";
    width: 65px;
    height: 65px;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .images {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
  }

  .images > .image {
    width: auto;
    margin: 0 1%;
    border-radius: 100%;
    overflow: hidden;
    margin-bottom: 20px;
    z-index: 50;
    &.active:before {
      background: #cbd83f;
    }
  }

  .image img {
    display: block;
    height: auto;
    width: 120px;
  }

  .image:before {
    position: absolute;
    content: "";
    width: 120px;
    height: 120px;
    background: #fddb3a;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 100%;
    z-index: -1;
  }
  .change-image-btn {
    text-align: center;
    margin-top: 30px;
  }
}
</style>
