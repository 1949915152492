<template>
  <div id="surprise-me" class="footer_canvas">
    <img src="@/assets/images/home/home-tsoa-canvas.png" class="canvas_img" />

    <div
      class="bubble_xD active"
      v-if="this.bubbles[randomNumber]"
      ref="bubble"
      :style="
        'background:url(' +
        require('../../assets/images/home/tsoa-canvas/' +
          this.bubbles[randomNumber].id +
          '.svg') +
        ';width: ' +
        this.bubbles[randomNumber].size[0] +
        'px; height :' +
        this.bubbles[randomNumber].size[1] +
        'px;' +
        ' left: ' +
        this.bubbles[randomNumber].position[0] +
        '%; top :' +
        this.bubbles[randomNumber].position[1] +
        '%;'
      "
    >
      {{ this.bubbles[randomNumber].text }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      bubbles: [
        {
          id: 1,
          size: [180, 124],
          position: [18, 44],
        },
        {
          id: 2,
          size: [120, 129],
          position: [7, 60],
        },
        {
          id: 3,
          size: [130, 130],
          position: [6, 80],
        },
        {
          id: 4,
          size: [135, 104],
          position: [20, 76],
        },
        {
          id: 5,
          size: [146, 100],
          position: [43, 75],
        },
        {
          id: 6,
          size: [162, 127],
          position: [58, 88],
        },
        {
          id: 7,
          size: [152, 114],
          position: [74, 69],
        },
        {
          id: 8,
          size: [106, 82],
          position: [92, 43],
        },
        {
          id: 9,
          size: [130, 100],
          position: [71, 48],
        },
        {
          id: 10,
          size: [130, 100],
          position: [56, 48],
        },
        {
          id: 11,
          size: [130, 100],
          position: [42, 46],
        },
      ],
      randomNumberArray: [0],
      randomNumber: 0,
      bubbleInterval: 0,
    };
  },
  components: {},
  computed: {},
  methods: {
    randomBubbleGenerator: function () {
      let numbers = []; // new empty array
      let min, max, n, p;
      min = 0;
      max = 11;

      for (let i = 0; i < max; i++) {
        do {
          n = Math.floor(Math.random() * (max - min));
          p = numbers.includes(n);
          if (!p) {
            numbers.push(n);
          }
        } while (p);
      }

      this.randomNumberArray = numbers;
    },
    randomBubblePicker: function () {
      if (this.randomNumberArray.length == 1) {
        this.randomBubbleGenerator();
        this.randomNumber = this.randomNumberArray.pop();
        return;
      }
      this.randomNumber = this.randomNumberArray.pop();
      setTimeout(() => {
        this.$refs?.bubble?.classList.add("active");
        this.$refs.bubble.className = "bubble_xD active";
      }, 100);
    },
  },
  mounted: function () {
    this.randomBubbleGenerator();
    this.bubbleInterval = setInterval(() => {
      this.$refs.bubble.className = "bubble_xD";
      this.randomBubblePicker();
    }, 3500);
  },
  beforeDestroy: function () {
    clearInterval(this.bubbleInterval);
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/mixins';
.footer_canvas {
  background: var(--bg);
  width: 100vw;
  height: 100vh;
  min-height: 1000px;
  position: relative;
  @include respond(sm) {
    margin-top: -35px;
  }

  .canvas_img {
    position: absolute;
    width: 100%;
    height: 100% !important;
    left: 0;
    z-index: 0;
    top: 40px;
  }

  .bubble_xD {
    background: url(../../assets/images/home/tsoa-canvas/1.svg);
    width: 200px;
    height: 200px;
    position: absolute;
    left: 10%;
    top: 10%;
    opacity: 0;

    &.active {
      opacity: 1;
      animation: bubble_pop 0.4s linear;
    }
    @keyframes bubble_pop {
      0% {
        opacity: 0;
        margin-top: -20px;
      }
      100% {
        opacity: 1;
        margin-top: 0px;
      }
    }
  }

  // &:before {
  //   content: "";
  //   position: absolute;
  //   top: 100px;
  //   left: 0px;
  //   width: 100vw;
  //   background-size: cover !important;
  //   background-position: center !important;
  //   background-repeat: no-repeat !important;
  //   display: block;
  //   height: 240px;
  //   z-index: 9;
  //   background: url("../../assets/images/home/bg-vector/bg-vector-6.svg");
  // }

  @media (max-width: 768px) {
    height: 400px !important;
    min-height: 400px;

    &:before {
      top: -60px;
      background: url("../../assets/images/home/bg-vector/mobile/6.svg") center
        center/contain no-repeat !important;
    }
  }
}
</style>
