<template>
  <div class="selfWorth--form">
    <div class="disclaimer">
      <p>
        Please ensure you enter accurate data as your Uncovor account approval
        depend on this.
      </p>
    </div>

    <div class="form-container">
      <div class="form-left">
        <h4>Know your self worth</h4>
        <div class="self-worth-left">
          <div
            :class="`progress-container ${
              show.salaryDetails ? '--exciting' : '--boring'
            }`"
          >
            <div class="progress-item">
              <div class="progress-item-container">
                <div class="progress-image">
                  <img
                    class=""
                    src="@/assets/images/home/key-icon.svg"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="progress-title">Login</div>
              </div>
            </div>
            <div class="progress-item">
              <div class="progress-item-container">
                <div class="progress-image">
                  <img
                    class=""
                    src="@/assets/images/home/boring-icon.svg"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="progress-title">Boring</div>
              </div>
            </div>
            <div class="progress-item">
              <div class="progress-item-container">
                <div class="progress-image">
                  <img
                    class=""
                    src="@/assets/images/home/exciting-icon.svg"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="progress-title">Exciting</div>
              </div>
            </div>
          </div>
          <div class="bottom-image">
            <img src="@/assets/images/home/self-worth-boring.png" />
          </div>
        </div>
      </div>
      <div class="form-right">
        <div v-if="show.basicDetails">
          <BasicDetails
            v-bind:loadingBtn="loading.basicDetails"
            v-on:basicDetailsBtnClick="this.basicDetailsBtnClick"
            :linkedinDetails="this.linkedinDetails"
          />
        </div>
        <div v-else-if="show.salaryDetails">
          <SalaryDetails
            v-bind:loadingBtn="loading.salaryDetails"
            v-on:salaryDetailsBtnClick="this.salaryDetailsBtnClick"
            @back="goBack"
          />
        </div>
        <div v-else class="linkedinForm">
          <ValidationObserver ref="observer" v-slot="{ invalid }">
            <div :class="`linkedin-container ${invalid ? 'error' : ''}`">
              <ValidationProvider
                ref="linkedinURL"
                rules="url"
                v-slot="{ errors }"
              >
                <h4>What’s your LinkedIn link?</h4>
                <p>
                  Tell us your LinkedIn profile link and we’ll do all the magic.
                </p>
                <form @submit="fetchLinkedInData">
                  <MyInput
                    v-model="linkedinURL"
                    id="linkedinURL"
                    v-on:inputFocus="prefillLinkedInBaseUrl"
                    placeholder="eg: https://linkedin.com/in/drstrange"
                  />
                  <span class="inputError">{{ errors[0] }}</span>
                  <div class="linkedinForm__linked-get-link">
                    <QuestionMark />
                    <a
                      target="_blank"
                      rel="noreferrer noopener"
                      href="https://www.linkedin.com/help/linkedin/answer/a522735/find-your-linkedin-public-profile-url?lang=en"
                      >How do I get the link?</a
                    >
                  </div>
                  <MyButton
                    width="50%"
                    :loading="loading.linkedinURL"
                    v-bind:button-action-type="`submit`"
                    :disabled="invalid || !linkedinURL || linkedInBaseURL === linkedinURL"
                  >
                    Proceed
                  </MyButton>
                </form>
                <span class="inputError" v-if="linkedinError">{{ linkedinError }}</span>
                <span class="apiLoading" v-if="apiLoadingText">{{ apiLoadingText }}</span>
              </ValidationProvider>
            </div>
          </ValidationObserver>
          <a href="#" @click.prevent="skipLinkedin" class="bottom_skip_link"
            >Naaah, I’ll enter details manually</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AOS from "aos";
import MyInput from "../../components/input.vue";
import MyButton from "../../components/button.vue";
import BasicDetails from "./BasicDetails.vue";
import SalaryDetails from "./SalaryDetails.vue";
import router from "../../router";
import { ValidationProvider, extend, ValidationObserver } from "vee-validate";
import { api } from "../../services/http-common.js";
import {SET_USER_MUTATION} from '../../store';
import QuestionMark from "../../assets/svg/icons/question-mark.svg?inline";

extend("url", {
  validate(url) {
    if (
      /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\//gm.test(url)
    ) {
      return true;
    } else {
      return false;
    }
  },
  message: "Please enter a valid linkedin URL",
});

export default {
  data() {
    return {
      linkedinURL: "",
      linkedinDetails: null,
      show: {
        salaryDetails: false,
        basicDetails: false,
      },
      loading: {
        linkedinURL: false,
        basicDetails: false,
        salaryDetails: false,
      },
      linkedinError: "",
      apiLoadingText: "",
      linkedInBaseURL: "https://linkedin.com/in/",
    };
  },
  components: {
    MyInput,
    MyButton,
    BasicDetails,
    SalaryDetails,
    ValidationProvider,
    ValidationObserver,
    QuestionMark,
  },
  mounted: async function () {
    AOS.init({
      delay: 100,
      duration: 400, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: true,
    });

    const userDetails = this.$store.getters.getUser || {};
    // Fill LinkedIn URL
    if (userDetails.linkedin_url) {
      this.linkedinURL = userDetails.linkedin_url;
    }
    // Skip LinkedIn and Basic details if Education and WorkEx is there
    if (
      userDetails.education &&
      userDetails.education.length &&
      userDetails.work_ex &&
      userDetails.work_ex.length
    ) {
      if (userDetails.salary && userDetails.salary.length) {
        this.salaryDetailsBtnClick();
      } else {
        this.basicDetailsBtnClick();
      }
    }
  },
  computed: {},
  methods: {
    prefillLinkedInBaseUrl(){
      if(this.linkedinURL === "") this.linkedinURL = this.linkedInBaseURL
    },
    getLinkedinDetailsForUser(){
      return api
        .post(`api/v1/linkedin_profile/?url=${this.linkedinURL}`, {})
        .then((response) => {
          this.linkedinDetails = { ...response.data.data };
          const {userDetails} = this.$store.state
          const {work_ex, education} = response.data.data
          this.$store.commit(SET_USER_MUTATION, {...userDetails, work_ex, education});
        })
    },
    fetchLinkedInData(e = null) {
      e?.preventDefault()
      if(this.linkedinURL == '' || this.linkedinError !== '' || this.linkedinURL === this.linkedInBaseURL){
        return 
      }
      this.loading.linkedinURL = true;
      this.apiLoadingText = "This might take a couple of seconds...";
      this.getLinkedinDetailsForUser()
        .then(() => {
          this.show.basicDetails = true;
          this.loading.linkedinURL = false;
          this.apiLoadingText = "";
        })
        .catch((error) => {
          this.apiLoadingText = "";
          this.linkedinError =
            "An error occured. Please try again later or continue to enter your details manually.";
          this.loading.linkedinURL = false;
          console.log(error);
        });
    },
    skipLinkedin() {
      this.show.basicDetails = true;
      this.linkedinDetails = null;
    },
    basicDetailsBtnClick() {
      this.loading.basicDetails = true;
      this.show.basicDetails = false;
      this.show.salaryDetails = true;
      this.getLinkedinDetailsForUser()
    },
    salaryDetailsBtnClick() {
      this.show.salaryDetails = false;
      router.push({ path: "/know-your-self-worth/insights" });
    },
    goBack() {
      if(this.linkedinDetails === null){
        this.fetchLinkedInData()
      }
      this.show.basicDetails = true;
      this.show.salaryDetails = false;
    }
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/mixins';
.selfWorth--form {
  margin: 3% 40px;
  @include respond(sm) {
    margin: 3% 20px; // SCREEN-PADDING
  }
  .disclaimer {
    font-size: 14px;
    color: #181815;
    width: fit-content;
    margin: auto;
    display: flex;
    align-items: center;
    padding: 18px 32px;
    border-radius: 20px 20px 0 0;
    background: #cbd83f;

    @include respond(md) {
      width: 100%;
    }

    &:before {
      content: "";
      display: inline-block;
      width: 35px;
      margin-bottom: -4px;
      // margin-right: 8px;
      height: 18px;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml,%3Csvg%20width%3D'18'%20height%3D'18'%20viewBox%3D'0%200%2018%2018'%20fill%3D'none'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%20%3Cpath%20d%3D'M9%200.25C11.3206%200.25%2013.5462%201.17187%2015.1872%202.81282C16.8281%204.45376%2017.75%206.67936%2017.75%209C17.75%2011.3206%2016.8281%2013.5462%2015.1872%2015.1872C13.5462%2016.8281%2011.3206%2017.75%209%2017.75C6.67936%2017.75%204.45376%2016.8281%202.81282%2015.1872C1.17187%2013.5462%200.25%2011.3206%200.25%209C0.25%206.67936%201.17187%204.45376%202.81282%202.81282C4.45376%201.17187%206.67936%200.25%209%200.25ZM9%204C8.8413%203.99986%208.68432%204.03283%208.53908%204.09679C8.39384%204.16075%208.26353%204.2543%208.15648%204.37146C8.04944%204.48862%207.96801%204.62682%207.91739%204.77723C7.86677%204.92764%207.84807%205.08696%207.8625%205.245L8.31875%2010.2525C8.33644%2010.4209%208.41584%2010.5767%208.54164%2010.69C8.66743%2010.8032%208.83072%2010.8659%209%2010.8659C9.16928%2010.8659%209.33257%2010.8032%209.45836%2010.69C9.58416%2010.5767%209.66356%2010.4209%209.68125%2010.2525L10.1363%205.245C10.1507%205.08706%2010.132%204.92785%2010.0815%204.77753C10.0309%204.6272%209.9496%204.48906%209.84269%204.37192C9.73578%204.25478%209.60563%204.1612%209.46053%204.09717C9.31544%204.03313%209.1586%204.00004%209%204ZM9%2014C9.26522%2014%209.51957%2013.8946%209.70711%2013.7071C9.89464%2013.5196%2010%2013.2652%2010%2013C10%2012.7348%209.89464%2012.4804%209.70711%2012.2929C9.51957%2012.1054%209.26522%2012%209%2012C8.73478%2012%208.48043%2012.1054%208.29289%2012.2929C8.10536%2012.4804%208%2012.7348%208%2013C8%2013.2652%208.10536%2013.5196%208.29289%2013.7071C8.48043%2013.8946%208.73478%2014%209%2014Z'%20fill%3D'%23181815'%2F%3E%20%3C%2Fsvg%3E");
      @include respond(sm) {
        width: 50px;
      }
    }

    p {
      display: inline-block;
      margin-top: 4px;
    }
  }
  .form-container {
    display: flex;
    width: 100%;
    border-radius: 20px;
    background: white;
    // overflow: hidden;
    min-height: 700px !important;
    @include respond(lg) {
      min-height: 0 !important;
    }


    .form-left {
      background: #f1f2e4;
      width: 30%;
      border-radius: 20px 0 0 20px;
      position: relative;
      overflow: hidden;
        @include respond(lg-only) {
          h4 {
            padding: 34px 24px !important;
          }
          .self-worth-left .progress-container {
            width: 100% !important;
            margin: 0 24px !important;
          }
        }

      .self-worth-left {
        display: flex;
        width: 100%;
        justify-content: space-evenly;
        flex-direction: column;
        @include respond(sm) {
          justify-content: center;
          flex-direction: inherit;
        }

        .progress-container {
          display: flex;
          width: 90%;
          position: relative;
          justify-content: flex-start;
          margin: 0 33px 20px 42px;
          @include respond(md) {
            width: 60%;
          }
          @include respond(sm) {
            width: 100%;
          }
          .progress-item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            width: 33%;
            position: relative;
            @include respond(sm) {
              align-items: center;
            }

            .progress-item-container {
              display: flex;
              flex-direction: column;
              align-items: center;
            }

            &:after {
              content: "";
              height: 2px;
              width: 100%;
              background: #d6d9b0;
              position: absolute;
              z-index: 1;
              left: 25px;
              margin-top: -27px;

              @include respond(sm) {
                width: 90%;
                left: 59%;
              }
            }

            &:last-child {
              &:after {
                display: none;
              }
            }

            .progress-image {
              border: 2px solid #d6d9b0;
              display: block;
              width: 36px;
              height: 36px;
              border-radius: 100%;
              // background: #d6d9b0;
              display: flex;
              align-items: center;
              justify-content: center;
              z-index: 2;
              position: relative;
            }

            .progress-title {
              font-size: 14px;
              margin-top: 8px;
            }
          }

          &.--boring {
            .progress-item {
              &:nth-child(1) {
                .progress-image {
                  background: #cbd83f;
                  border-color: #cbd83f;
                }

                &:after {
                  background: #cbd83f;
                }
              }
              &:nth-child(2) {
                .progress-image {
                  background: #cbd83f;
                  border-color: #cbd83f;
                }

                .progress-title {
                  font-weight: bold;
                }
              }
            }
          }

          &.--exciting {
            .progress-item {
              &:nth-child(1) {
                .progress-image {
                  background: #cbd83f;
                  border-color: #cbd83f;
                }

                &:after {
                  background: #cbd83f;
                }
              }
              &:nth-child(2) {
                .progress-image {
                  background: #cbd83f;
                  border-color: #cbd83f;
                }

                &:after {
                  background: #cbd83f;
                }
              }

              &:nth-child(3) {
                .progress-image {
                  background: #cbd83f;
                  border-color: #cbd83f;
                }
                .progress-title {
                  font-weight: bold;
                }
              }
            }
          }
        }
      }

      h4 {
        font-weight: 700;
        color: black;
        font-size: 30px;
        text-align: left;
        padding: 34px 42px;
        @include respond(sm) {
          text-align: center;
        }
      }

      .bottom-image {
        margin-top: 20px;
        position: absolute;
        bottom: -8px;

        img {
          height: 100%;
          width: 100%;
        }
        @include respond(sm) {
          display: none;
        }
      }
    }

    .form-right {
      width: 70%;
      margin: auto;

      .linkedinForm {
        > span {
          margin: auto !important;
          display: initial !important;
        }
        margin: 12% 10%;
        .linkedin-container {
          width: 100%;
          background: #f1f2e4;
          border: 2px solid #d6d9b0;
          box-sizing: border-box;
          border-radius: 16px;
          padding: 24px;
          position: relative;
          h4 {
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 25px;
            text-align: center;
          }

          p {
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            color: #333333;
            margin: 8px 0 28px 0;
          }
        }

        span.inputError {
          width: 100%;
          margin: 16px 0;
          text-align: center;
          color: red;
          font-size: 12px;
        }

        span.apiLoading {
          font-size: 12px;
          text-align: center;
          width: 100%;
          margin: 16px 0;
        }

        span {
          margin: 36px auto;
          display: block;
          width: fit-content;
        }
        &__linked-get-link {
          margin: 30px 0;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      a {
        color: #8e9447;
        text-decoration: underline;
        display: inline-block;
        margin-left: 5px;
      }
      a.bottom_skip_link {
        margin-top: 20px;
        cursor: pointer !important;
      }
    }
  }
}

.main_title {
  color: var(--bg-text);
  font-weight: 800;
  font-size: 66px;
  margin: 0 auto;
  line-height: 82px;

  span {
    font-family: BrotherLine !important;
    position: relative;
    color: white;
    font-size: 74px;
    text-align: center;
    display: block;
    margin-bottom: -34px;
    font-weight: 400;
    margin-left: -30px;
  }
}

input {
  width: 100%;
  padding: 18px 38px 14px 18px;
  font-weight: 400;
  font-size: 16px;
  color: black;
  outline: none;
  border-radius: 10px;
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.18);

  &::placeholder {
    color: #adadad;
  }

  &:focus {
    border: 1px solid #0000004d !important;
  }
}

@media (max-width: 768px) {
  .selfWorth--form {
    .logo {
      display: none;
      padding: 50px 0 64px 0;
    }

    .main_title {
      display: none;
    }

    .form-left-img {
      display: flex;
      flex-direction: column;

      h4 {
        font-size: 24px !important;
        max-width: 100% !important;
        padding: 8% !important;
      }

      img.top_image {
        margin-left: 38%;
        margin-bottom: -16%;
        width: 60%;
      }

      img.bottom_image {
        max-width: 50%;
      }
    }

    .form-container {
      flex-direction: column;
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;

      .form-left {
        width: 100%;
        border-radius: 0;
        h4 {
          padding: 34px 42px;
        }
      }

      .form-right {
        width: 100%;
        border-radius: 16px;
        background: white;
        margin-top: -8px !important;

        .linkedinForm {
          margin: 12% 0;
          span.inputError {
            position: relative !important;
          }

          span.apiLoading {
            position: relative !important;
            width: 100%;
            font-weight: 500;
          }

          .linkedin-container {
            width: 100%;
            background: none;
            border: none;
            padding: 0 42px;

            h4 {
              text-align: left;
              color: black;
            }

            p {
              text-align: left;
              margin: 8px 0 14px 0;

              font-size: 14px;
              font-weight: 400;
            }

            a {
              text-align: left;
              font-size: 14px;
              display: block;
              // margin-top: 12px;
              font-weight: 400;
            }            
          }

          span {
            // margin: -10px auto 0;
            margin-top: -10px;
            font-size: 14px;
            font-weight: bold;
            width: 100%;
          }

          :deep(.my_button_container) {
            width: 100% !important;
          }
        }
      }
    }

    .disclaimer {
      margin-top: -40px !important;

      p {
        margin-top: 10px !important;
        line-height: 18px;
      }
    }
  }

  .bottom-image {
    text-align: center;
    position: absolute;
    right: 0;
    bottom: -8px;
    width: 150px;
  }
}

::v-deep {
  .my_button_wrapper {
    button {
      width: 100% !important;
      border-radius: 10px;
      margin-top: 16px;
      padding: 14px 40px;
    }
  }

  .linkedin-container.error {
    input {
      border: 1px solid red !important;
    }
  }

  .form-bottom {
    button {
      width: 100%;
      margin-top: 32px;
    }
  }
}
</style>
