var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"waitlist_page_wrapper"},[_c('div',{staticClass:"top_header_section"},[_c('div',{staticClass:"top_banner"},[_c('Header')],1),_c('div',{staticClass:"section_content"},[_c('div',{staticClass:"left_part"},[_c('div',{staticClass:"main_title"},[_vm._v("You’re on our waitlist now!")]),_c('div',{staticClass:"secondary_title"},[_vm._v(" We shall email you when we enable the access to you. Until then keep referring to move up the waitlist ")]),_c('div',{staticClass:"wait_list_wrapper"},[_c('img',{attrs:{"src":require("../assets/images/waitlist/frame.png"),"alt":""}}),_c('div',{staticClass:"wait_list"},[_c('p',{staticClass:"help_text"},[_vm._v("YOUR CURRENT POSITION")]),_c('p',{staticClass:"number",class:{
                'medium-position': this.waitlistNo.length >= 6,
                'high-position': this.waitlistNo.length >= 10,
              }},[_vm._v(" "+_vm._s(!this.waitlistNo ? "-" : this.waitlistNo)+" ")])])]),_c('p',{staticClass:"secondary_title"},[_vm._v(" As per your current position, you would receive "+_vm._s(_vm.currentUserPennis)+" trons to view others’ salaries. But this is bound to change based on your referral count and that of other users. ")])]),_vm._m(0)])]),_c('div',{staticClass:"know_your_self_worth"},[_c('div',{staticClass:"content_wrapper"},[_vm._m(1),_c('div',{staticClass:"section_right",attrs:{"data-aos":"fade-up"}},[_vm._m(2),_c('div',{staticClass:"description"},[_vm._v(" Do you think you’re at your fullest earning potential right now? Don’t you want to understand what your real worth is in the competitive salary market? Figure it out now! ")]),_c('div',{staticClass:"button_wrapper"},[_c('router-link',{attrs:{"to":{ name: 'SelfWorth' }}},[_vm._v(" Know your self worth ")])],1)])])]),_c('div',{staticClass:"want_more_pennies_wrapper"},[_c('div',{staticClass:"content_wrapper"},[_c('div',{staticClass:"left_part"},[_c('div',{staticClass:"section_title"},[_vm._v("Want more trons?")]),_c('div',{staticClass:"section_secondary_title"},[_vm._v(" Share your unique referral link to your friends and colleagues to move up the waitlist and win more trons ")]),_c('div',{staticClass:"input_wrapper"},[_c('MyInput',{attrs:{"value":'https://uncovor.com/#/?ref=' + this.referralCode,"readonly":true}}),_c('span',{staticClass:"copy_button",on:{"click":_vm.copy_referralcode}},[_c('img',{attrs:{"src":require("../assets/images/waitlist/copy.svg"),"alt":""}})])],1),_c('div',{staticClass:"social_media_wrapper"},[_vm._m(3),_c('a',{attrs:{"target":"_blank","href":'https://www.facebook.com/sharer/sharer.php?u=https://uncovor.com/#/?ref=' +
              this.referralCode}},[_c('img',{attrs:{"src":require("../assets/images/waitlist/facebook.svg"),"alt":"Facebook","srcset":""}})]),_c('a',{attrs:{"target":"_blank","href":'https://twitter.com/intent/tweet?text=Join&nbsp;me&nbsp;on&nbsp;Uncovor&url=https://uncovor.com/#/?ref=' +
              this.referralCode}},[_c('img',{attrs:{"src":require("../assets/images/waitlist/twitter.svg"),"alt":"Twitter","srcset":""}})]),_c('a',{attrs:{"target":"_blank","href":'whatsapp://send?text=Joinhttps://uncovor.com/#/?ref=' +
              this.referralCode}},[_c('img',{attrs:{"src":require("../assets/images/waitlist/whatsapp.svg"),"alt":"Whatsapp","srcset":""}})])]),_vm._m(4)]),_vm._m(5)])]),_c('div',{staticClass:"move_up_waiting_list_wrapper"},[_vm._m(6),_c('div',{staticClass:"section_right",attrs:{"data-aos":"fade-up"}},[_c('div',{staticClass:"title"},[_vm._v("Move up the Waitlist")]),_c('div',{staticClass:"description"},[_vm._v(" Upload your salary slip to move up the waitlist and earn more trons ")]),(this.verification_status == 0)?[_c('MyButton',{attrs:{"type":"accent"},on:{"click":_vm.uploadSalarySlip}},[_vm._v(" Upload salary slip ")])]:(this.verification_status == 1)?[_c('MyButton',{attrs:{"type":"disabled"}},[_vm._v(" Salary slip verified ")])]:(this.verification_status == 2)?[_c('MyButton',{attrs:{"disabled":true}},[_vm._v(" Salary slip under review ")])]:(this.verification_status == 3)?[_c('MyButton',{attrs:{"type":"disabled"}},[_vm._v(" Salary slip rejected ")])]:(this.verification_status == 4)?[_c('MyButton',{attrs:{"type":"disabled"}},[_vm._v(" Re upload salary slip after 6 Months ")])]:_vm._e()],2)]),_c('FooterCanvas'),_c('Footer'),_c('UploadPayslip',{attrs:{"show":_vm.showUploadPaySlip},on:{"closeModal":this.closeModal}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right_part"},[_c('img',{attrs:{"src":require("../assets/images/waitlist/top-banner-right.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section_left"},[_c('div',{staticClass:"image_wrapper"},[_c('div',{staticClass:"image_wrapper-bg"}),_c('img',{attrs:{"data-aos":"fade-up","src":require("@/assets/images/waitlist/know-your-self-worth.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('span',[_vm._v("Know your")]),_vm._v("Self Worth")])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"target":"_blank","href":"\n            https://www.linkedin.com/sharing/share-offsite/?url=https://uncovor.com"}},[_c('img',{attrs:{"src":require("../assets/images/waitlist/linkedin.svg"),"alt":"LinkedIn","srcset":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"waitlist_chart_wrapper"},[_c('div',{staticClass:"table_header"},[_c('div',{staticClass:"position"},[_vm._v("Waitlist Position")]),_c('div',{staticClass:"trons_value"},[_vm._v("Trons")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"position"},[_vm._v("1 - 2,000")]),_c('div',{staticClass:"trons_value"},[_vm._v("10,000")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"position"},[_vm._v("2,001 - 5,000")]),_c('div',{staticClass:"trons_value"},[_vm._v("5,000")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"position"},[_vm._v("5,001 - 10,000")]),_c('div',{staticClass:"trons_value"},[_vm._v("1,000")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"position"},[_vm._v("10,001 - 25,000")]),_c('div',{staticClass:"trons_value"},[_vm._v("500")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"position"},[_vm._v("25,001 - 50,000")]),_c('div',{staticClass:"trons_value"},[_vm._v("100")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right_part"},[_c('img',{attrs:{"src":require("../assets/images/waitlist/want-more-trons.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section_left"},[_c('div',{staticClass:"image_wrapper"},[_c('img',{attrs:{"data-aos":"fade-up","src":require("../assets/images/waitlist/move-up-waitlist.png")}})])])
}]

export { render, staticRenderFns }