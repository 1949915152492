<template>
  <ValidationObserver ref="observer" v-slot="{ invalid }">
    <div class="basicDetails">
      <div class="form-fields">
        <div class="form-top">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <span>Who do you identify yourself as?</span>
            <div class="radio-container">
              <input
              v-model="basicDetails.gender"
              type="radio"
              id="gender_male"
              name="gender"
              value="he/him"
              :class="{'ios_size': isIOS()}"
              />
              <label for="gender_male" :class="{'ios_size': isIOS()}">He/him</label>
              <input
                type="radio"
                id="gender_female"
                name="gender"
                v-model="basicDetails.gender"
                value="she/her"
                :class="{'ios_size': isIOS()}"
              />
              <label for="gender_female" :class="{'ios_size': isIOS()}">She/her</label>
              <input
                type="radio"
                id="gender_they/them"
                name="gender"
                value="they/their"
                v-model="basicDetails.gender"
                :class="{'ios_size': isIOS()}"
              />
              <label for="gender_they/them" :class="{'ios_size': isIOS()}">They/them</label>
            </div>
            <span class="inputError">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="form-bottom">
          <div class="input-groups">
            <div
              :class="`input-field ${
                basicDetails?.college?.name?.length > 0 ||
                show.basicDetailsDropdown.college
                  ? show.basicDetailsDropdown.college
                    ? 'dropdown active'
                    : 'active'
                  : ''
              }`"
            >
              <ValidationProvider rules="required|min:3" v-slot="{ errors }">
                <input
                  type="text"
                  id="college"
                  name="college"
                  :class="`${errors.length > 0 ? 'error' : ''}`"
                  autocomplete="off"
                  v-model="basicDetails.college.name"
                  placeholder=" "
                  @focus="() => (show.basicDetailsDropdown.college = true)"
                  @keyup="
                    () => { apiSearch(
                        'college',
                        'collegeList',
                        basicDetails.college.name
                      ); basicDetails.college.id = null; }
                      
                  "
                  v-on:blur="show.basicDetailsDropdown.college = false"
                />
                <span class="inputError">{{ errors[0] }}</span>
                <span class="placeholder">College</span>
                <ul
                  v-if="show.basicDetailsDropdown.college"
                  class="search-dropdown"
                  @scroll="onListScroll($event, 'collegeList')"
                >
                  <li v-if="searching?.collegeList == true">loading...</li>
                  <li v-else-if="collegeList?.length == 0">No results found</li>
                  <li
                    v-else
                    :key="collage.id"
                    v-for="collage in collegeList"
                    @mousedown="
                      () => {
                        basicDetails.college.name = collage.name;
                        basicDetails.college.id = collage.id;
                        show.basicDetailsDropdown.college = false;
                      }
                    "
                  >
                    <small>{{ collage.name }}</small>
                  </li>
                </ul>
              </ValidationProvider>
            </div>

            <!-- Company -->
            <div
              :class="`input-field ${
                basicDetails?.company?.name?.length > 0 ||
                show.basicDetailsDropdown.company
                  ? show.basicDetailsDropdown.company
                    ? 'dropdown active'
                    : 'active'
                  : ''
              }`"
            >
              <ValidationProvider rules="required|min:3" v-slot="{ errors }">
                <input
                  type="text"
                  id="company"
                  v-model="basicDetails.company.name"
                  :class="`${errors.length > 0 ? 'error' : ''}`"
                  name="company"
                  autocomplete="off"
                  placeholder=" "
                  @keyup="
                    () => {
                      apiSearch(
                        'company',
                        'companiesList',
                        basicDetails.company.name
                      ); basicDetails.company.id = null;
                    } 
                  "
                  @focus="show.basicDetailsDropdown.company = true"
                  @blur="show.basicDetailsDropdown.company = false"
                />
                <span class="inputError">{{ errors[0] }}</span>
                <span class="placeholder">Company </span>
                <ul
                  class="search-dropdown"
                  v-if="show.basicDetailsDropdown.company"
                  @scroll="onListScroll($event, 'companiesList')"
                >
                  <li v-if="searching?.companiesList == true">loading...</li>
                  <li v-else-if="companiesList?.length == 0">No results found</li>
                  <li
                    v-else
                    :key="company.name"
                    v-for="company in companiesList"
                    class="company-list"
                    @mousedown="
                      () => {
                        basicDetails.company.name = company.name;
                        basicDetails.company.id = company.id;
                        show.basicDetailsDropdown.company = false;
                        getLevels(company.id);
                      }
                    "
                  > 
                    <img v-if="company.logo_url" :src="company.logo_url" :alt="company.name" width="50" />
                    <small>{{ company.name }}</small>
                  </li>
                </ul>
              </ValidationProvider>
            </div>

            <!-- Job Family / Job Domain -->
            <div
              :class="`input-field ${
                basicDetails?.jobFamily?.name?.length > 0 ||
                show.basicDetailsDropdown.jobFamily
                  ? show.basicDetailsDropdown.jobFamily
                    ? 'dropdown active'
                    : 'active'
                  : ''
              }`"
            >
              <ValidationProvider rules="required" v-slot="{ errors }">
                <input
                  type="text"
                  id="jobFamily"
                  v-model="basicDetails.jobFamily.name"
                  name="jobFamily"
                  autocomplete="off"
                  placeholder=" "
                  :class="`${errors.length > 0 ? 'error' : ''}`"
                  @keyup=" () => {
                    search(
                      'jobFamily',
                      basicDetails.jobFamily.name
                    );
                    basicDetails.jobFamily.id = null;
                    basicDetails.specialisation = {name: '', id: ''}
                    specialisationList = []
                    specialisationApiData = []
                  }
                  "
                  @blur="show.basicDetailsDropdown.jobFamily = false"
                  @focus="show.basicDetailsDropdown.jobFamily = true"
                />
                <span class="inputError">{{ errors[0] }}</span>
                <span class="placeholder">Job Family</span>
                <ul
                  class="search-dropdown"
                  v-if="
                    show.basicDetailsDropdown.jobFamily
                  "
                  @scroll="
                    onListScroll($event, 'jobFamilysApiData', { size: 25 })
                  "
                >
                  <li v-if="getJobFamilyListData.length == 0">No results found</li>
                  <li
                    v-else
                    :key="jobFamily.name"
                    v-for="jobFamily in getJobFamilyListData"
                    @mousedown="
                      () => {
                        basicDetails.jobFamily.name = jobFamily.name;
                        basicDetails.jobFamily.id = jobFamily.id;
                        show.basicDetailsDropdown.jobFamily = false;
                        getSpecialisation(jobFamily.id);
                        getJobTitle(jobFamily.id);
                      }
                    "
                  >
                    <small>{{ jobFamily.name }}</small>
                  </li>
                </ul>
              </ValidationProvider>
            </div>

            <!-- Job Title (Designation) -->
            <div
              :class="`input-field ${
                basicDetails?.jobTitle?.name.length > 0 ||
                show.basicDetailsDropdown.jobTitle
                  ? show.basicDetailsDropdown.jobTitle
                    ? 'dropdown active'
                    : 'active'
                  : ''
              }`"
            >
              <ValidationProvider rules="required" v-slot="{ errors }">
                <input
                  type="text"
                  id="jobTitle"
                  v-model="basicDetails.jobTitle.name"
                  name="jobTitle"
                  autocomplete="off"
                  placeholder=" "
                  :class="`${errors.length > 0 ? 'error' : ''}`"
                  @blur="show.basicDetailsDropdown.jobTitle = false"
                  @focus="show.basicDetailsDropdown.jobTitle = true"
                  @keyup=" () => {
                    search(
                      'jobTitle',
                      basicDetails.jobTitle.name
                    );
                  }
                  "
                />
                <span class="inputError">{{ errors[0] }}</span>
                <span class="placeholder">Job Title</span>
                <ul
                  class="search-dropdown"
                  v-if="basicDetails?.jobFamily?.id && show.basicDetailsDropdown.jobTitle"
                  @scroll="onListScroll($event, 'jobTitleList')"
                >
                  <li v-if="searching.jobTitleList == true">loading...</li>
                  <li v-else-if="getJobTitleListData.length == 0">No results found</li>
                  <li
                    v-else
                    :key="post.name"
                    v-for="post in getJobTitleListData"
                    @mousedown="
                      () => {
                        basicDetails.jobTitle.name = post.name;
                        basicDetails.jobTitle.id = post.id;
                        show.basicDetailsDropdown.jobTitle = false;
                      }
                    "
                  >
                    <small>{{ post.name }}</small>
                  </li>
                </ul>
              </ValidationProvider>
            </div>

            <!-- Level -->
            <div
              :class="['input-field', {'dropdown active': basicDetails?.level.length > 0 && show.basicDetailsDropdown.level}, {'active': basicDetails?.level.length > 0 || show.basicDetailsDropdown.level}, {'hide-drop-arrow': !(getLevelListData.length > 0 && show.basicDetailsDropdown.level)}]"
            >
              <ValidationProvider rules="required" v-slot="{ errors }">
                <input
                  type="text"
                  id="level"
                  v-model="basicDetails.level"
                  name="level"
                  autocomplete="off"
                  placeholder=" "
                  :class="[
                    errors.length > 0 ? 'error' : '',
                    { 'hide-input-cursor': getLevelListData.length > 0 },
                  ]"
                  @focus="show.basicDetailsDropdown.level = true"
                  @blur="show.basicDetailsDropdown.level = false"
                  @keyup="search('level', basicDetails.level)"
                  @keydown="
                    ($event) =>
                      getLevelListData.length > 0
                        ? $event.preventDefault()
                        : null
                  "
                />
                <span class="inputError">{{ errors[0] }}</span>

                <span class="placeholder">Level</span>
                <ul
                  class="search-dropdown"
                  v-if="
                    getLevelListData.length > 0 &&
                    show.basicDetailsDropdown.level
                  "
                  @scroll="
                    onListScroll($event, 'levelList', {
                      company: basicDetails.company.id,
                    })
                  "
                >
                  <li
                    :key="'l' + item.level"
                    v-for="item in getLevelListData"
                    @mousedown="
                      () => {
                        basicDetails.level = item.level;
                        show.basicDetailsDropdown.level = false;
                      }
                    "
                  >
                    <small>{{ item.level }}</small>
                  </li>
                </ul>
              </ValidationProvider>
            </div>

            <!-- Specialisation -->
            <div
              :class="`input-field 
              ${(specialisationApiData || specialisationList).length > 0 ? show.basicDetailsDropdown.specialisation ? 'dropdown active' : '' : 'hide-drop-arrow' } 
              ${basicDetails?.specialisation?.name?.length > 0 ? 'active' : ''}`"
            >
              <ValidationProvider v-slot="{ errors }">
                <input
                  type="text"
                  id="specialisation"
                  autocomplete="off"
                  :class="`${errors.length > 0 ? 'error' : ''}`"
                  v-model="basicDetails.specialisation.name"
                  name="specialisation"
                  placeholder=" "
                  @keyup="
                    (specialisationApiData || specialisationList).length > 0 ? 
                    search(
                      'specialisation',
                      basicDetails.specialisation.name
                    ) : null
                  "
                  @blur="show.basicDetailsDropdown.specialisation = false"
                  @focus="show.basicDetailsDropdown.specialisation = true"
                />
                <span class="inputError">{{ errors[0] }}</span>
                <span class="placeholder">Specialisation (optional)</span>
                <ul
                  class="search-dropdown"
                  v-if="
                    specialisationApiData.length > 0 &&
                    show.basicDetailsDropdown.specialisation
                  "
                  @scroll="
                    onListScroll($event, 'specialisationList', {
                      category_id: basicDetails.jobFamily.id,
                    })
                  "
                >
                  <li v-if="specialisationList.length == 0">No results found</li>
                  <li
                    :key="post.name"
                    v-for="post in getJobSpecialisationListData"
                    @mousedown="
                      () => {
                        basicDetails.specialisation.name = post.name;
                        basicDetails.specialisation.id = post.id;
                        show.basicDetailsDropdown.specialisation = false;
                      }
                    "
                  >
                    <small>{{ post.name }}</small>
                  </li>
                </ul>
              </ValidationProvider>
            </div>

            <!-- Location  -->
            <div
              :class="`input-field ${
                basicDetails?.location?.name?.length > 0 ||
                show.basicDetailsDropdown.location
                  ? show.basicDetailsDropdown.location
                    ? 'dropdown active'
                    : 'active'
                  : ''
              }`"
            >
              <ValidationProvider rules="required|min:3" v-slot="{ errors }">
                <input
                  type="text"
                  id="location"
                  v-model="basicDetails.location.name"
                  name="location"
                  placeholder=" "
                  autocomplete="off"
                  @keyup="
                    apiSearch(
                      'location',
                      'locationsList',
                      basicDetails.location.name
                    )
                  "
                  :class="`${errors.length > 0 ? 'error' : ''}`"
                  @blur="show.basicDetailsDropdown.location = false"
                  @focus="show.basicDetailsDropdown.location = true"
                />
                <span class="inputError">{{ errors[0] }}</span>
                <span class="placeholder">Location</span>
                <ul
                  class="search-dropdown"
                  v-if="
                    show.basicDetailsDropdown.location
                  "
                  @scroll="onListScroll($event, 'locationsList')"
                > 
                  <li v-if="searching.locationsList == true">loading...</li>
                  <li v-else-if="locationsList.length == 0">No results found</li>
                  <li
                    :key="location.city"
                    v-else
                    v-for="location in locationsList"
                    @mousedown="
                      () => {
                        basicDetails.location.name = location.city;
                        basicDetails.location.id = location.id;
                        show.basicDetailsDropdown.location = false;
                      }
                    "
                  >
                    <small>{{ location.city }}</small>
                  </li>
                </ul>
              </ValidationProvider>
            </div>

            <!-- Location Arrangement -->
            <div
              :class="`input-field ${
                basicDetails?.locationArrangement?.name.length > 0 ||
                show.basicDetailsDropdown.locationArrangement
                  ? show.basicDetailsDropdown.locationArrangement
                    ? 'dropdown active'
                    : 'active'
                  : ''
              }`"
            >
              <ValidationProvider rules="required" v-slot="{ errors }">
                <input
                  type="text"
                  id="locationArrangement"
                  v-model="basicDetails.locationArrangement.name"
                  :class="`${errors.length > 0 ? 'error' : ''}`"
                  name="locationArrangement"
                  autocomplete="off"
                  placeholder=" "
                  @focus="show.basicDetailsDropdown.locationArrangement = true"
                  @blur="show.basicDetailsDropdown.locationArrangement = false"
                  readonly
                />
                <span class="inputError">{{ errors[0] }}</span>
                <span class="placeholder">Location Arrangement</span>
                <ul
                  class="search-dropdown"
                  v-if="
                    locationArrangementList.length > 0 &&
                    show.basicDetailsDropdown.locationArrangement
                  "
                >
                  <li
                    :key="post.name"
                    v-for="post in locationArrangementList"
                    @mousedown="
                      () => {
                        basicDetails.locationArrangement.name = post.name;
                        basicDetails.locationArrangement.id = post.id;
                        show.basicDetailsDropdown.locationArrangement = false;
                      }
                    "
                  >
                    <small>{{ post.name }}</small>
                  </li>
                </ul>
              </ValidationProvider>
            </div>

            <!-- Total years of Experience -->
            <div class="input-field hide-dropdown YoE active">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="flex">
                  <input
                    v-numericOnly
                    type="number"
                    id="totalExp"
                    class="years-field"
                    v-model="basicDetails.totalExp.years"
                    name="totalExp_years"
                    placeholder="YY"
                    autocomplete="off"
                    @change="validateExperiences"
                  />
                  <span v-if="basicDetails.totalExp.years > 0" :class="['YoE--year', {'ios_size': isIOS()}]"
                    >year<span v-if="basicDetails.totalExp.years > 1">s</span></span
                  >
                  <span class="placeholder">Total Years of Experience</span>
                </div>
                <span v-if="basicDetails.totalExp.years > 0" :class="['separator', {'ios_size': isIOS()}]"
                  >/</span
                >
                <div class="flex">
                  <input
                    v-numericOnly
                    type="number"
                    class="months-field"
                    v-model="basicDetails.totalExp.months"
                    name="totalExp_months"
                    placeholder="MM"
                    autocomplete="off"
                    @change="validateExperiences"
                  />
                  <span v-if="basicDetails.totalExp.months > 0" :class="['YoE--month', {'ios_size': isIOS()}]"
                    >month<span v-if="basicDetails.totalExp.months > 1">s</span></span
                  >
                </div>
                <span class="inputError">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

            <!-- Years at current company -->
            <div
              :class="`input-field YaC hide-dropdown ${
                basicDetails.currentCompanyExp ||
                show.basicDetailsDropdown.currentCompanyExp
                  ? show.basicDetailsDropdown.currentCompanyExp
                    ? 'active'
                    : 'active'
                  : ''
              }`"
            >
              <ValidationProvider
                :rules="{ required: true }"
                v-slot="{ errors }"
              >
                <div class="flex">
                  <input
                    v-numericOnly
                    type="number"
                    id="currentCompanyExp"
                    :class="`years-field ${errors.length > 0 ? 'error' : ''}`"
                    v-model="basicDetails.currentCompanyExp.years"
                    name="currentCompanyExp_years"
                    placeholder="YY"
                    autocomplete="off"
                    @change="validateExperiences"
                    :disabled="!basicDetails.totalExp.years"
                  />

                  <span v-if="basicDetails.currentCompanyExp.years > 0" :class="['YoE--year', {'ios_size': isIOS()}]"
                    >year<span v-if="basicDetails.currentCompanyExp.years > 1">s</span></span
                  >
                  <span class="placeholder">Experience at current company</span>
                </div>
                <span v-if="basicDetails.currentCompanyExp.years > 0" :class="['separator', {'ios_size': isIOS()}]"
                  >/</span
                >
                <div class="flex">
                  <input
                    v-numericOnly
                    type="number"
                    id="currentCompanyExp"
                    :class="`months-field ${errors.length > 0 ? 'error' : ''}`"
                    v-model="basicDetails.currentCompanyExp.months"
                    name="currentCompanyExp_months"
                    placeholder="MM"
                    autocomplete="off"
                    @change="validateExperiences"
                    :disabled="!basicDetails.totalExp.years && !basicDetails.totalExp.months"
                  />
                  <span v-if="basicDetails.currentCompanyExp.months > 0" :class="['YoE--month', {'ios_size': isIOS()}]"
                    >month<span v-if="basicDetails.currentCompanyExp.months > 1">s</span></span
                  >
                </div>

                <span class="inputError">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <MyButton
            width="100%"
            :loading="basicDetailsLoading"
            :disabled="invalid"
            v-on:click="basicDetailsBtnClick"
            ><template v-if="type == 'edit'"> Save Details </template>
            <template v-else> Can we skip to the good part </template>
          </MyButton>
          <div class="terms">
            By proceeding, you agree to our
            <router-link to="/terms-conditions">
              terms and conditions
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
import MyButton from "../../components/button.vue";
import { api } from "../../services/http-common.js";
import { ValidationProvider, extend, ValidationObserver } from "vee-validate";
import { required, min } from "vee-validate/dist/rules";
import { debounce, cleanObject, getYearsAndMonthsFromMonths } from "../../helpers/utils";
import * as mockData from "../../helpers/mock-data";
import * as constants from "../../helpers/constants";
import { numericOnly, disableInput } from "../../helpers/directives";

extend("required", {
  ...required,
  message: "This field is required",
});
extend("min", {
  ...min,
  message: "Minimum 3 chars required to search",
});

const APIs_MAP = {
  collegeList: { url: "api/v1/colleges/all", field: "collegeList" },
  companiesList: { url: "api/v1/companies/all", field: "companiesList" },
  jobFamilysApiData: {
    url: "api/v1/job_domain/",
    field: "jobFamilysApiData",
    params: { page: 0, size: 25 },
  },
  // jobTitleList: { url: "api/v1/designation/all", field: "jobTitleList" },
  levelList: {
    url: "api/v1/job_level",
    field: "levelList",
    initialFetch: false,
  },
  specialisationList: {
    url: "api/v1/job_specialisation/all",
    field: "specialisationList",
    initialFetch: false,
  },
  locationArrangementList: {
    url: "LOCATION_ARRANGEMENT",
    field: "locationArrangementList",
  },
  locationsList: { url: "api/v1/locations/all", field: "locationsList" },
};

export default {
  directives: { numericOnly, disableInput },
  data() {
    return {
      show: {
        basicDetails: true,
        basicDetailsDropdown: {
          college: false,
          company: false,
          jobFamily: false,
          jobTitle: false,
          level: false,
          location: false,
          totalExp: false,
          locationArrangement: false,
          currentCompanyExp: false,
          specialisation: false,
        },
      },
      currentCompanyName: "",
      basicDetails: {
        gender: "",
        college: {
          name: "",
          id: "",
          existing_id: "",
        },
        company: {
          name: "",
          id: "",
          existing_id: "",
        },
        jobFamily: {
          name: "",
          id: "",
        },
        jobTitle: {
          name: "",
          id: "",
        },
        level: "",
        location: {
          name: "",
          id: "",
        },
        locationArrangement: {
          name: "",
          id: "",
        },
        totalExp: {
          years: "",
          months: "",
        },
        currentCompanyExp: {
          years: "",
          months: "",
        },
        specialisation: {
          name: "",
          id: "",
        },
      },
      basicDetailsLoading: false,
      collegeList: [],
      companiesList: [],
      jobFamilysList: [],
      jobFamilysApiData: [],
      jobFamilysApiCall: false,
      locationsList: [],
      searchAPI: "",
      timeout: null,
      allCollegesList: [],
      // jobTitle
      jobTitleList: [],
      jobTitleApiData: [],
      jobTitleApiCall: false,
      // Location Arrangement
      locationArrangementList: [],
      locationArrangementFilteredList: [],
      // Years at current company
      // currentCompanyExpList: Array.from({length: 40}, (_, i) => i + 1)
      // Level
      levelList: [],
      levelFilteredList: [],
      specialisationList: [],
      specialisationApiData: [],
      // Infinite scroll
      pageNumbersTrack: {
        collegeList: 1,
        companiesList: 1,
        jobFamilysApiData: 1,
        jobTitleList: 1,
        levelList: 1,
        specialisationList: 1,
        locationsList: 1,
      },
      searching: {}
    };
  },
  props: ["loadingBtn", "linkedinDetails", "type"],
  components: { MyButton, ValidationProvider, ValidationObserver },
  computed: {
    getJobTitleListData() {
      const searchInput = this.basicDetails.jobTitle.name
      const apiRecordsForInput = this.jobTitleList.filter((item) =>
        item.name.match(new RegExp(searchInput, 'gi')) !== null
      );
      return !searchInput.length ? this.jobTitleApiData : apiRecordsForInput
    },
    getJobSpecialisationListData() {
      const searchInput = this.basicDetails.specialisation.name
      const apiRecordsForInput = this.specialisationList.filter((item) =>
        item.name.match(new RegExp(searchInput, 'gi')) !== null
      );
      return !searchInput.length ? this.specialisationApiData : apiRecordsForInput
    },
    getJobFamilyListData() {
      const searchInput = this.basicDetails.jobFamily.name
      const apiRecordsForInput = this.jobFamilysApiData.filter((item) =>
        item.name.match(new RegExp(searchInput, 'gi')) !== null
      );
      return !searchInput.length ? this.jobFamilysApiData : apiRecordsForInput
    },
    getLevelListData() {
      return this.levelFilteredList.length
        ? this.levelFilteredList
        : this.levelList;
    },
    getLocationArrangementData() {
      return this.locationArrangementFilteredList.length
        ? this.locationArrangementFilteredList
        : this.locationArrangementList;
    },
  }, 
  methods: {
    isIOS() {
      return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    },
    onListScroll($event, field, params) {
      const { scrollTop, offsetHeight, scrollHeight } = $event.target;
      if (scrollTop + offsetHeight >= scrollHeight && !this.searching[field]) {
        this.getDataOnScroll(field, params);
      }
    },
    getDataOnScroll(field, params) {
      if (APIs_MAP[field] && this[APIs_MAP[field].field].length >= 25) {
        api
          .get(`${APIs_MAP[field].url}`, {
            params: { page: this.pageNumbersTrack[field]++, ...params },
          })
          .then((response) => {
            if (response.data.data.length) {
              response.data.data.forEach((item) => {
                this[APIs_MAP[field].field].push(item);
              });
            }
          });
      }
    },
    getDataDefault(field) {
      if (APIs_MAP[field]) {
        api.get(`${APIs_MAP[field].url}`).then((response) => {
          if (response.data.data.length) {
            this[APIs_MAP[field].field] = response.data.data;
          }
        });
      }
    },
    basicDetailsBtnClick() {
      this.$refs.observer.validate().then((result) => {
        if (!result) {
          console.log("Form validation errors!");
          return;
        }
        this.basicDetailsLoading = true;
        let basicDetailsData = cleanObject({
          gender: this.basicDetails.gender,
          college: {
            name: this.basicDetails.college.name,
            id: this.basicDetails.college.id,
            education_id: this.basicDetails.college.education_id,
          },
          company: {
            name: this.basicDetails.company.name,
            id: this.basicDetails.company.id,
            existing_id: this.basicDetails.company.existing_id,
          },
          // Job Family (Job Domain)
          job_domain: {
            name: this.basicDetails.jobFamily.name,
            id: this.basicDetails.jobFamily.id,
          },
          // Job title (Designation)
          designation: {
            name: this.basicDetails.jobTitle.name,
            id: this.basicDetails.jobTitle.id,
          },
          job_specialisation: {
            name: this.basicDetails.specialisation.name,
            id: this.basicDetails.specialisation.id ?? '',
          },
          level: this.basicDetails.level,
          location: {
            name: this.basicDetails.location.name,
            id: this.basicDetails.location.id,
          },
          // location_arrangement: {
          //   name: this.basicDetails.locationArrangement.name,
          //   id: this.basicDetails.locationArrangement.id,
          // },
          location_arrangement: this.basicDetails.locationArrangement.id,
          years_at_company: this.basicDetails.currentCompanyExp.years * 12 + +this.basicDetails.currentCompanyExp.months,
          total_experience: {
            years: +this.basicDetails.totalExp.years,
            months: +this.basicDetails.totalExp.months || 0,
          },
        });
        if (
          this.currentCompanyName == this.basicDetails.company.name &&
          this.basicDetails.workex_id
        ) {
          basicDetailsData.workex_id = this.basicDetails.workex_id;
        }

        api
          .put(`api/v1/user/basic`, basicDetailsData)
          .then(() => {
            this.basicDetailsLoading = false;
            this.$emit("basicDetailsBtnClick");
            // console.log(response);
          })
          .catch((error) => {
            this.basicDetailsLoading = false;
            console.log(error);
          });
      });
    },
    
    search (inputId, inputValue = "") {
      switch(inputId){
        case "jobTitle":
          this.jobTitleList = this.jobTitleApiData.filter((item) =>
            item.name.match(new RegExp(inputValue, 'gi')) !== null
          );
          break;
        case "specialisation":
          this.specialisationList = this.specialisationApiData.filter((item) =>
            item.name.match(new RegExp(inputValue, 'gi')) !== null
          );
          this.basicDetails.specialisation.id = ''
          break;
        case "jobFamily":
          this.jobFamilysList = this.jobFamilysApiData.filter((item) =>
            item.name.match(new RegExp(inputValue, 'gi')) !== null
          );
          break;
        case "level":
          this.levelFilteredList = this.levelList.filter((item) =>
            item.level.match(new RegExp(inputValue, 'gi')) !== null
          );
          break;
        case "locationArrangement":
          this.locationArrangementFilteredList =
            this.locationArrangementList.filter((item) =>
              item.name.match(new RegExp(inputValue, 'gi')) !== null
            );
          break;
        default: 
          break;
      }
    },
    apiSearch: debounce(async function (inputId, field, inputValue = "") {
      try {
        if(!this[field].length && !inputValue){
          this.updateSearchState(field, false)
        }
        // All search required minimum 3 chars except jobFamily
        if(inputValue.length < 3) {
          if (!inputValue) {
            this.getDataDefault(field);
          }
          this.updateSearchState(field, false)
          return;
        }
        this.updateSearchState(field, true)
        let response;
        switch (inputId) {
          case "company":
            response = await api.get(`api/v1/companies/search?search_term=${this.basicDetails.company.name}`)
            this.companiesList = response.data.data;
            break;
          case "college":
            response = await api.get(`api/v1/colleges/search?search_term=${this.basicDetails.college.name}`)
            this.collegeList = response.data.data;
            break;
          case "jobTitle":
            response = await api.get(`api/v1/designation/search?search_term=${this.basicDetails.jobTitle.name}`)
            this.jobTitleApiData = response.data.data;
            this.jobTitleList = response.data.data;
            break;
          case "location":
            response = await api.get(`api/v1/locations/search?search_term=${this.basicDetails.location.name}`)
            this.locationsList = response.data.data;
            break;
          default:
            break;
        }
      } catch (error) {
        console.error(error)
      } finally{
        this.updateSearchState(field, false)
      }
    }),
    updateSearchState(searchField, isSearching){
      this.searching = {
          ...this.searching,
          [searchField]: isSearching
        };
    },
    getSpecialisation(job_domain_id) {
      api
        .get(`api/v1/job_specialisation/all?job_domain_id=${job_domain_id}`)
        .then((response) => {
          this.specialisationList = response.data.data;
          this.specialisationApiData = response.data.data;
          if(job_domain_id !== this.basicDetails?.jobFamily?.id || this.specialisationList.length === 0){
            this.basicDetails.specialisation = {name: "", id: ""};
          }
        });
    },
    getJobTitle(category) {
      api
        .get(`api/v1/designation/all?category_id=${category}`)
        .then((response) => {
          this.jobTitleList = response.data.data;
          this.jobTitleApiData = response.data.data;
          const linkedInDesignation = this.workEx?.designation_relation?.name ?? this.workEx?.designation
          if(linkedInDesignation !== this.basicDetails.jobTitle?.name){
            this.basicDetails.jobTitle = {name: "", id: ""};
          }
        });
    },
    getLevels(companyId) {
      api.get(`api/v1/job_level/?company=${companyId}`).then((response) => {
        this.levelList = response.data.data.levels || [];
        this.levelFilteredList = [];
        this.basicDetails.level = "";
      });
    },
    prefillLinkedInData(){
      if (this.linkedinDetails) {
        const {work_ex: workExperiences = [], education = []} = this.linkedinDetails;
        this.workEx = workExperiences?.filter((company) => company.is_current);
        
        if (this.workEx?.length) {
          this.workEx = this.workEx.pop();
        } else {
          this.workEx = workExperiences[workExperiences.length - 1];
        }

        const latestEducation = education?.find((education) => education.is_current) ?? education[education.length - 1];

        this.basicDetails.college.name = latestEducation?.college_name;
        this.basicDetails.college.education_id = latestEducation?.id;
        this.basicDetails.college.id = latestEducation?.college?.id;
        this.basicDetails.gender = this.linkedinDetails.gender;

        if (this.type == "edit") {
          const location = this.linkedinDetails?.location ?? this.workEx?.location;
          this.basicDetails.location = {
            name: location?.city,
            id: location?.id,
          };

          this.basicDetails.totalExp = this.linkedinDetails.total_experience;
        }
        if (this.workEx) {
          // Company
          this.currentCompanyName = this.workEx.company_name;
          this.basicDetails.company.name = this.workEx.company_name;
          this.basicDetails.company.id = this.workEx.company?.id;
          this.basicDetails.company.existing_id = this.workEx.company?.id;

          // Job title (Designation)
          this.basicDetails.jobTitle.name = this.workEx.designation_relation?.name ?? this.workEx?.designation;
          if (this.workEx?.designation_relation?.id) {
            this.basicDetails.jobTitle.id = this.workEx.designation_relation.id;
          }
          this.basicDetails.workex_id = this.workEx.id;

          // Job Family (Job Domain)
          if (this.workEx?.job_domain?.name) {
            this.basicDetails.jobFamily = this.workEx.job_domain;
            const jobDomainId = this.workEx.job_domain.id
            this.getSpecialisation(jobDomainId);
            this.getJobTitle(jobDomainId);
          }

          // Level
          if (this.workEx?.level) {
            this.basicDetails.level = this.workEx?.level;
          }

          // Specialisation
          if (this.workEx?.job_specialisation) {
            this.basicDetails.specialisation = this.workEx.job_specialisation;
          }
          // Location
          if (this.workEx?.location) {
            this.basicDetails.location = {name: this.workEx.location?.city, id: this.workEx.location?.id};
          }

          // Location arrangement
          if (this.workEx?.location_arrangement) {
            const {name = "", id = ""} = mockData.LOCATION_ARRANGEMENT.find((loc) => loc.id == this.workEx.location_arrangement) ?? {}
            this.basicDetails.locationArrangement = {name, id};
          }

          // Total experience
          if (this.linkedinDetails) {
            this.basicDetails.totalExp = {
              ...this.basicDetails.totalExp,
              ...this.linkedinDetails.total_experience
            };
          }
          
          // Years at current company
          if (this.workEx?.years_at_company) {
            const {years, months} = getYearsAndMonthsFromMonths(this.workEx?.years_at_company)
            this.basicDetails.currentCompanyExp.years = years;
            this.basicDetails.currentCompanyExp.months = months;
          }

          
        }
        if (this.basicDetails.company && this.basicDetails.company.id && !this.workEx?.level) {
          this.getLevels(this.basicDetails.company.id);
        }
      }
    },
    validateExperiences(){
      const {currentCompanyExp = {}, totalExp = {}} = this.basicDetails
      if(totalExp.years > 50){
        totalExp.years = 50
      }
      if(totalExp.months > 11){
        totalExp.months = 11
      }
      if(currentCompanyExp.years === totalExp.years){
        if(currentCompanyExp.months > totalExp.months){
          currentCompanyExp.months = totalExp.months
        } else if(currentCompanyExp.months > 11) {
          currentCompanyExp.months = 11
        }
      }
      if(currentCompanyExp.years > totalExp.years){
        currentCompanyExp.years = totalExp.years
      }
    }
  },
  watch: {
    linkedinDetails: function (newVal, oldVal) {
      if(newVal !== oldVal){
        this.prefillLinkedInData() 
      }
    }
  },
  mounted() {
    this.prefillLinkedInData()
  },
  created() {
    // Fetch all dropdown's 25 results
    const fields_map = [];
    const fetch_array = Object.keys(APIs_MAP)
      .map((key) => {
        if (APIs_MAP[key].initialFetch === false) {
          return null;
        } else if (APIs_MAP[key].url.startsWith("api/")) {
          fields_map.push(APIs_MAP[key].field);
          return api.get(APIs_MAP[key].url, { params: APIs_MAP[key].params });
        } else {
          fields_map.push(APIs_MAP[key].field);
          return mockData[APIs_MAP[key].url] || constants[APIs_MAP[key].url];
        }
      })
      .filter(Boolean);

    Promise.all(fetch_array)
      .then((values) => {
        values.forEach((value, i) => {
          this[fields_map[i]] = (value.data && value.data.data) || value;
        });
      })
      .catch(console.warn);
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/mixins';
:disabled{
  cursor: not-allowed;
}
.basicDetails {
  padding: 40px 40px 15px 40px;
  .flex{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .form-fields {
    .form-top {
      width: fit-content;
      span {
        color: #333;

        display: block;
        margin-bottom: 16px;
        text-align: left;
      }
      label {
        color: #333;
        font-weight: 500;
        padding-right: 16px;
        padding-left: 6px;
        // cursor: url("../../assets/images/cursors/hover.svg"), auto;
      }

      input {
        width: fit-content;
        cursor: pointer;
      }
      .radio-container {
        display: flex;
        flex-flow: row wrap;
        align-content: flex-start;
      }
      label.ios_size {
        margin-top: 5px;
      }
      input.ios_size[type=radio] { 
        transform: scale(0.5, 0.5); 
        -webkit-transform: scale(0.5, 0.5); 
      }
    }

    .form-bottom {
      margin-top: -25px;
      .input-groups {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        // padding-top: 2px;
        .input-field {
          width: 48%;
          margin-top: 34px;
          position: relative;
          border: 1px solid rgba(0, 0, 0, 0.18);
          border-radius: 10px;
          > span {
            position: inherit;
            display: flex;
            align-items: center;
          }
          @include respond(md) {
            width: 100%;
          }

          &:after {
            content: "";
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='11' viewBox='0 0 15 11' fill='none'%3E%3Cpath d='M13.7826 1.26978C13.3879 0.851943 12.7232 0.851943 12.3285 1.26978L8.22701 5.61257C7.83239 6.03041 7.16761 6.03041 6.77299 5.61257L2.67146 1.26978C2.27684 0.851943 1.61205 0.851943 1.21743 1.26978L0.648478 1.8722C0.284495 2.25759 0.284495 2.86005 0.648478 3.24545L6.77299 9.73022C7.16761 10.1481 7.83239 10.1481 8.22701 9.73022L14.3515 3.24545C14.7155 2.86005 14.7155 2.25759 14.3515 1.8722L13.7826 1.26978Z' fill='%23C7C7C7'/%3E%3C/svg%3E");
            display: block;
            width: 15px;
            position: absolute;
            height: 10px;
            top: 21px;
            right: 16px;
            transition: all 0.15s linear;
          }
          &.hide-drop-arrow:after {
            display: none;
          }

          &.dropdown {
            & > :first-child {
              width: 100%;
            }
          }

          &.active {
            .placeholder {
              transform: translate(14px, -100%);
              z-index: 10;
              transition: all 0.15s linear;
              background: white;
            }

            ul.search-dropdown {
              display: block;
            }
          }

          input {
            position: relative;
            z-index: 9;
            background: none !important;
          }

          input{
            & ~ .placeholder {
              position: absolute;
              transform: translate(14px, 0px);
              color: #bbbbbb;
              font-size: 15px;
              padding: 0 8px;
              z-index: 0;
              transition: all 0.15s linear;
            }
            &:focus ~ .placeholder{
              transform: translate(14px, -100%);
              z-index: 10;
              transition: all 0.15s linear;
              background: white;
            }
          }

          // input:not(:focus) ~ ul.search-dropdown {
          //   visibility: none;
          // }

          ul.search-dropdown {
            animation: scaleHeight .15s linear;
            width: calc(100% + 2px);
            left: -1px;
            position: absolute;
            top: 43px;
            border: 1px solid rgba(0, 0, 0, 0.18);
            background: white;
            border-radius: 0 0 10px 10px;
            z-index: 99;
            max-height: 200px;
            overflow: scroll;
            box-shadow: 0px 8px 15px -5px rgba(0, 0, 0, .2);
            li {
              text-align: left;
              padding: 10px 16px;
              cursor: pointer;
              &.company-list{
                img{
                  vertical-align: middle;
                  margin-right: 10px;
                }
              }
              &:hover {
                background: #f0f2e3;
              }
            }
          }

          @keyframes scaleHeight {
            from{
              max-height: 0px;
            }
            to{
              max-height: 200px;
            }
          }

          &.hide-dropdown {
            &:after {
              display: none;
            }
          }

          .years-field {
            width: 55px;
            padding: 18px 8px 14px 18px !important;
            border-radius: 10px 0 0 10px !important;
            border-right: 0;
            &:focus {
              border-right: 0 !important;
            }

            @include respond(md) {
              padding-right: 50px !important;
            }
            @include respond(sm) {
              padding-right: 18px !important;
            }
          }

          span.separator {
            font-size: 24px;
            margin: 0px 20px;
            color: #bbbbbb;
          }

          .months-field {
            width: 55px;
            border-radius: 0 10px 10px 0 !important;
            padding: 18px 8px 14px 18px !important;
            border-left: 0;
            &:focus {
              border-left: 0 !important;
            }
            @include respond(sm) {
              padding: 18px 28% 14px 30% !important;
            }
          }

          // #jobFamily,
          &:not(.hide-drop-arrow){
            #specialisation,
            #jobTitle {
              // caret-color: transparent;
            }
          }
        }
        .hide-input-cursor {
          caret-color: transparent;
        }
      }
      :deep(.my_button_container button) {
        @include respond(sm) {
          padding: 15px 15px;
        }
      }
    }
  }

  .terms {
    padding-top: 22px;
    line-height: 22px;
    a {
      color: #9faa22;
      text-decoration: none;
      cursor: pointer!important;;
    }
  }
}

input {
  width: 100%;
  padding: 18px 38px 14px 18px;
  font-weight: 400;
  font-size: 16px;
  color: black;
  outline: none;
  border-radius: 10px;
  box-shadow: none;
  border: none;

  &::placeholder {
    color: #adadad;
  }

  &.error {
    border-color: red;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.inputError {
  position: absolute;
  left: 0;
  top: 55px;
  font-size: 12px;
  color: red;
}

::v-deep {
  .my_button_wrapper {
    button {
      margin-top: 16px;
      padding: 14px 40px;
    }
  }

  .form-bottom {
    button {
      width: 100%;
      margin-top: 32px;
    }
  }
}

@media (max-width: 768px) {
  .basicDetails {
    padding-bottom: 20px;
  }
  // .form-fields .form-bottom .input-groups .input-field {
  //   width: 100% !important;
  // }
}
</style>
