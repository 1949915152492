<template>
  <div class="top_header_section" @keydown="showBannerVideo = false">
    <div class="content_wrapper">
      <Header />

      <div class="main_title">
        <p class="--part1">Break the</p>
        <p class="--part2">Salary Sharing Taboo</p>
        <p class="--part3">Uncover Real Salaries, Real People</p>
      </div>
      <div class="realtime-data">
        <div
          class="info"
          :class="{ 'show-animation': realtime_data_set.length > 1 }"
          v-html="realtime_html_data"
        />
      </div>
      <template v-if="!this.$store.state.auth">
        <ValidationObserver ref="observer" v-slot="{ invalid }">
          <div ref="earlyAccessEmail" class="email_field_wrapper">
            <ValidationProvider rules="required|email" v-slot="{ errors }">
              <MyInput
                v-on:enterKey="showModal"
                v-model="uEmail"
                placeholder="Give us your Email Address"
              />
              <div class="my_button_wrapper">
                <MyButton
                  :type="'accent'"
                  :disabled="!showEarlyAccessButton || invalid"
                  v-on:click="showModal"
                  :loading="showEmailVerificationLoading"
                >
                  Get Early Access
                </MyButton>
              </div>

              <span v-if="showEmailInputError" class="main-input inputError">{{
                errors[0] || "Please enter a valid email"
              }}</span>
            </ValidationProvider>
          </div>
        </ValidationObserver>

        <div class="waitlist_link">
          Already in waitlist?
          <a class="login_link" href="javascript:void(0)" @click="openLoginFlow"
            >Click here</a
          >
        </div>
      </template>

      <template v-else>
        <div class="waitlist_link">
          <span
            >You're already in the waitlist, view your
            <router-link :to="{ name: 'Waitlist' }">
              Current Position
            </router-link>
          </span>
        </div>
      </template>
      <div class="image_section">
        <img src="@/assets/images/home/top-left.png" class="top-left" />
        <div class="floating-money">
          <Particles
            id="tsparticles"
            :options="{
              fullScreen: {
                enable: false,
                zIndex: -1,
              },
              particles: {
                number: {
                  value: 10,
                  density: {
                    enable: true,
                    value_area: 200,
                  },
                },
                color: { value: '#ffffff' },
                shape: {
                  type: 'image',
                  stroke: { width: 0, color: '#000000' },
                  polygon: { nb_sides: 5 },
                  image: {
                    src: require('../../assets/images/dollar.png'),
                    width: 60,
                    height: 60,
                  },
                },
                opacity: {
                  value: 0.6,
                  random: false,
                  anim: {
                    enable: true,
                    speed: 1,
                    opacity_min: 0.09744372624809179,
                    sync: false,
                  },
                },
                size: {
                  value: 50,
                  random: false,
                  anim: {
                    enable: false,
                    speed: 280,
                    size_min: 0.1,
                    sync: false,
                  },
                },
                line_linked: {
                  enable: true,
                  distance: 100,
                  color: '#cbd83f',
                  opacity: 0.01,
                  width: 2,
                },
                move: {
                  enable: true,
                  speed: 6,
                  direction: 'left',
                  random: true,
                  straight: true,
                  out_mode: 'out',
                  bounce: true,
                  attract: {
                    enable: false,
                    rotateX: 801.7060304327614,
                    rotateY: 641.3648243462092,
                  },
                },
              },
              interactivity: {
                detect_on: 'canvas',
                events: {
                  onhover: { enable: true, mode: 'grab' },
                  onclick: { enable: true, mode: 'push' },
                  resize: true,
                },
                modes: {
                  grab: { distance: 400, line_linked: { opacity: 1 } },
                  bubble: {
                    distance: 400,
                    size: 40,
                    duration: 2,
                    opacity: 8,
                    speed: 3,
                  },
                  repulse: { distance: 200, duration: 0.2 },
                  push: { particles_nb: 1 },
                  remove: { particles_nb: 2 },
                },
              },
              retina_detect: true,
            }"
          >
            <canvas data-generated="true" width="100vw" height="100vh"></canvas>
          </Particles>
          <!-- 
          <div class="floating-money-wrapper">
            <div class="floating-money-bg" />
          </div> -->
        </div>
        <img src="@/assets/images/home/top-right.png" class="top-right" />
      </div>
      <div class="hero_banner">
        <div class="banner_container">
          <div class="banner_bg"></div>
          <div class="banner_content">
            <span>Why Hiding salaries is bad for you?</span>
            <p>
              Companies collude and don't want you to know everyone’s salary so
              that they can rip you off. This is how information asymmetry and
              capitalism works.
            </p>
          </div>
          <div class="banner_cta">
            <img
              class="thumbnail"
              src="@/assets/images/home/video-thumbnail.png"
            />
            <PlayButtonSvg @click="showVideo" class="cta_btn" />
            <!-- <img
              
             
              src="@/assets/images/home/play-btn.svg"
            /> -->
          </div>
          <div :class="['clip', showBannerVideo ? 'active' : 'na']">
            <div class="close" @click="closeVideo">
              <img src="/close.png" />
            </div>
            <iframe
              ref="bannerIframe"
              width="960"
              height="480"
              src=""
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
    <Modal
      :show="modal.show"
      :showLeftPart="true"
      :customClass="'my_modal_wrapper'"
      :title="getModalTitle"
      v-on:closeModal="this.closeModal"
    >
      <template v-slot:image>
        <template v-if="modal.showSignInScreen">
          <div v-if="selfWorthFlow" class="self-worth-left">
            <div class="progress-container --login">
              <div class="progress-item">
                <div class="progress-item-container">
                  <div class="progress-image">
                    <img
                      class=""
                      src="@/assets/images/home/key-icon.svg"
                      alt=""
                      srcset=""
                    />
                  </div>
                  <div class="progress-title">Login</div>
                </div>
              </div>
              <div class="progress-item">
                <div class="progress-item-container">
                  <div class="progress-image">
                    <img
                      class=""
                      src="@/assets/images/home/boring-icon.svg"
                      alt=""
                      srcset=""
                    />
                  </div>
                  <div class="progress-title">Boring</div>
                </div>
              </div>
              <div class="progress-item">
                <div class="progress-item-container">
                  <div class="progress-image">
                    <img
                      class=""
                      src="@/assets/images/home/exciting-icon.svg"
                      alt=""
                      srcset=""
                    />
                  </div>
                  <div class="progress-title">Exciting</div>
                </div>
              </div>
            </div>
            <div class="bottom-image">
              <img src="@/assets/images/home/self-worth-login.png" />
            </div>
          </div>
          <div v-else class="left_part_two_images">
            <img
              class="top_image"
              src="@/assets/images/home/namaste.svg"
              alt=""
              srcset=""
            />

            <img
              class="bottom_image"
              src="@/assets/images/email-confirmation/get-started.png"
              alt=""
              srcset=""
            />
          </div>
        </template>
        <template v-else-if="modal.showExistingUser">
          <div class="left_part_two_images">
            <img
              class="top_image"
              src="@/assets/images/home/namaste.svg"
              alt=""
              srcset=""
            />

            <img
              class="bottom_image"
              src="@/assets/images/email-confirmation/get-started.png"
              alt=""
              srcset=""
            />
          </div>
        </template>
        <template v-else-if="modal.showEnterOtpScreen">
          <div v-if="selfWorthFlow" class="self-worth-left">
            <div class="progress-container --login">
              <div class="progress-item">
                <div class="progress-item-container">
                  <div class="progress-image">
                    <img
                      class=""
                      src="@/assets/images/home/key-icon.svg"
                      alt=""
                      srcset=""
                    />
                  </div>
                  <div class="progress-title">Login</div>
                </div>
              </div>
              <div class="progress-item">
                <div class="progress-item-container">
                  <div class="progress-image">
                    <img
                      class=""
                      src="@/assets/images/home/boring-icon.svg"
                      alt=""
                      srcset=""
                    />
                  </div>
                  <div class="progress-title">Boring</div>
                </div>
              </div>
              <div class="progress-item">
                <div class="progress-item-container">
                  <div class="progress-image">
                    <img
                      class=""
                      src="@/assets/images/home/exciting-icon.svg"
                      alt=""
                      srcset=""
                    />
                  </div>
                  <div class="progress-title">Exciting</div>
                </div>
              </div>
            </div>
            <div class="bottom-image">
              <img src="@/assets/images/home/self-worth-otp.png" />
            </div>
          </div>
          <div v-else class="left_part_two_images">
            <img
              class="top_image"
              src="@/assets/images/email-confirmation/enter-otp-message.svg"
              alt=""
              srcset=""
            />

            <img
              class="bottom_image"
              src="@/assets/images/email-confirmation/enter-otp.png"
              alt=""
              srcset=""
            />
          </div>
        </template>
        <template v-else>
          <div class="left_part_two_images waitList">
            <img
              class="top_image"
              src="@/assets/images/email-confirmation/adding-to-waitlist-msg.svg"
              alt=""
              srcset=""
            />

            <div class="helpText">
              <span>Facing issues?</span>
              <a href="#">Mail us at help@uncovor.com</a>
            </div>
          </div>
        </template>
      </template>

      <div class="mymodal_content_wrapper">
        <div v-if="modal.showSignInScreen" class="verify_phone_number_wrapper">
          <div :class="`input-field ${uName.length > 0 ? 'active' : ''}`">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <input
                type="text"
                id="userName"
                name="userName"
                :class="`${errors.length > 0 ? 'error' : ''}`"
                v-model="uName"
                placeholder=" "
                autocomplete="off"
              />
              <span class="inputError">{{ errors[0] }}</span>
              <span class="placeholder">Your Name *</span>
            </ValidationProvider>
          </div>
          <div :class="`input-field ${uEmail.length > 0 ? 'active' : ''}`">
            <ValidationProvider rules="required|email" v-slot="{ errors }">
              <input
                type="text"
                id="uEmail"
                name="uEmail"
                :class="`${errors.length > 0 ? 'error' : ''}`"
                v-model="uEmail"
                placeholder=" "
                autocomplete="off"
              />
              <span class="inputError">{{ errors[0] }}</span>
              <span class="placeholder">Your Email Address *</span>
            </ValidationProvider>
          </div>
          <div :class="`input-field ${uPhone.length > 0 ? 'active' : ''}`">
            <ValidationProvider rules="required|max_phone_len:10" v-slot="{ errors }">
              <div :class="`my_input_wrapper phone`">
                <input
                  type="number"
                  id="uPhone"
                  name="uPhone"
                  :class="`${errors.length > 0 ? 'error' : ''}`"
                  v-model="uPhone"
                  placeholder=" "
                  autocomplete="off"
                />
                <span class="placeholder">Your Phone Number *</span>
              </div>
              <span class="inputError">{{
                errors[0] || fieldErrors.phoneNumber
              }}</span>
            </ValidationProvider>
          </div>

          <div
            :class="`input-field ${uReferralCode.length > 0 ? 'active' : ''}`"
          >
            <ValidationProvider rules="required" v-slot="{ errors }">
              <input
                type="text"
                id="referralCode"
                name="uReferralCode"
                :class="`${errors.length > 0 ? 'error' : ''}`"
                v-model="uReferralCode"
                placeholder=" "
                autocomplete="off"
                v-on:keyup.enter="sendTheOtp"
              />
              <span class="inputError">{{
                errors[0] || fieldErrors.referralCode
              }}</span>
              <span class="placeholder">Referral Code *</span>
            </ValidationProvider>
          </div>

          <div class="my_button_wrapper">
            <div id="recaptchaVerifier"></div>
            <MyButton
              class="sendTheOtp"
              :type="'primary'"
              :disabled="!showVerifyButton"
              v-on:click="updateProspectUserAndSendOtp"
              :loading="showVerifyPhoneLoading"
            >
              Next, verify phone number
            </MyButton>
          </div>

          <p class="terms_conditions">
            By proceeding, you agree to our
            <router-link to="/terms-conditions"
              >terms and conditions</router-link
            >
          </p>
        </div>
        <div
          v-else-if="modal.showExistingUser"
          class="verify_phone_number_wrapper"
        >
          <div :class="`input-field ${uPhone.length > 0 ? 'active' : ''}`">
            <div :class="`my_input_wrapper phone disabled`">
              <input
                v-if="uMaskedPhone"
                type="text"
                id="uPhone"
                name="uPhone"
                v-model="uMaskedPhone"
                placeholder=" "
                disabled
                v-on:keyup.enter="sendTheOtp"
              />
              <input
                v-else
                type="text"
                id="uPhone"
                name="uPhone"
                v-model="uPhone"
                placeholder=" "
                :disabled="!isLoginFlow"
                v-on:keyup.enter="sendTheOtp"
              />
              <span class="inputError">{{ otpError }}</span>
              <span class="placeholder">Enter your phone number</span>
            </div>
          </div>

          <div class="my_button_wrapper">
            <div id="recaptchaVerifierExistingEmail"></div>
            <MyButton
              type="primary"
              class="sendTheOtp"
              v-on:click="sendTheOtp"
              ref="loginGetOtpButton"
              :loading="showVerifyPhoneLoading"
              :disabled="uPhone.length != 10"
            >
              Get Otp
            </MyButton>
          </div>

          <p class="terms_conditions">
            By proceeding, you agree to our
            <router-link to="/terms-conditions"
              >terms and conditions</router-link
            >
          </p>
        </div>
        <div v-else-if="modal.showEnterOtpScreen" class="enter_otp">
          <div :class="`input-field ${uOTP.length > 0 ? 'active' : ''}`">
            <input
              type="number"
              id="otpInput"
              name="otpInput"
              v-model="uOTP"
              :class="`${otpError.length > 0 ? 'error' : ''}`"
              placeholder=" "
              autocomplete="off"
              v-on:keyup.enter="verifyUserOTP"
            />
            <span class="inputError">{{ otpError }}</span>
            <span class="placeholder">Enter your 6 digit OTP *</span>
          </div>

          <div class="my_button_wrapper">
            <MyButton
              type="primary"
              :disabled="!showOtpButton"
              v-on:click="verifyUserOTP"
              :loading="showOtpButtonLoading"
            >
              Hop on to the waitlist
            </MyButton>
          </div>

          <div v-if="!resendOtp.showOtpButtonLoading" class="resend_otp">
            <a
              v-if="resendOtp.showResendOtpButton"
              @click="resendTheOtp"
              href="javascript:void(0)"
              >Resend OTP</a
            >
            <p v-else>
              Resend OTP in
              <span class="counter">{{ resendOtp.currentCounter }}</span> sec
            </p>
          </div>
        </div>
        <div v-else class="addingToWaitlist">
          <img
            src="@/assets/images/email-confirmation/adding-to-waitlist-bg.png"
          />
        </div>
      </div>
    </Modal>
    <!-- <Modal
      :show="true"
      :showLeftPart="false"
      :customClass="'my_modal_wrapper videoModal ' +showBannerVideo ? 'active' : 'na'"
      v-on:closeModal="this.closeVideo"
    >
      <div class="mymodal_content_wrapper videoModal">
        <iframe
          src="https://www.youtube.com/embed/7xH7eGFuSYI"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </Modal> -->
    <div v-if="selfWorthCTA" class="selfWorth--CTA" @click="selfWorthModal">
      <div class="coin">
        <lottie
          :options="animationOptions"
          v-on:animCreated="handleAnimation"
        />
      </div>
      <div class="CTA__content">
        <span>Know your self worth!</span>
        <p>You could earn upto 50% more if you join the right place.</p>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../../components/Header.vue";
import Modal from "../../components/modal.vue";
import MyInput from "../../components/input.vue";
import MyButton from "../../components/button.vue";
import router from "../../router";
import { api } from "../../services/http-common.js";
import { ValidationProvider, extend, ValidationObserver } from "vee-validate";
import { required, email, max } from "vee-validate/dist/rules";
import * as crypto from "crypto";
import animationData from "@/assets/lotties/gold-coin.json";
import PlayButtonSvg from "../../assets/images/home/play-btn.svg?inline";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";

extend("email", {
  ...email,
  message: "Please enter a valid email",
});
extend("required", {
  ...required,
  message: "This field is required",
});
extend("max_phone_len", {
  ...max,
  message: "Please enter a valid 10 digit phone number",
});

export default {
  data() {
    return {
      showEmailInputError: false,
      showVideoModal: false,
      isLoginFlow: false,
      isValidPhoneNumber: true,
      modal: {
        show: false,
        title: "Let’s get started!",
        showSignInScreen: true,
        showEnterOtpScreen: false,
        showExistingUser: false,
      },
      fieldErrors: {
        phoneNumber: "",
        referralCode: "",
      },
      realtime_data_set: [],
      realtime_insights_interval: null,
      realtime_current_data_index: 0,
      realtime_html_data: "",
      animationOptions: { animationData: animationData },
      animationSpeed: 1,
      showBannerVideo: false,
      uName: "",
      uPhone: "",
      uEmail: "",
      uReferralCode: "",
      uMaskedPhone: "",
      cookieReferral: "",
      uOTP: " ",
      otpError: "",
      showEmailVerificationLoading: false,
      showVerifyPhoneLoading: false,
      showOtpButtonLoading: false,
      resendOtp: {
        initialCounter: 30,
        currentCounter: 30,
        resendOtpTimer: "",
        showResendOtpButton: false,
      },
      selfWorthCTA: true,
      selfWorthFlow: false,
      existingUser: false,
    };
  },
  components: {
    MyInput,
    Header,
    Modal,
    ValidationProvider,
    ValidationObserver,
    MyButton,
    PlayButtonSvg,
  },
  computed: {
    getModalTitle() {
      if (this.modal.showSignInScreen) {
        return "Let’s get started!";
      } else if (this.modal.showEnterOtpScreen) {
        return "Enter OTP";
      } else if (this.modal.showExistingUser) {
        return "Welcome back!";
      } else {
        return "Adding you to the waitlist...";
      }
    },
    showEarlyAccessButton() {
      if (this.uEmail === "") {
        return true;
      } else {
        return this.isValidEmail(this.uEmail);
      }
    },
    showVerifyButton() {
      let isEmailValid = false;
      let isPhoneValid = false;

      if (this.uEmail) {
        isEmailValid = this.isValidEmail(this.uEmail);
      }
      if (this.uPhone && this.uPhone.length === 10) {
        isPhoneValid = this.isValidNumber(this.uPhone);
      }
      return isEmailValid && isPhoneValid && this.uReferralCode;
    },
    showOtpButton() {
      if (this.uOTP && this.uOTP.toString().length === 6) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    openLoginFlow() {
      this.enableRecaptcha("recaptchaVerifierExistingEmail");
      this.modal.show = true;
      this.modal.showSignInScreen = false;
      this.modal.showExistingUser = true;
      this.isLoginFlow = true;
      this.existingUser = true;
      this.isValidPhoneNumber = false;
    },
    enableRecaptcha(containerOrId) {
      const auth = getAuth();
      setTimeout(() => {
        window.recaptchaVerifier = new RecaptchaVerifier(
          containerOrId,
          {
            size: "invisible",
            callback: () => {
              this.showEmailVerificationLoading = false;
            },
          },
          auth
        );
      }, 0);
    },
    showModal() {
      if (this.uEmail && this.isValidEmail(this.uEmail)) {
        this.showEmailInputError = false;
        this.showEmailVerificationLoading = true;
        api
          .get(`api/v1/user/email?email=${this.uEmail}`, {
            headers: {
              accept: "application/octet-stream",
            },
            responseType: "arraybuffer",
          })
          .then((response) => {
            this.enableRecaptcha("recaptchaVerifierExistingEmail");

            const encryptedData = response.data;

            const privateKey = process.env.VUE_APP_RSA_KEY;

            const decryptedData = crypto.privateDecrypt(
              {
                key: privateKey,
                padding: crypto.constants.RSA_PKCS1_PADDING,
              },
              Buffer.from(encryptedData, "utf-8")
            );

            var existingUserPhone = JSON.parse(
              new TextDecoder().decode(decryptedData)
            ).phone_number;

            this.uPhone = existingUserPhone.replace("+91", "");
            this.uMaskedPhone = "xxxxxx" + this.uPhone.substring(6);
            this.existingUser = true;
            document.querySelector("html").classList.add("noScroll");
            this.modal.show = true;
            this.modal.showSignInScreen = false;
            this.modal.showExistingUser = true;
          })
          .catch((e) => {
            if (e.response.status && e.response.status == 400) {
              this.existingUser = false;
              this.modal.show = true;
              this.modal.showSignInScreen = true;
              this.modal.showExistingUser = false;
              document.querySelector("html").classList.add("noScroll");
              this.enableRecaptcha("recaptchaVerifier");
            }
          });
      } else {
        this.showEmailInputError = true;
      }
    },
    selfWorthModal() {
      if (this.$store.state.auth) {
        router.push({ path: "/know-your-self-worth" });
      } else {
        // const auth = getAuth();
        // this.modal.show = true;
        // this.selfWorthCTA = false;
        this.selfWorthFlow = true;
        window.scroll({
          top: 0,
          left: 0,
        });
        this.$refs.earlyAccessEmail.classList.remove("highlight");
        this.$refs.earlyAccessEmail.classList.add("highlight");

        setTimeout(() => {
          this.$refs.earlyAccessEmail.classList.remove("highlight");
          this.$refs.earlyAccessEmail.querySelector("input").focus();
        }, 600);
        // document.querySelector("body").classList.add("noScroll");
        // setTimeout(() => {
        //   window.recaptchaVerifier = new RecaptchaVerifier(
        //     "recaptchaVerifier",
        //     {
        //       size: "invisible",
        //       callback: () => {
        //         this.modal.showSignInScreen = false;
        //         this.modal.showEnterOtpScreen = true;
        //         //TODO: call api to send the otp
        //         this.toggleOtpCounter("start");
        //         // reCAPTCHA solved, allow signInWithPhoneNumber.
        //       },
        //     },
        //     auth
        //   );
        // }, 0);
      }
    },
    closeModal() {
      this.modal.show = false;
      this.isLoginFlow = false;
      this.selfWorthCTA = true;
      this.isValidPhoneNumber = true;
      this.uPhone = "";
      this.modal.title = "Let’s get started!";
      this.modal.showSignInScreen = false;
      this.modal.showEnterOtpScreen = false;
      this.modal.showExistingUser = false;
      this.showEmailVerificationLoading = false;
      this.showVerifyPhoneLoading = false;
      if (this.selfWorthFlow) this.selfWorthFlow = false;
      document.querySelector("html").classList.remove("noScroll");
    },
    closeVideo() {
      this.showBannerVideo = false;
      this.selfWorthCTA = true;
      this.$refs.bannerIframe.src = "";
      document.querySelector("html").classList.remove("noScroll");
    },
    verifyUserOTP() {
      if (!this.showOtpButton) {
        return;
      }

      this.showOtpButtonLoading = true;
      window.confirmationResult
        .confirm(this.uOTP)
        .then((result) => {
          let userDetails;

          let self = this;

          let getUserProfile = function () {
            api
              .get(`api/v1/user/profile`)
              .then((response) => {
                self.$store.commit("setUser", response.data);
                let date = new Date(Date.now() + 86400e3);
                date = date.toUTCString();
                document.cookie = `@uncovor::login=true; expires=${date} path=/`;
                self.$store.commit("setAuth", true);
                self.toggleOtpCounter("discard");
                self.showOtpButtonLoading = false;
                document.querySelector("html").classList.remove("noScroll");
                if (self.selfWorthFlow) {
                  router.push({ path: "/know-your-self-worth" });
                  return;
                }
                self.modal.show = false;
                self.modal.showSignInScreen = false;
                self.modal.showEnterOtpScreen = false;
                document.querySelector("html").classList.remove("noScroll");
                router.push({ path: "/waitlist" });
              })
              .catch((e) => {
                console.log(e);
              });
          };

          if (!this.existingUser) {
            if (this.cookieReferral || this.uReferralCode !== "") {
              userDetails = {
                email: this.uEmail,
                phone: result._tokenResponse.phoneNumber,
                name: this.uName,
                image: "#",
                referral_code: this.uReferralCode
                  ? this.uReferralCode
                  : this.cookieReferral,
              };
            } else {
              userDetails = {
                email: this.uEmail,
                phone: result._tokenResponse.phoneNumber,
                name: this.uName,
                image: "#",
              };
            }

            api
              .post(`api/v1/user/?call_type=create`, userDetails, {
                headers: {
                  "firebase-token": result._tokenResponse.idToken,
                },
              })
              .then(() => {
                // console.log(result._tokenResponse.idToken);
                getUserProfile();
              })
              .catch((error) => {
                this.showOtpButtonLoading = false;
                // const errormessage = error?.response?.data?.detail;
                // if (errormessage.indexOf("user already exists") !== -1) {
                //   this.modal.showSignInScreen = true;
                //   this.modal.showExistingUser = false;
                //   this.fieldErrors.phoneNumber = "Phone number already exists";
                // }
                console.log(error);
              });
          } else {
            userDetails = {
              phone: result._tokenResponse.phoneNumber,
            };

            api
              .post(`api/v1/user/?call_type=login`, userDetails, {
                headers: {
                  "firebase-token": result._tokenResponse.idToken,
                },
              })
              .then(() => {
                getUserProfile();
              })
              .catch((error) => {
                console.log(error);
              });

            // getUserProfile();
          }

          // ...
        })

        .catch((error) => {
          if (error.code == "auth/invalid-verification-code") {
            this.otpError = "Please enter a valid OTP";
          } else {
            this.otpError = "An error occured, please try again later";
          }
          this.showOtpButtonLoading = false;
        });
    },
    updateProspectUserAndSendOtp() {
      this.showVerifyPhoneLoading = true;
      this.fieldErrors.phoneNumber = "";
      this.fieldErrors.referralCode = "";
      const payload = {
        email: this.uEmail,
        phone: "+91" + this.uPhone,
        name: this.uName,
        referral_code: this.uReferralCode,
        image: "#",
      };

      api
        .post(`api/v1/user/prospect`, payload)
        .then((response) => {
          if (
            response.data &&
            response.data.data &&
            response.data.data.referral_code_valid === false
          ) {
            this.showVerifyPhoneLoading = false;
            this.fieldErrors.referralCode =
              "Please enter a valid referral code";
            return;
          }
          if (response.data.status_code === 400) {
            this.fieldErrors.phoneNumber = "Phone number already exists";
            this.showVerifyPhoneLoading = false;
            return;
          }
          this.sendTheOtp();
        })
        .catch(() => {
          this.showVerifyPhoneLoading = false;
          this.fieldErrors.phoneNumber = "Phone number already exists";
        });
    },
    sendTheOtp() {
      if (this.uPhone.length !== 10) {
        return;
      }

      this.toggleOtpCounter("start");
      this.showVerifyPhoneLoading = true;
      const auth = getAuth();
      this.otpError = "";
      let self = this;
      signInWithPhoneNumber(auth, `+91${this.uPhone}`, window.recaptchaVerifier)
        .then((confirmationResult) => {
          self.showVerifyPhoneLoading = false;
          self.modal.showSignInScreen = false;

          self.modal.showExistingUser = false;
          self.modal.showEnterOtpScreen = true;
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          //TODO: Below line can be a state no need of window
          window.confirmationResult = confirmationResult;
          // ...
        })
        .catch((error) => {
          console.log(error, "sign in with phone");
          // Error; SMS not sent
          // ...
        });
    },
    toggleOtpCounter(task = "discard") {
      if (task === "start") {
        clearInterval(this.resendOtpCounter);
        this.uOTP = "";
        this.resendOtp.showResendOtpButton = false;
        this.resendOtp.currentCounter = this.resendOtp.initialCounter;
        const self = this;
        this.resendOtpCounter = setInterval(() => {
          // console.log("timer");
          if (self.resendOtp.currentCounter === 0) {
            self.resendOtp.showResendOtpButton = true;
            clearInterval(self.resendOtpCounter);
          } else {
            self.resendOtp.currentCounter = self.resendOtp.currentCounter - 1;
          }
        }, 1000);
      } else {
        clearInterval(this.resendOtpCounter);
        this.resendOtp.showResendOtpButton = true;
        this.resendOtp.currentCounter = this.resendOtp.initialCounter;
      }
    },
    resendTheOtp() {
      this.sendTheOtp();
    },
    isValidEmail(email) {
      const regex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return regex.test(String(email).toLowerCase());
    },
    isValidNumber(number) {
      const regex = /^\d+$/;
      return regex.test(String(number));
    },
    showVideo() {
      this.showBannerVideo = true;
      this.$refs.bannerIframe.src =
        "https://www.youtube.com/embed/7xH7eGFuSYI?autoplay=1";
      document.querySelector("html").classList.add("noScroll");
    },
    handleAnimation: function (anim) {
      this.anim = anim;
    },
    stop: function () {
      this.anim.stop();
    },
    play: function () {
      this.anim.play();
    },
    pause: function () {
      this.anim.pause();
    },
    onSpeedChange: function () {
      this.anim.setSpeed(this.animationSpeed);
    },
  },
  mounted() {
    this.cookieReferral = this.$store.getters.getReferral;
    this.uReferralCode = this.cookieReferral;

    let pageHeight = document.querySelector("body").clientHeight;
    window.onscroll = () => {
      if (scrollY > pageHeight * 0.8) {
        document.querySelector("body").classList.add("hideCTA");
      } else {
        document.querySelector("body").classList.remove("hideCTA");
      }
    };
  },
  beforeUnmount() {
    if (this.realtime_insights_interval) {
      clearInterval(this.realtime_insights_interval);
    }
  },
};
</script>

<style lang="scss" scoped>
.hideCTA {
  .selfWorth--CTA {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.25s linear;
  }
}

.top_header_section {
  background: var(--bg);

  .my_button_wrapper {
    .my_button_container {
      width: 100%;
    }
  }

  .content_wrapper {
    max-width: 100%;
    background: url("../../assets/images/home/home-bg-contour.png");
    .realtime-data {
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      margin-top: 8px;
      .info {
        color: #ffffff;
        height: 24px;
        opacity: 0.6;
        &.show-animation {
          animation: fadeInOut 3s ease-in-out infinite;
          animation-delay: 1s;
        }
      }
      strong {
        color: #ffffff;
      }
    }
    @keyframes fadeInOut {
      0% {
        opacity: 0;
        transform: translateY(100%);
      }
      25%,
      50%,
      75% {
        opacity: 0.6;
        transform: translateY(0%);
      }
      100% {
        opacity: 0;
        transform: translateY(-100%);
      }
    }
  }
  .main_title {
    color: var(--bg-text);
    font-weight: 800;
    font-size: 66px;
    margin: 0 auto;
    z-index: 9999;

    .--part1 {
      font-family: BrotherLine !important;
      color: white;
      font-size: 94px;
      text-align: center;
      display: block;
      font-weight: 400;
      line-height: 50px;
    }
    .--part2 {
      font-size: 66px;
      font-weight: 700;
      margin: 24px 0 30px 0;
    }
    .--part3 {
      color: white;
      font-size: 32px;
      font-weight: 400;
      margin-bottom: 14px;
    }
  }
  .email_field_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    position: relative;
    .my_input_wrapper {
      width: 320px;
      z-index: 1;
    }
    .my_button_wrapper {
      position: relative;
      left: -20px;
      z-index: 1;
    }

    > span {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 36px 0 28px 18px;
      z-index: 1;
      position: relative;
      border: 3px solid transparent;
      .inputError {
        position: absolute;
        top: 38px;
        background: #f65e5e;
        font-size: 16px;
        color: white;
        width: 100%;
        left: 0px;
        text-align: left;
        padding: 26px 16px 12px 16px;
        border-radius: 8px;
      }
    }

    &.highlight {
      animation: levitate 0.4s linear;

      > span {
        border: 3px solid #fe8300;
        border-radius: 13px;
      }

      @keyframes levitate {
        0% {
          top: 0px;
        }

        50% {
          top: -20px;
        }

        100% {
          top: 0px;
        }
      }
    }
  }
  .image_section {
    bottom: 0;
    background: linear-gradient(180deg, transparent, #181815);
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    position: relative;
    min-height: 400px;
    margin: -45px auto 0 auto;
    .top-left {
      position: relative;
      max-height: 500px;
      width: 450px;
      z-index: 2;
    }
    .floating-money {
      width: 55%;
      position: relative;
      z-index: 1;
      height: 326px;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;

      div#tsparticles {
        position: absolute;
        width: calc(100% + 160px);
        margin-left: -100px;
        height: 100%;
        overflow: hidden;
        display: block;

        &:before {
          content: "";
          display: block;
          position: absolute;
          width: 100%;
          height: 70px;
          z-index: 99999;
          top: 0px;
          background: linear-gradient(0, #18181500, #1a1a18);
        }
      }

      .floating-money-wrapper {
        position: absolute;
        width: 150%;
        margin-left: -30%;
        height: 100%;
        margin-top: 10%;
        overflow: hidden;
      }

      .floating-money-bg {
        height: 100%;
        max-height: 300px;
        position: relative;
        width: 24000px;
        background-size: contain !important;
        background: url("../../assets/images/home/top-money-floating.png")
          repeat center;
        animation: bg-slide 150s linear infinite;

        @keyframes bg-slide {
          from {
            transform: translateX(0);
          }
          to {
            transform: translateX(-50%);
          }
        }
      }
    }
    .top-right {
      position: relative;
      height: 50%;
      max-height: 500px;
      width: 450px;
      z-index: 2;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 40px;
      z-index: 6;
      bottom: -2px;
      background: linear-gradient(0, #181815, #18181500);
    }
  }

  .waitlist_link {
    color: white;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    z-index: 1;
    position: relative;
    margin-top: 10px;

    a {
      text-decoration: none;
      font-weight: 1000;
      color: var(--bg-text);
    }
    .login_link {
      cursor: pointer !important;
    }

    span {
      margin-top: 15px;
      display: block;
    }
  }

  .hero_banner {
    background: #181815;
    .banner_container {
      background: #34343299;
      max-width: 1340px;
      margin: auto;
      display: flex;
      position: relative;
      z-index: 9;
      border-radius: 20px;
      margin-top: 80px;
      margin-bottom: -60px;
      padding: 50px 60px;

      .banner_bg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        backdrop-filter: blur(40px);
        z-index: 0;
        border-radius: 20px;
      }

      .banner_content {
        text-align: left;
        z-index: 1;
        span {
          color: white;
          font-size: 28px;
          font-weight: 700;
        }

        p {
          color: white;
          font-weight: 400;
          line-height: 30px;
          margin-top: 16px;
          font-size: 20px;
        }
      }

      .banner_cta {
        z-index: 1;
        position: relative;
        margin-bottom: -20px;

        img.thumbnail {
          width: 260px;
        }

        .cta_btn {
          position: absolute;
          left: calc(50% - 28px);
          top: calc(50% - 44px);
          cursor: pointer;
        }
      }
    }
  }

  .mymodal_content_wrapper {
    a {
      color: #9faa22;
      text-decoration: none;
    }
    .my_input_wrapper {
      margin-bottom: 24px;
      // ::v-deep {
      //   input {
      //     padding: 16px;
      //     line-height: 19px;
      //   }
      // }

      &.phone {
        &:before {
          content: "+91";
          width: 50px;
          border-radius: 10px 0 0 10px;
          height: 100%;
          display: flex;
          position: absolute;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
          left: 0;
          border: 1px solid #d1d1d1;
          background: #eeeeee;
          z-index: 99999;
        }

        .placeholder {
          margin-left: 42px;
        }

        input {
          padding-left: 64px !important;
          &:disabled {
            background: #f6f6f6 !important;
          }
        }
      }
    }
    .terms_conditions {
      font-weight: 400;
      font-size: 12px;
      line-height: 24px;
      margin-top: 12px;
    }
    .resend_otp {
      text-align: left;
      margin-top: 16px;
      font-size: 16px;
      font-weight: 400;
      .counter {
        color: #9faa22;
      }
    }
  }

  .top_image {
    margin-left: 12%;
    margin-bottom: -2%;
  }

  .bottom_image {
    width: 100%;
    max-height: 340px;
    margin-bottom: -8px;
  }

  .addingToWaitlist {
    background: #f0f2e3;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    height: 100%;
    img {
      height: 100%;
      width: 150%;
      margin-left: -50%;
    }
  }

  .helpText {
    padding: 20% 0 12% 10%;
    span {
      display: block;
      color: #333;
    }

    a {
      text-decoration: none;
      margin-top: 8px;
      color: #9fa922;
      font-weight: 600;
      display: block;
      font-size: 16px;
    }
  }

  .left_part_two_images.waitlist {
    display: flex;
    height: 40vh;
    flex-direction: column;
    justify-content: space-between;
  }

  .left_part {
    .self-worth-left {
      display: flex;
      width: 100%;
      position: relative;
      justify-content: space-evenly;
      flex-direction: column;

      .progress-container {
        display: flex;
        width: 90%;
        position: relative;
        justify-content: flex-start;
        margin: 0 33px 20px 42px;
        .progress-item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          width: 33%;
          position: relative;

          .progress-item-container {
            display: flex;
            flex-direction: column;
            align-items: center;
          }

          &:after {
            content: "";
            height: 4px;
            width: 100%;
            background: #d6d9b0;
            position: absolute;
            z-index: 1;
            left: 16px;
            margin-top: -18px;
          }

          &:last-child {
            &:after {
              display: none;
            }
          }

          .progress-image {
            border: 2px solid #d6d9b0;
            display: block;
            width: 36px;
            height: 36px;
            border-radius: 100%;
            background: #d6d9b0;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 2;
            position: relative;
          }

          .progress-title {
            font-size: 14px;
            margin-top: 8px;
          }
        }

        &.--login {
          .progress-item {
            &:nth-child(1) {
              .progress-image {
                background: #cbd83f;
                border-color: #cbd83f;
              }

              .progress-title {
                font-weight: bold;
              }
            }
          }
        }
      }

      .bottom-image {
        max-height: 340px;
        margin-top: 40px;

        img {
          height: 100%;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .main_title {
      font-size: 32px;

      span {
        font-size: 56px;
        margin-bottom: -40px;
      }

      p {
        font-size: 18px;
        line-height: 100%;
      }
    }

    .waitlist_link {
      a {
        display: block;
        line-height: 24px;
      }

      span {
        margin-top: 30px;
      }
    }

    .hero_banner {
      padding: 8% 0;
      .banner_container {
        padding: 28px 32px 28px 32px;
        margin: 0 24px;

        .banner_content {
          margin-top: 12px;
          span {
            font-size: 18px;
          }

          p {
            font-size: 14px;
            line-height: 20px;
          }
        }
      }

      .banner_cta {
        display: none;
        .thumbnail {
          display: none;
        }

        .cta_btn {
          left: calc(50% - 44px) !important;
          top: calc(50% - 24px) !important;
        }
      }
    }

    .image_section {
      align-items: unset !important;
      justify-content: unset !important;
      margin-top: 40px;
      min-height: 180px;
    }

    .top-left {
      height: 50% !important;
      max-height: 600px !important;
      width: 45% !important;
      margin-left: -12%;
    }

    .top-right {
      height: 60% !important;
      max-height: 600px !important;
      width: 45% !important;
      margin-right: -12%;
    }

    .floating-money {
      height: auto !important;

      .floating-money-wrapper {
        margin-left: -32% !important;
        height: 60% !important;
        margin-top: 60% !important;
      }
    }

    .my_input_wrapper {
      width: 260px !important;
    }

    .waitlist_link {
      left: 2px;
    }

    .modal_content {
      padding-bottom: 40px;
      .my_input_wrapper {
        width: 100% !important;
      }
    }

    .email_field_wrapper {
      flex-direction: column;
      margin: 10px 0 20px 0 !important;

      > span {
        flex-direction: column;
        margin: 40px 0 28px 0 !important;

        .inputError {
          margin-left: 0 !important;
          top: 124px !important;
          left: 0;
        }
      }
      .my_button_wrapper {
        left: 0;
        width: 260px !important;
      }
    }

    ::v-deep {
      .email_field_wrapper button {
        width: 260px !important;
        height: 50px !important;
        margin-left: -20px;
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
      }

      .my_input_wrapper input {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      .modal_content {
        .my_input_wrapper input {
          width: 100% !important;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
        }
        .my_button_wrapper button {
          // width: 100% !important;
          border-radius: 10px;
        }
      }
    }

    .selfWorth--CTA {
      right: 20px;
      max-width: 90%;
      bottom: 20px;

      span {
        font-size: 16px;
      }

      p {
        font-size: 12px;
      }
    }

    .self-worth-left .bottom-image {
      max-height: 280px !important;
      margin-top: 10px !important;
    }
  }
}

.mymodal_content_wrapper {
  .input-field {
    position: relative;
    margin-bottom: 32px;

    &:after {
      content: "";
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='11' viewBox='0 0 15 11' fill='none'%3E%3Cpath d='M13.7826 1.26978C13.3879 0.851943 12.7232 0.851943 12.3285 1.26978L8.22701 5.61257C7.83239 6.03041 7.16761 6.03041 6.77299 5.61257L2.67146 1.26978C2.27684 0.851943 1.61205 0.851943 1.21743 1.26978L0.648478 1.8722C0.284495 2.25759 0.284495 2.86005 0.648478 3.24545L6.77299 9.73022C7.16761 10.1481 7.83239 10.1481 8.22701 9.73022L14.3515 3.24545C14.7155 2.86005 14.7155 2.25759 14.3515 1.8722L13.7826 1.26978Z' fill='%23C7C7C7'/%3E%3C/svg%3E");
      display: none;
      width: 15px;
      position: absolute;
      height: 10px;
      top: 22px;
      right: 16px;
      transition: all 0.15s linear;
    }

    &.active {
      .placeholder {
        top: -9px;
        transform: translate(0, 0);
        z-index: 10;
        transition: all 0.1s linear;
        background: white;
      }

      ul.search-dropdown {
        display: block;
      }
    }

    input {
      position: relative;
      z-index: 9;
      background: none !important;
    }

    input:focus ~ .placeholder {
      top: -9px;
      z-index: 10;
      transform: translate(0, 0);
      transition: all 0.1s linear;
      background: white;
    }

    // input:not(:focus) ~ ul.search-dropdown {
    //   visibility: none;
    // }

    span.placeholder {
      position: absolute;
      left: 14px;
      top: 50%;
      transform: translate(0, -50%);
      color: #bbbbbb;
      font-size: 15px;
      padding: 0 8px;
      z-index: 0;
      transition: all 0.1s linear;
    }

    span.separator {
      position: absolute;
      left: 95px;
      top: 30%;
      font-size: 24px;
    }
  }

  input {
    width: 100%;
    padding: 18px 38px 14px 18px;
    font-weight: 400;
    font-size: 16px;
    color: black;
    outline: none;
    border-radius: 10px;
    box-shadow: none;
    border: 1px solid #d1d1d1;

    &::placeholder {
      color: #adadad;
    }

    &:focus {
      border: 1px solid #0000004d !important;
    }

    &.error {
      border-color: red;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  .inputError {
    position: absolute;
    left: 0;
    top: 58px;
    font-size: 12px;
    color: red;
  }
}

.videoModal {
  iframe {
    width: 72vw;
    height: 72vh;
    border: 6px solid white;
    border-bottom: 2px solid white;
    border-radius: 20px;
  }
}

.selfWorth--CTA {
  position: fixed;
  display: flex;
  align-items: center;
  right: 40px;
  bottom: 30px;
  border-radius: 16px;
  padding: 16px;
  background: #f1f2e4;
  z-index: 99;
  max-width: 375px;
  cursor: pointer;
  transition: bottom 0.2s linear;

  .coin {
    background: #222222;
    border-radius: 16px;
    width: 75px;
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
  }

  span {
    font-weight: 700;
    font-size: 20px;
  }

  .CTA__content {
    display: block;
    width: 68%;
    // margin-left: 66px;
    text-align: left;

    p {
      margin-top: 6px;
      line-height: 20px;
    }
  }

  // &:before {
  //   content: "";
  //   display: block;
  //   width: 100px;
  //   position: absolute;
  //   height: 100px;
  //   margin-top: -26px;
  //   margin-left: -33px;
  //   background-repeat: no-repeat;
  //   background: url("../../assets/images/selfworth_coin.svg");
  // }

  &:after {
    content: "";
    display: block;
    width: 12px;
    position: absolute;
    right: 22px;
    top: 45%;
    background-repeat: no-repeat;
    height: 16px;
    transition: right 0.2s linear;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='15' viewBox='0 0 10 15' fill='none'%3E%3Cpath d='M0.769778 1.21743C0.351943 1.61205 0.351943 2.27684 0.769778 2.67146L5.11257 6.77299C5.53041 7.16761 5.53041 7.83239 5.11257 8.22701L0.769778 12.3285C0.351943 12.7232 0.351943 13.3879 0.769778 13.7826L1.3722 14.3515C1.75759 14.7155 2.36005 14.7155 2.74545 14.3515L9.23022 8.22701C9.64806 7.83239 9.64806 7.16761 9.23022 6.77299L2.74545 0.648479C2.36005 0.284496 1.75759 0.284495 1.3722 0.648478L0.769778 1.21743Z' fill='%23BBC07C'/%3E%3C/svg%3E");
  }

  &:hover {
    bottom: 35px;
    transition: bottom 0.2s linear;
    &:after {
      right: 16px;
      transition: right 0.2s linear;
    }
  }
}

.noScroll {
  .selfWorth--CTA {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1360px) {
  .image_section {
    .top-left,
    .top-right {
      max-height: 360px !important;
    }

    div#tsparticles {
      width: calc(100% + 140px) !important;
    }

    .floating-money {
      max-height: 260px;
    }
  }
}

::v-deep {
  .email_field_wrapper {
    input {
      padding: 18px 36px 15px 16px;
    }

    .my_button_wrapper {
      left: -20px;
      margin-right: -20px;
    }
  }
}
.clip {
  position: fixed;
  top: 46%;
  left: 80%;
  transform: translate(-50%, -50%) scale(21%);
  z-index: 1000;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 1;
  transition: 0.5s;
  z-index: 999999;
  pointer-events: none;
  .close {
    position: fixed;
    color: #ffffff;
    right: 0;
    top: 0;
    font-weight: bold;
    font-size: 20px;
    z-index: 999999999999;
    padding: 40px;
    width: 110px;
    height: auto;
  }
}
.clip.active {
  visibility: visible;
  opacity: 1;
  transition-delay: 0.5s;
  transform: translate(-50%, -50%) scale(1);
  top: 50%;
  left: 50%;
  background: #000000d6;
  pointer-events: auto;
}
</style>
