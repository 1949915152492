<template>
  <div class="main-header-wrapper">
    <header>
      <div class="logo">
        <router-link to="/">
          <MainLogo />
        </router-link>
      </div>
      <input class="checkbox mobile-menu" type="checkbox" name="" ref="mobile-menu" />
      <div v-if="isLoggedIn" class="hamburger-lines mobile-menu">
        <span class="line line1"></span>
        <span class="line line2"></span>
        <span class="line line3"></span>
      </div>
      <nav>
        <ul class="first-level">
          <!-- <template v-if="!isLoggedIn">
            <li>
              <router-link
                :to="{ name: 'Comics' }"
                @click.native="closeMobileNav"
              >
                Comics
              </router-link>
            </li>
            <li>
              <router-link
                :to="{ name: 'Blogs' }"
                @click.native="closeMobileNav"
              >
                Blogs
              </router-link>
            </li>
          </template> -->
          <li class="hasChildren" v-if="this.$store.state.auth">
            <div class="profile-icon">
              <img src="@/assets/images/profile_icon.svg" />
              <span>{{
                userDetails.name
                ? userDetails.name
                : ""
              }}</span>
            </div>

            <ul>
              <li class="waitlistPosition">
                <router-link :to="{ name: 'Waitlist' }" @click.native="closeMobileNav">
                  <span>Waitlist Position</span><b>{{
                    userDetails.waitlist
                    ? userDetails.waitlist
                    : "-"
                  }}</b>
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'Profile' }" @click.native="closeMobileNav">
                  My Profile
                </router-link>
              </li>
              <li class="predict-my-future">
                <router-link :to="{ name: 'Home' }" @click.native="closeMobileNav">
                  Predict my future <span>(Coming Soon)</span>
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'SelfWorth' }" @click.native="closeMobileNav">
                  Your self worth
                </router-link>
              </li>
              <li class="logout_btn"
                @click.prevent="userDetails.user_persona ? logoutConfirmationModal() : showUserPersonaFormModal()">Logout
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </header>
    <div ref="logoutConfirmationModalRef" class="logout-confirmation-modal" id="modal-container">
      <div class="modal-background">
        <div class="modal">
          <div class="main-wrapper">
            <div class="content">
              <div class="top">
                <div class="title">You got to be kiddin’ me!</div>
                <div class="info">We will miss you dearly, come back soon.</div>
              </div>
              <div class="bottom">
                <div class="ctas">
                  <a @click="cancelLogout" class="secondary">Cancel</a>
                  <a @click="logout" class="primary">Logout</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <UserPersonaFormModal ref="userPersonaInputModalRef" v-if="!userDetails.user_persona"
      v-on:userPersonaSubmit="onUserPersonaSubmit" />
  </div>
</template>

<script>
import MainLogo from "../assets/images/logo_full.svg?inline";
import UserPersonaFormModal from "./user-persona-form-modal.vue";
import { SET_AUTH_LOGOUT_MUTATION } from "../store";
export default {
  data: function () {
    return {
      logoutConfirmationModalRef: null,
      userPersonaInputModalRef: null
    }
  },
  components: {
    MainLogo,
    UserPersonaFormModal,
  },
  methods: {
    logout() {
      this.$store.commit(SET_AUTH_LOGOUT_MUTATION, false);
    },
    showUserPersonaFormModal() {
      const { classList } = this.refUserPersonalModal?.$el
      this.toggleModal(classList)
    },
    onUserPersonaSubmit() {
      this.logoutConfirmationModal()
    },
    logoutConfirmationModal() {
      const { classList = null } = this.refLogoutModal
      this.toggleModal(classList)
    },
    toggleModal(modalClassList) {
      if (!modalClassList?.contains('rarity')) {
        modalClassList?.add("rarity")
      } else if (modalClassList?.contains('out')) {
        modalClassList?.remove("out")
      } else {
        modalClassList?.add("out")
      }
    },
    cancelLogout() {
      const { classList = null } = this.refLogoutModal
      this.toggleModal(classList)
    },
    closeMobileNav() {
      this.$refs["mobile-menu"].checked = false;
    },
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.getAuth;
    },
    userDetails() {
      return this.$store.state.userDetails
    },
    refLogoutModal() {
      return this.$refs?.logoutConfirmationModalRef
    },
    refUserPersonalModal() {
      return this.$refs?.userPersonaInputModalRef
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../assets/scss/mixins';
@import "../assets/scss/modal";

.main-header-wrapper {
  margin: 0 40px;

  @include respond(sm) {
    margin: 0 20px; // SCREEN-PADDING
  }

  #modal-container {
    z-index: 9999 !important;

    &.logout-confirmation-modal {
      .modal-background {
        -webkit-backdrop-filter: blur(0px);
        backdrop-filter: blur(0px);
        background: rgba(0, 0, 0, .6);
      }

      .main-wrapper {
        position: relative;
        background: #F1F2E4;
        border-radius: 20px;
        width: 100%;
        max-width: 483px;
        height: 402px;
        margin: calc((100vh - 402px)/2) auto;
        padding: 40px;
        overflow: hidden;

        &::before {
          content: "";
          position: absolute;
          top: 15px;
          right: 0;
          background: url('../assets/images/logout-confirmation.png') bottom right no-repeat;
          background-size: contain;
          width: 100%;
          height: 100%;
          z-index: 1;
        }

        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
        }

        .content {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          height: 100%;
          font-family: Oxanium;
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          color: #000;
          font-style: normal;
          text-align: left;
          z-index: 2;

          .top {
            .title {
              font-weight: 600;
              font-size: 26px;
              line-height: 32px;
              margin-bottom: 8px;
            }

            .info {
              max-width: 197px;
              font-weight: 400;
              line-height: 22px;
            }
          }

          .bottom {
            width: 100%;

            .ctas {
              display: flex;
              width: 100%;

              a {
                width: 100%;
                font-weight: 600;
                text-align: center;
                border-radius: 8px;
                padding: 18px 28px;
                text-decoration: none;

                &.primary {
                  order: 1;
                  background: #000;
                  color: #fff;
                }

                &.secondary {
                  order: 0;
                  color: #000;
                  background: #DBDEBA;
                  margin-right: 16px;
                }
              }
            }
          }
        }
      }
    }
  }
}

header {
  display: flex;
  margin: auto;
  width: 100%;
  justify-content: space-between;

  nav {
    margin-top: 56px;

    ul {
      display: flex;
      position: relative;
      justify-content: center;
      align-items: center;

      &.first-level {
        &>li {
          padding-bottom: 15px;
        }
      }

      li {
        display: block;
        margin-left: 48px;
        position: relative;

        a {
          font-size: 16px;
          font-weight: 500;
          text-decoration: none;
          color: var(--bg-text);
          cursor: pointer !important;
        }

        &.hasChildren {
          // cursor: inherit;
          display: flex;
          color: var(--bg-accent);
          line-height: normal;
          height: 100%;
          z-index: 9;

          .profile-icon {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              // margin-top: -8px;
              margin-right: 12px;
            }

            span {
              margin-top: -1px;
              font-weight: 500;
            }
          }

          ul {
            display: none;
            z-index: 99999999;
          }

          &:hover {
            ul {
              display: flex;
              flex-direction: column;
              position: absolute;
              background: white;
              right: 0;
              top: 0;
              // margin-top: 40px;
              border-radius: 10px;

              li {
                margin-left: 0;
                width: 250px;
                text-align: left;
                color: var(--bg);
                font-weight: 400;

                &.predict-my-future {
                  opacity: 0.5;
                  pointer-events: none;
                  cursor: none;

                  span {
                    font-size: 10px;
                  }
                }

                a,
                &.logout_btn {
                  color: var(--bg);
                  font-weight: 400;
                  padding: 12px 24px 12px;
                  display: block;
                  cursor: pointer;
                }

                &:hover {
                  background: #f1f2e4;
                }

                &.waitlistPosition {
                  background: #f1f2e4;
                  border-radius: 10px 10px 0 0;
                  border-bottom: 1px solid #0000001a;

                  b {
                    color: var(--bg);
                    display: block;
                    font-weight: 700;
                    margin-top: 2px;
                  }

                  &:after {
                    content: "";
                    display: block;
                    width: 9px;
                    height: 13px;
                    right: 24px;
                    top: 32px;
                    position: absolute;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='13' viewBox='0 0 9 13' fill='none'%3E%3Cpath d='M0.803558 1.13978C0.371447 1.53609 0.371446 2.21743 0.803558 2.61374L4.49056 5.99525C4.92267 6.39156 4.92267 7.0729 4.49056 7.4692L0.803557 10.8507C0.371445 11.247 0.371446 11.9284 0.803558 12.3247L1.17702 12.6672C1.5594 13.0179 2.14648 13.0179 2.52886 12.6672L8.19644 7.4692C8.62855 7.0729 8.62855 6.39156 8.19644 5.99525L2.52886 0.797255C2.14648 0.44656 1.5594 0.44656 1.17702 0.797256L0.803558 1.13978Z' fill='%23BBC07C'/%3E%3C/svg%3E");
                    transition: right 0.2s linear;
                  }

                  &:hover {
                    &:after {
                      right: 20px;
                      transition: right 0.2s linear;
                    }
                  }
                }

                &:last-child {
                  border-radius: 0 0 10px 10px;
                }
              }
            }
          }
        }
      }
    }
  }

  .mobile-menu {
    display: none;
  }
}

.logo {
  padding: 50px 0 100px 0;

  img {
    width: 150px;
    height: 24px;
  }
}

@media (max-width: 768px) {
  .main-header-wrapper {
    .logo {
      padding: 20px 0 0 0;
      display: flex;
    }

    header {
      display: block;

      .mobile-menu {
        display: block !important;

        &.hamburger-lines {
          display: flex !important;
        }
      }

      .menu-items {
        display: flex;
      }

      li {
        display: none !important;
        list-style: none;
      }

      a {
        text-decoration: none;
        color: #0e2431;
        font-weight: 500;
        font-size: 1.2rem;
        padding-top: 0.7rem;
      }

      a:hover {
        font-weight: bolder;
      }

      .checkbox {
        position: fixed;
        height: 22px;
        width: 32px;
        top: 30px;
        right: 38px;
        z-index: 999999999999;
        opacity: 0;
        cursor: pointer;
      }

      .hamburger-lines {
        height: 19px;
        width: 30px;
        position: fixed;
        top: 32px;
        right: 20px;
        z-index: 999999999;
        flex-direction: column;
        justify-content: space-between;
      }

      .hamburger-lines .line {
        display: block;
        height: 2px;
        width: 100%;
        border-radius: 10px;
        background: white;
      }

      .hamburger-lines .line1 {
        transform-origin: 12% 0%;
        transition: transform 0.4s ease-in-out;
      }

      .hamburger-lines .line2 {
        transition: transform 0.2s ease-in-out;
      }

      .hamburger-lines .line3 {
        transform-origin: 2% 100%;
        transition: transform 0.4s ease-in-out;
      }

      input[type="checkbox"]:checked~.menu-items {
        transform: translateX(0);
      }

      input[type="checkbox"]:checked~nav li {
        display: block !important;
      }

      // input[type="checkbox"]:checked ~ .hamburger-lines {
      //       height: 25px !important;
      // }

      input[type="checkbox"]:checked~nav {
        margin-top: 0;
        position: fixed;
        background: var(--bg);
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 9999;
        padding: 16% 0;

        ul {
          align-items: flex-start;
          flex-direction: column;

          li {
            margin-bottom: 28px;
          }
        }

        .hasChildren {
          margin-top: 6px;

          .profile-icon {
            img {
              margin-top: -7px !important;
              margin-left: 13px !important;
            }
          }

          ul {
            display: flex;
            flex-direction: column;
            position: relative !important;
            background: var(--bg);
            top: 30px;
            border-radius: 10px;
            margin-left: 38px;
            border: 1px solid;

            li {
              margin-left: 0;

              margin-bottom: 0px;

              width: 200px;
              text-align: left;

              font-weight: 400;

              a,
              &.logout_btn {
                color: var(--bg-text);
                font-weight: 400;
                padding: 12px 24px 12px;
                display: block;
              }

              &.waitlistPosition {
                background: #f1f2e4;
                border-radius: 10px 10px 0 0;
                border-bottom: 1px solid #0000001a;
                margin-bottom: 16px;

                b {
                  color: var(--bg);
                  display: block;
                  font-weight: 700;
                  margin-top: 2px;
                }

                span {
                  color: var(--bg);
                }

                &:after {
                  content: "";
                  display: block;
                  width: 9px;
                  height: 13px;
                  right: 24px;
                  top: 32px;
                  position: absolute;
                  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='13' viewBox='0 0 9 13' fill='none'%3E%3Cpath d='M0.803558 1.13978C0.371447 1.53609 0.371446 2.21743 0.803558 2.61374L4.49056 5.99525C4.92267 6.39156 4.92267 7.0729 4.49056 7.4692L0.803557 10.8507C0.371445 11.247 0.371446 11.9284 0.803558 12.3247L1.17702 12.6672C1.5594 13.0179 2.14648 13.0179 2.52886 12.6672L8.19644 7.4692C8.62855 7.0729 8.62855 6.39156 8.19644 5.99525L2.52886 0.797255C2.14648 0.44656 1.5594 0.44656 1.17702 0.797256L0.803558 1.13978Z' fill='%23BBC07C'/%3E%3C/svg%3E");
                  transition: right 0.2s linear;
                }

                &:hover {
                  &:after {
                    right: 20px;
                    transition: right 0.2s linear;
                  }
                }
              }

              &:last-child {
                border-radius: 0 0 10px 10px;
              }
            }
          }
        }
      }

      input[type="checkbox"]:checked~.hamburger-lines .line {
        background: white;
      }

      input[type="checkbox"]:checked~.hamburger-lines .line1 {
        transform: rotate(45deg);
      }

      input[type="checkbox"]:checked~.hamburger-lines .line2 {
        transform: scaleY(0);
      }

      input[type="checkbox"]:checked~.hamburger-lines .line3 {
        transform: rotate(-45deg);
      }
    }

    #modal-container {
      &.logout-confirmation-modal {
        .main-wrapper {
          max-width: 90%;
          height: 340px;
          padding: 30px 30px 20px;
        }
      }
    }
  }
}</style>
