<template>
  <div class="insights_page_wrapper">
    <div class="content_wrapper">
      <div class="top_header_section">
        <div class="top_banner">
          <Header />
        </div>
        <div class="section_content">
          <div class="left_part">
            <div class="main_title">
              <span>Good job, </span> {{ userDetails.name }}
            </div>
            <div class="secondary_title">
              We crunched a lot of salary data and calculated your score with
              all due diligence. Here is a quick snapshot of your score.
            </div>
            <div class="wait_list_wrapper">
              <img src="../../assets/images/waitlist/frame.png" alt="" />
              <div class="wait_list">
                <p class="help_text">YOUR UNCOVOR SCORE</p>
                <p class="number">{{ uncovorScore }}<span>%</span></p>
              </div>
            </div>
          </div>
          <div class="right_part">
            <img src="../../assets/images/self-worth/hero-bg.png" alt="" />
          </div>
        </div>
      </div>

      <div class="salary_graph">
        <div class="salary_graph__container">
          <div class="salary_graph__heading">
            <div class="hour-glass">
              <HourGlass></HourGlass>
            </div>
            <div class="salary_graph__heading-content">
              <b>Your Waitlist position is {{ userDetails.waitlist }}</b>
              <p>
                You’ve now been added to the Uncovor waitlist and your waitlist
                rank is {{ userDetails.waitlist }}. Invite friends to move up your waitlist position.
              </p>
            </div>
            <MyButton class="salary_graph__heading--button" type="quaternary" @click="$router.push({name: 'Waitlist'})"> View Waitlist </MyButton>
          </div>
          <div class="salary_graph__body">
            <BarChart
              v-if="histogramShow.show_histogram && histogramDataLoaded"
              :labels="chartData.labels"
              :dataInput="chartData.dataInput"
              :median="chartData.median"
              :threequarters="chartData.threequarters"
            ></BarChart>
            <template v-else>
              <h3>Invite 3 friends to reveal the salary graph</h3>
              <p>
                You can unlock salary comparison chart on Uncovor by inviting
                three or more friends.
              </p>
              <div class="salary_graph__main">
                <img
                  src="@/assets/images/profile/invite_blurred.png"
                  alt="Graph"
                />
                <div class="salary_graph__main--invite-btn">
                  <MyButton width="100%" @click="onInvite">
                    Invite friends
                  </MyButton>
                  <div class="salary_graph__main--progress">
                    <div class="progress-bar">
                      <span
                        class="progress-bar-fill"
                        :style="{ width: `${invitePercentage}%` }"
                      ></span>
                    </div>
                    <span class="salary_graph__main--invited"
                      >Invited
                      {{
                        `${histogramShow.referrals_completed}/${histogramShow.referrals_required}`
                      }}</span
                    >
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div
          v-if="!(histogramShow.show_histogram && histogramDataLoaded)"
          class="salary_graph__img-container"
        >
          <img
            src="@/assets/images/profile/salary-details-bg.png"
            alt="Salary-pic"
          />
        </div>
      </div>

      <div class="quick_insights">
        <h4>Quick Insights</h4>
        <div class="quick_insights_header">
          <div
            :class="`insight-title ${
              activeInsightTab == 'All Insights' ? 'active' : ''
            }`"
            @click="activeInsightTab = 'All Insights'"
          >
            All Insights
          </div>
          <div
            :key="id"
            v-for="(insight, id) in Object.keys(insights)"
            :class="`insight-title ${
              activeInsightTab == insight ? 'active' : ''
            }`"
            @click="activeInsightTab = insight"
          >
            {{ insight }}
          </div>
        </div>
        <div class="quick_insights_content_wrapper">
          <div
            v-if="activeInsightTab == 'All Insights'"
            class="quick_insights_content"
          >
            <template v-for="insight in Object.keys(insights)">
              <div
                :key="card.id"
                v-for="card in insights[insight]"
                class="insight-card"
              >
                <template v-if="cardIcons[card.category]">
                  <div class="quick_insights_content__icon-wrapper">
                    <img :src="getInsightsIconPath(card.category)" />
                  </div>
                </template>
                {{ card.insight }}
              </div>
            </template>
          </div>
          <div v-else class="quick_insights_content">
            <div
              :key="id"
              v-for="(card, id) in insights[activeInsightTab]"
              class="insight-card"
            >
              <template v-if="cardIcons[card.category]">
                <div class="quick_insights_content__icon-wrapper">
                  <img :src="getInsightsIconPath(card.category)" />
                </div>
              </template>
              {{ card.insight }}
            </div>
          </div>
        </div>
      </div>

      <div class="share_insights">
        <div class="main_title">
          <span>Taunt your friends</span><br />
          <p>to share their salary details</p>
        </div>
        <div class="share_container">
          <div class="share_left" v-if="uncovorScore || memeImg">
            <MemePicker :uncovorScore="uncovorScore" :img="memeImg" />
          </div>
          <div class="share_right">
            <span
              >Share it on social media with your friends and colleagues and ask
              their self worth too</span
            >
            <div class="share_social">
              <div class="social_icon">
                <a
                  target="_blank"
                  :href="`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
                    memeImg
                  )}`"
                >
                  <img src="../../assets/images/waitlist/linkedin.svg" />
                </a>
              </div>
              <div class="social_icon">
                <a
                  target="_blank"
                  :href="`https://twitter.com/intent/tweet?url=https%3A%2F%2Funcovor.com%2F&text=Salary%20is%20open-source%20now%2C%20know%20your%20self%20worth&hashtags=KnowYourSelfWorth,Uncovor`"
                >
                  <img src="../../assets/images/waitlist/twitter.svg" />
                </a>
              </div>
              <div class="social_icon">
                <a
                  target="_blank"
                  href="https://www.facebook.com/sharer.php?u=https%3A%2F%2Funcovor.com%2F"
                >
                  <img src="../../assets/images/waitlist/facebook.svg" />
                </a>
              </div>
              <div class="social_icon">
                <a
                  target="_blank"
                  href="https://wa.me/?text=Know%20your%20self%20worth%3A%20https%3A%2F%2Funcovor.com%2F"
                  data-action="share/whatsapp/share"
                >
                  <img src="../../assets/images/waitlist/whatsapp.svg" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  </div>
</template>

<script>
import { api } from "../../services/http-common.js";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import MyButton from "../../components/button";
import MemePicker from "../../components/MemePicker";
import BarChart from "../../components/BarChart";
import HourGlass from "../../assets/images/insights/hourglass.svg?inline";
import { closestIndex } from "../../helpers/utils";

export default {
  components: { Header, Footer, MemePicker, HourGlass, MyButton, BarChart },
  data() {
    return {
      activeInsightTab: "All Insights",
      uncovorScore: "",
      memeImg: "",
      insights: {},
      cardIcons: {
        company: "cardIcon",
        hike1: "career-choices",
        EmotionalWellbeing: "emotional-wellbeing",
        hike: "information-power",
        underpaid: "underpaid",
        // new
        "Pay Masters": "pay-masters",
        Peers: "peers",
        Trivia: "trivia",
        "Job Change": "job-switch",
        "Silicon Valley": "silicon-valley",
        Education: "education",
        Juniors: "juniors",
        Seniors: "seniors",
      },
      histogramShow: {},
      histogramDataLoaded: false,
      chartData: {
        labels: [],
        dataInput: [],
        median: 0,
        threequarters: {
          scale: 0,
          value: 0,
        },
      },
    };
  },
  methods: {
    getInsightsIconPath(category) {
      return require(`../../assets/images/self-worth/${this.cardIcons[category]}.svg`);
    },
    getInsightsHistogramShow() {
      api
        .get(`api/v1/insights/uncovor_histogram/show`)
        .then((response) => {
          this.histogramShow = response.data.data || {};

          // TODO - Temp
          // this.histogramShow.show_histogram = true;
          if (this.histogramShow.show_histogram) {
            this.getInsightsHistogram();
          }
        })
        .catch(console.warn);
    },
    getInsightsHistogram() {
      api
        .get(`api/v1/insights/uncovor_histogram`)
        .then((response) => {
          const histogram = response.data.data.histogram;
          this.histogramDataLoaded = true;
          this.chartData.labels = histogram.bins;
          this.chartData.dataInput = histogram.values;
          this.chartData.median = closestIndex(
            histogram.median,
            this.chartData.labels
          );
          this.chartData.threequarters.scale = closestIndex(
            histogram.threequarters,
            this.chartData.labels
          );
          this.chartData.threequarters.value = `${histogram.threequarters}%`;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    onInvite() {
      const referral_code = (this.$store.getters.getUser || {}).referral_code;
      const refUrl =
        referral_code && `https://uncovor.com/#/?ref=${referral_code}`;
      if (refUrl) {
        const text = `Know your self worth: ${encodeURIComponent(refUrl)}`;
        const whatsappUrl = `https://wa.me/?text=${text}`;
        window.open(whatsappUrl, "_blank");
      }
    },
  },
  computed: {
    userDetails() {
      return this.$store.state.userDetails;
    },
    invitePercentage() {
      const ref_completed = this.histogramShow.referrals_completed || 0;
      const ref_required = this.histogramShow.referrals_required || 0;
      if (ref_completed > ref_required) {
        return 100;
      } else {
        return (
        ((this.histogramShow.referrals_completed || 0) /
            (this.histogramShow.referrals_required || 0)) *
          100
        ).toFixed(3);
      }
    },
  },
  created() {
    this.getInsightsHistogramShow();
    let self = this;
    api
      .get(`api/v1/insights/`)
      .then(function (response) {
        let insightsData = {};
        response.data.data.forEach(function (item) {
          if (insightsData[item.category]) {
            insightsData[item.category].push(item);
          } else {
            insightsData[item.category] = [item];
          }
        });
        self.insights = { ...insightsData };
      })
      .catch((e) => {
        console.log(e);
      });

    api
      .get(`api/v1/insights/uncovor_score`)
      .then((response) => {
        let uncovorScore = response.data.data.score;
        uncovorScore = String(uncovorScore).substr(0, 5);
        self.uncovorScore = uncovorScore;
      })
      .catch((e) => {
        console.log(e);
      });

    api
      .get(`api/v1/meme/score`)
      .then((response) => {
        this.memeImg = response.data.data;
      })
      .catch((e) => {
        console.log(e);
      });
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/mixins';
.insights_page_wrapper {
  overflow: hidden;
  text-align: left;
  background: var(--bg);
  // padding: 0 10%;

  .top_header_section {
    background: var(--bg);

    .top_banner {
      .logo {
        padding: 50px 0 60px 0;
        img {
          width: 150px;
          height: 30px;
        }
      }
    }
    .section_content {
      margin: 0 40px;
      @include respond(sm) {
        margin: 0 20px; // SCREEN-PADDING
      }
      .left_part {
        width: 50%;
      }
      .right_part {
        width: 50%;
        position: relative;
        img {
          width: 100%;
          position: absolute;
          top: -10%;
          max-width: 670px;
        }
      }
      display: flex;
      .main_title {
        color: var(--bg-text);
        font-size: 66px;
        margin: 0 auto;
        line-height: 82px;
        font-family: BrotherLine !important;

        span {
          font-family: BrotherLine !important;
          position: relative;
          color: white;
          font-size: 74px;
          text-align: center;
          display: inline-block;
          margin-bottom: -34px;
        }
      }
      p.secondary_title {
        width: 50%;
        opacity: 1;
      }
      .secondary_title {
        font-weight: 500;
        font-size: 21px;
        line-height: 36px;
        color: #f2f4f9;
        opacity: 0.7;
        max-width: 65%;
        padding: 24px 0;
      }
      .wait_list_wrapper {
        position: relative;
        display: inline-block;
        .wait_list {
          position: absolute;
          top: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          .help_text {
            font-size: 16px;
            font-weight: 500;
            line-height: 27px;
            color: white;
          }
          .number {
            font-weight: 800;
            font-size: 64px;
            color: var(--bg-text);
            background: transparent;
            margin: 0;
            height: auto;
            display: block;
            padding: 0;
            line-height: 1;
            span {
              font-weight: normal;
            }
          }
        }
      }
    }
  }

  .salary_graph {
    position: relative;
    margin-top: 150px;
    &__container {
      margin: 60px 40px;
      background: #ffffff;
      border-radius: 20px;
      overflow: hidden;
      @include respond(sm) {
        margin: 60px 20px; // SCREEN-PADDING
      }
    }

    &__heading {
      display: flex;
      background: #f1f2e4;
      padding: 30px;
      justify-content: space-between;
      align-items: center;
      b {
        color: #000000;
      }
      p {
        width: 95%;
      }
      .hour-glass {
        position: relative;
        width: 70px;
        height: 70px;
        border-radius: 50%;
        background-color: #fddb3a;
        svg {
          position: absolute;
          top: -2px;
          left: -5px;
        }
      }
      :deep(.my_button_container) {
        width: 210px;
      }
    }
    &__heading-content {
      width: calc(100% - 283px);
      margin-left: 20px;
      p {
        padding-top: 5px;
        line-height: 1.3rem;
        opacity: 0.7;
      }
    }

    &__body {
      padding: 30px;
      h3 {
        font-size: 28px;
        font-weight: bold;
        color: #000000;
      }
      .progress-bar {
        width: 54%;
        background-color: #d4d7ac;
        border-radius: 16px;
        height: 15px;
      }

      .progress-bar-fill {
        display: block;
        height: 15px;
        background-color: #8f981f;
        border-radius: 16px;
        transition: width 500ms ease-in-out;
      }
    }
    &__main {
      margin-top: 30px;
      position: relative;
      &--invite-btn {
        width: 260px;
        text-align: center;
        padding: 10px;
        background-color: white;
        box-shadow: 0px 0px 4px #8d8d8d;
        border-radius: 10px;
        position: absolute;
        top: 50%;
        left: 25%;
        transform: translate(-50%, -50%);
        & > :first-child {
          margin-bottom: 10px;
        }
      }
      &--progress {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      &--invited {
        font-weight: bold;
        text-transform: uppercase;
      }
    }
    &__img-container {
      position: absolute;
      right: 35px;
      bottom: 354px;
      width: 350px;
      img {
        position: absolute;
      }
    }
  }
  .quick_insights {
    margin: 8% 40px 0 40px;
    @include respond(sm) {
      margin: 8% 20px 0 20px; // SCREEN-PADDING
    }
    h4 {
      color: #cbd83f;
      font-style: normal;
      font-weight: 800;
      font-size: 58px;
    }

    .quick_insights_header {
      display: flex;
      margin: 32px 0;
      overflow: scroll;

      .insight-title {
        background: #525251;
        color: white;
        text-transform: capitalize;

        padding: 10px 18px;
        margin-right: 12px;
        border-radius: 10px;
        cursor: pointer;

        &.active {
          background: #cbd93e;
          color: #181815;
        }
      }
    }

    .quick_insights_content_wrapper {
      height: 25em;
      overflow: scroll;
    }

    .quick_insights_content {
      display: flex;
      flex-wrap: wrap;

      .insight-card {
        width: 24%;
        background: white;
        margin-bottom: 1%;
        margin-right: 1%;
        padding: 24px;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        line-height: 22px;

        img {
          width: 56px;
          margin-top: 0px;
          height: 56px;
        }
      }
      &__icon-wrapper {
        width: 56px;
        height: 56px;
        background: #cbd93e;
        border-radius: 100%;
        overflow: hidden;
        margin-bottom: 20px;
      }
    }
  }

  .share_insights {
    margin: 100px 40px 0 40px;
    @include respond(sm) {
      margin: 50px 20px 0 20px !important; // SCREEN-PADDING
    }
    .main_title {
      color: var(--bg-text);
      font-weight: 800;
      font-size: 48px;
      margin: 0 auto;

      span {
        font-family: BrotherLine !important;
        position: relative;
        color: white;
        font-size: 80px;
        font-weight: 400 !important;
        text-align: center;
        display: inline-block;
        margin-bottom: -34px;
      }
    }

    .share_container {
      margin-top: 60px;
      display: flex;
      justify-content: space-around;
      .share_left {
        img {
          max-width: 540px;
        }
      }
      .share_right {
        margin-left: 6%;
        span {
          font-size: 21px;
          color: white;
          max-width: 65%;
          display: block;
          line-height: 32px;
        }

        .share_social {
          display: flex;
          margin-top: 36px;

          .social_icon {
            margin-right: 24px;
            cursor: pointer;
            img {
              border-radius: 100%;
            }
          }
        }
      }
    }
  }

  ::v-deep {
    .my_button_wrapper button {
      padding: 12px 18px 12px 18px;
      color: #181815 !important;
      line-height: 100%;
    }

    .my_input_wrapper {
      width: 100%;
      input {
        border: 1px solid white !important;
        color: #191915b3 !important;
      }
    }

    .footer_canvas {
      top: 0px;
      &:before {
        display: none;
      }
    }
  }

  @include respond(lg) {
    .section_content {
      margin: 0;
    }
    .salary_graph {
      &__img-container {
        right: 37px;
        bottom: 162px;
        width: 160px;
      }
    }
  }

  @media (max-width: 768px) {
    padding: 0;

    .section_content {
      flex-direction: column;

      .section_title {
        font-size: 14px;
        line-height: 26px;
      }

      .main_title {
        font-size: 42px !important;
        line-height: 44px !important;

        span {
          font-size: 42px !important;
          text-align: left !important;
          display: block !important;
          margin-bottom: 0 !important;
        }
      }

      .secondary_title {
        font-size: 14px !important;
        line-height: 26px !important;
        max-width: 100% !important;
        padding: 16px 0 !important;
      }

      .left_part {
        width: 100% !important;
      }

      .right_part {
        width: 100% !important;

        img {
          position: relative !important;
          margin-top: 36px !important;
        }
      }
    }

    .quick_insights {

      h4 {
        font-size: 32px !important;
      }

      .quick_insights_header {
        margin: 24px 0 !important;
        overflow: scroll !important;
      }

      .quick_insights_content {
        flex-direction: column;

        .insight-card {
          width: 100% !important;
          margin-bottom: 16px !important;
        }
      }
    }

    .share_insights {
      margin: 60px 40px;

      .main_title {
        font-size: 28px !important;
        line-height: 34px !important;

        span {
          font-size: 46px !important;
        }
      }

      .share_container {
        flex-direction: column;

        .share_left img {
          width: 100%;
        }

        .share_right {
          margin-left: 0 !important;

          span {
            font-size: 14px !important;
            max-width: 100%;
            line-height: 24px;
            margin-top: 24px !important;
          }

          .share_social {
            margin-top: 26px;

            .social_icon {
              margin-right: 16px;
              img {
                max-width: 32px;
              }
            }
          }
        }
      }
    }
    .salary_graph {
      margin-top: 0;

      &__heading {
        // padding-bottom: 85px;
        display: block;
        p {
          width: 100%;
        }
        .hour-glass {
          display: none;
        }
        &--button {
          // margin-top: 30px;
          width: 100%;
          // position: absolute;
          // left: 50%;
          // transform: translate(-50%, -50%);
        }
        :deep(.my_button_container) {
          width: 100%;
        }
      }
      &__heading-content {
        margin-left: 0;
        margin-bottom: 30px;
        width: 100%;
        p {
          width: 100%;
        }
      }
      &__main {
        &--invite-btn {
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      &__img-container {
        display: none;
      }
    }
  }
}
</style>
