<template>
  <div class="userProfile">
    <div class="content_wrapper">
      <Header />
      <div class="userTitle">
        <span>{{ userDetails.name }}'s</span> Profile
      </div>
      <div class="completeProfile">
        <h4>Complete your profile</h4>
        <div :class="`accordion ${accordionOpen ? 'open' : ''}`">
          <div class="accordion-head">
            <div :class="['profileStatus', userDetails.profile_level]">
              <span class="status-rookie">Rookie</span>
              <span class="status-seasoned">Seasoned</span>
              <span class="status-ninja">Ninja</span>
            </div>
            <div class="statusDays">
              <div class="time-icon"></div>
              {{ getNumberOfDays(userDetails.created_at) }}
            </div>
            <div class="accordion-dropdown" @click="toggleDropdown"></div>
          </div>
          <div class="accordion-body">
            <div class="list">
              <div class="list-item">
                <div class="list-logo">
                  <img src="@/assets/images/profile/invite-friends.svg" />
                </div>
                <div class="list-description pl-28 pr-5">
                  <h4>Invite your best 3 friends or colleagues</h4>
                  <span
                    >Spread the word and invite at least 3 friends to try out
                    Uncovor</span
                  >
                </div>
                <div class="list-cta">
                  <div class="list-cta__container">
                    <MyButton width="100%" @click="onInvite">
                      Invite friends
                    </MyButton>
                    <div class="invite-btn__progress">
                      <div class="invite-btn__progress-bar">
                        <span
                          class="invite-btn__progress-bar-fill"
                          :style="{ width: `${invitePercentage}%` }"
                        ></span>
                      </div>
                      <span class="invite-btn__invited"
                        >Invited
                        {{
                          `${histogramShow.referrals_completed}/${histogramShow.referrals_required}`
                        }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="list-item salary-slip">
                <div class="list-logo">
                  <img src="@/assets/images/profile/upload-payslip.svg" />
                </div>
                <div class="list-description pl-28 pr-5">
                  <h4>Upload your salary slip</h4>
                  <span
                    >Securely upload your salary slip to move up the Uncovor
                    waitlist</span
                  >
                </div>
                <div class="list-cta">
                  <template v-if="this.verification_status == 0">
                    <div @click="uploadSalarySlip" class="btn">Get Started</div>
                  </template>
                  <template v-else-if="this.verification_status == 1">
                    <div class="btn disabled">Salary slip verified</div>
                  </template>
                  <template v-else-if="this.verification_status == 2">
                    <div class="btn disabled">Salary slip under review</div>
                  </template>
                  <template v-else-if="this.verification_status == 3">
                    <div class="btn">Salary slip rejected, re upload</div>
                  </template>
                  <template v-else-if="this.verification_status == 4">
                    <div class="btn disabled">
                      Re upload salary slip after 6 Months
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <div v-if="insightsHtml" class="completeProfile__did_you_know">
              <b>Did you know ?</b>
              <p v-html="insightsHtml"></p>
              <FeatherSVG
                class="completeProfile__did_you_know--feather"
              ></FeatherSVG>
            </div>
          </div>
        </div>
      </div>

      <div v-if="basicDetailsAvailable" class="basicDetails">
        <h4>Basic Details</h4>

        <div class="container">
          <div class="container-head">
            <div class="profile-photo">
              <span @click="showProfileImageModal" class="edit"
                ><b-icon icon="pencil" size="is-small"> </b-icon
              ></span>
              <img v-if="userDetails.image" v-bind:src="userDetails.image" />
              <img v-else src="@/assets/images/profile/profile-icon.png" />
            </div>
            <div class="profile-details">
              <h4>{{ userDetails.name }}</h4>
              <span class="yoe"
                >{{
                  userDetails.total_experience.years &&
                  userDetails.total_experience.years > 1
                    ? userDetails.total_experience.years + " years"
                    : userDetails.total_experience.years + " year"
                }}
                {{
                  userDetails.total_experience.months &&
                  userDetails.total_experience.months > 1
                    ? userDetails.total_experience.months + " months"
                    : userDetails.total_experience.months > 0
                    ? userDetails.total_experience.months + " month"
                    : ""
                }}
                of experience
                {{
                  Object.keys(userDetails.location || {}).length
                    ? "| " +
                      userDetails.location.city +
                      ", " +
                      userDetails.location.country
                    : ""
                }}
              </span>
              <a
                v-if="userDetails.linkedin_url"
                target="_blank"
                :href="`https://www.${userDetails.linkedin_url}`"
                class="linkedin"
                >/{{
                  userDetails.linkedin_url.split("/").reverse()[0] !== ""
                    ? userDetails.linkedin_url.split("/").reverse()[0]
                    : userDetails.linkedin_url.split("/").reverse()[1]
                }}</a
              >
            </div>
            <div class="edit-section">
              <MyButton width="100%" type="quaternary" @click="editBasicDetails">
                <div class="edit-section__button">
                  <EditPencil></EditPencil> <span>Edit section</span>
                </div>
              </MyButton>
            </div>
          </div>
          <div class="container-body">
            <div class="container-body-left">
              <div class="education-details">
                <h4>Education</h4>
                <div class="list">
                  <div
                    v-for="(education, i) in userDetails.education"
                    :key="i + education.college_name"
                    class="list-item"
                  >
                    <div class="list-logo">
                      <img
                        v-if="education.college.logo_url"
                        v-bind:src="education.college.logo_url"
                      />
                      <img
                        v-else
                        src="@/assets//images/profile/college-icon.svg"
                      />
                    </div>
                    <div class="list-description">
                      <h4>{{ education.college.name }}</h4>
                      <span>
                        <template
                          v-if="education.start_date && education.end_date"
                        >
                          {{ education.start_date.split("-")[0] }} -
                          {{ education.end_date.split("-")[0] }}
                        </template>
                        <template
                          v-if="education.degree && education.course_name"
                        >
                          | {{ education.degree }} in
                          {{ education.course_name }}
                        </template>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="we-details">
                <h4>Work Experience</h4>
                <div class="list">
                  <div
                    v-for="(work_ex, index) in userDetails.work_ex
                      .slice()
                      .reverse()"
                    :key="index + work_ex.company_name"
                    class="list-item"
                  >
                    <div class="list-logo">
                      <img :src="work_ex.company.logo_url" />
                    </div>
                    <div class="list-description">
                      <h4>{{ work_ex.designation }}</h4>
                      <span
                        >{{ work_ex.company_name }}
                        <template v-if="work_ex.start_date">
                          | {{ jobDuration[index] }}
                          (
                          {{
                            months[new Date(work_ex.start_date).getUTCMonth()]
                          }}
                          {{ new Date(work_ex.start_date).getUTCFullYear() }} -
                          {{
                            work_ex.is_current || work_ex.end_date == null
                              ? "Present"
                              : months[
                                  new Date(work_ex.end_date).getUTCMonth()
                                ] +
                                " " +
                                new Date(work_ex.end_date).getUTCFullYear()
                          }}
                          )
                        </template>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="container-body-right waitlist_position_widget">
              <div class="waitlist">
                <h4>{{ userDetails.waitlist }}</h4>
                <span>Your waitlist position</span>
                <p>
                  We shall email you when we enable the access to you. Until
                  then keep referring to move up the waitlist.
                </p>
                <div class="waitlist__btn-container">
                  <MyButton width="80%" @click="onInvite">
                    Invite friends
                  </MyButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="salaryDetailsAvailable" class="salaryDetails">
        <h4>Salary Details</h4>

        <div class="container">
          <div class="container-head" v-if="!histogramShow.show_histogram">
            <div class="container-head-left">
              <h4>Invite 3 friends to reveal the salary graph</h4>
              <p>
                You can unlock salary comparison chart on Uncovor by inviting
                three or more friends.
              </p>

              <div class="invite-friends">
                <div class="btn-container">
                  <MyButton width="100%" @click="onInvite">
                    Invite friends
                  </MyButton>
                  <div class="invite-btn__progress">
                    <div class="invite-btn__progress-bar">
                      <span
                        class="invite-btn__progress-bar-fill"
                        :style="{ width: `${invitePercentage}%` }"
                      ></span>
                    </div>
                    <span class="invite-btn__invited"
                      >Invited
                      {{
                        `${histogramShow.referrals_completed}/${histogramShow.referrals_required}`
                      }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="container-head-right">
              <img src="@/assets/images/profile/salary-details-bg.png" />
            </div>
          </div>
          <div
            v-if="histogramShow.show_histogram && histogramDataLoaded"
            class="container-head chart-head"
          >
            <div class="container-head-left histo-loaded">
              <h4>{{ userDetails.name }}'s salary stack-ranked</h4>
              <p>
                The following graph shows distribution of salaries among your
                peers (3-5 years of workex)
              </p>
              <div class="list-item salaryDetails__chart-container">
                <BarChart
                  :labels="chartData.labels"
                  :dataInput="chartData.dataInput"
                  :median="chartData.median"
                  :threequarters="chartData.threequarters"
                  labelBg="#f1f2e4"
                ></BarChart>
              </div>
            </div>
            <div class="container-head-right histo-loaded">
              <div class="edit-section">
                <MyButton width="100%" type="quaternary" @click="editSalaryDetails">
                  <div class="edit-section__button">
                    <EditPencil></EditPencil> <span>Edit section</span>
                  </div>
                </MyButton>
              </div>
              <div class="waitlist">
                <span>Upload salary slip to recheck where you stand</span>
                <p>
                  Uncovor is a verified salary club. By uploading your salary
                  you are agreed..
                </p>
                <div class="waitlist__btn-container">
                  <MyButton width="80%" @click="uploadSalarySlip">
                    Upload Salary Slip
                  </MyButton>
                </div>
              </div>
            </div>
          </div>
          <div class="container-body">
            <div class="list-group">
              <h4>Basic Information</h4>
              <div class="list">
                <div class="list-item">
                  <span>{{ "₹ " + salaryDetails.base_salary_amount }}</span>
                  <p>Base salary</p>
                </div>
                <div class="list-item">
                  <span>{{
                    salaryDetails.relocation_bonus_amount
                      ? "₹ " + salaryDetails.relocation_bonus_amount
                      : "-"
                  }}</span>
                  <p>Relocation bonus</p>
                </div>
                <div class="list-item">
                  <span>{{
                    salaryDetails.sign_on_bonus &&
                    salaryDetails.sign_on_bonus.amount_1
                      ? "₹ " + salaryDetails.sign_on_bonus.amount_1
                      : "-"
                  }}</span>
                  <p>Sign-on bonus 1</p>
                </div>
                <div class="list-item">
                  <span>{{
                    salaryDetails.sign_on_bonus &&
                    salaryDetails.sign_on_bonus.amount_2
                      ? "₹ " + salaryDetails.sign_on_bonus.amount_2
                      : "-"
                  }}</span>
                  <p>Sign-on bonus 2</p>
                </div>
              </div>
            </div>

            <div class="list-group">
              <h4>Your stock grants</h4>
              <div class="list">
                <div class="list-item">
                  <span>{{
                    salaryDetails.annual_esops &&
                    salaryDetails.annual_esops.amount
                      ? "$ " + salaryDetails.annual_esops.amount
                      : "-"
                  }}</span>
                  <p>Total stocks grant</p>
                </div>
                <div class="list-item">
                  <span>{{
                    salaryDetails.annual_esops &&
                    salaryDetails.annual_esops.vesting_schedule
                      ? salaryDetails.annual_esops &&
                        salaryDetails.annual_esops.vesting_schedule
                      : "-"
                  }}</span>
                  <p>Vesting Schedule</p>
                </div>
                <div class="list-item">
                  <span>{{
                    salaryDetails.annual_esops &&
                    salaryDetails.annual_esops.valuation
                      ? "$ " + salaryDetails.annual_esops.valuation
                      : "-"
                  }}</span>
                  <p>Valuation at grant</p>
                </div>
              </div>
            </div>

            <div class="list-group">
              <h4>Other details</h4>
              <div class="list">
                <div class="list-item">
                  <span>{{
                    salaryDetails.performance_bonus_amount
                      ? "₹ " + salaryDetails.performance_bonus_amount
                      : "-"
                  }}</span>
                  <p>Performace bonus</p>
                </div>
                <div class="list-item">
                  <span>{{
                    salaryDetails.relocation_bonus_amount
                      ? "₹ " + salaryDetails.relocation_bonus_amount
                      : "-"
                  }}</span>
                  <p>Rentention bonus</p>
                </div>
                <!-- <div class="list-item">
                  <span>{{
                    salaryDetails.other_benefits &&
                    salaryDetails.other_benefits.amount
                      ? "₹ " + salaryDetails.other_benefits.amount
                      : "-"
                  }}</span>
                  <p>Other benefits</p>
                </div> -->
                <!-- Dummy for alignment of lg -->
                <div class="list-item">
                  <span></span>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="!salaryDetailsAvailable" class="placeholder">
        Self Worth Flow is pending
      </div>
    </div>
    <Footer />
    <UploadPayslip
      :show="showUploadPaySlip"
      v-on:closeModal="this.closeSalaryUpload"
      @uploaded="getUserDetails()"
    />
    <Modal
      :show="modal.basicDetails"
      :showLeftPart="true"
      :customClass="'my_modal_wrapper'"
      :title="'Edit Basic Details'"
      v-on:closeModal="this.closeBasicDetailsModal"
    >
      <template v-slot:image>
        <p>Use this space to edit your basic profile information</p>
        <img
          src="@/assets/images/profile/edit-basic-details.png"
          alt="Edit Basic Salary Details"
        />
      </template>
      <BasicDetails
        v-bind:loadingBtn="loading.basicDetails"
        v-on:basicDetailsBtnClick="saveBasicDetails"
        :linkedinDetails="linkedinDetails"
        :type="'edit'"
      />
    </Modal>
    <Modal
      :show="modal.salaryDetails"
      :showLeftPart="true"
      :customClass="'my_modal_wrapper edit-salary-modal'"
      :title="'Edit Salary Details'"
      v-on:closeModal="this.closeSalaryDetailsModal"
    >
      <template v-slot:image>
        <p>Use this space to edit your basic profile information</p>
        <img
          src="@/assets/images/profile/edit-basic-details.png"
          alt="Edit Basic Salary Details"
        />
      </template>
      <SalaryDetails
        v-bind:loadingBtn="loading.salaryDetails"
        v-on:salaryDetailsBtnClick="this.saveSalaryDetails"
        :type="'edit'"
        :currentSalaryDetails="this.salaryDetails"
      />
    </Modal>
    <ProfileImageModalVue :show="showProfileImageModalVar" @closeModal="showProfileImageModalVar = false" :userDetails="userDetails" @updateUserDetails="showProfileImageModalVar = false; getUserDetails()"></ProfileImageModalVue>
  </div>
</template>

<script>
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Modal from "../../components/modal";
import MyButton from "../../components/button";
import UploadPayslip from "../../components/upload-payslip.vue";
import BasicDetails from "../../views/Self-Worth/BasicDetails.vue";
import SalaryDetails from "../../views/Self-Worth/SalaryDetails.vue";
import { api } from "../../services/http-common.js";
import ProfileImageModalVue from "../../components/ProfileImageModal.vue";
import dayjs from "dayjs";
import BarChart from "../../components/BarChart";
import FeatherSVG from "../../assets/svg/feather.svg?inline";
import EditPencil from "../../assets/svg/icons/edit-pencil.svg?inline";
import { closestIndex } from "../../helpers/utils";

export default {
  components: {
    Header,
    Footer,
    UploadPayslip,
    BasicDetails,
    SalaryDetails,
    Modal,
    MyButton,
    BarChart,
    FeatherSVG,
    EditPencil,
    ProfileImageModalVue
  },
  data() {
    return {
      userDetails: {
        profile_level: "",
        created_at: "",
        total_experience: {
          years: "",
          months: "",
        },
        location: {
          city: "",
          country: "",
        },
        linkedin_url: "",
        work_ex: [
          {
            designation: "",
            company_name: "",
            start_date: "",
            end_date: "",
            company: {
              logo_url: "",
            },
          },
        ],
      },
      verification_status: 0,
      showUploadPaySlip: false,
      jobDuration: [],
      salaryDetails: {
        annual_esops: [{}, {}, {}, {}],
      },
      accordionOpen: true,
      loading: {
        basicDetails: false,
        salaryDetails: false,
      },
      modal: {
        basicDetails: false,
        salaryDetails: false,
      },
      months: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "June",
        "July",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      linkedinDetails: {},
      currentSalaryDetails: {},
      chartData: {
        labels: [],
        dataInput: [],
        median: 0,
        threequarters: {
          scale: 0,
          value: 0,
        },
      },
      historgramData: {},
      histogramDataLoaded: false,
      histogramShow: {},
      salaryDetailsAvailable: true,
      basicDetailsAvailable: true,
      insightsHtml: "",
      showProfileImageModalVar: false,
    };
  },
  methods: {
    toggleDropdown() {
      this.accordionOpen = !this.accordionOpen;
    },
    uploadSalarySlip() {
      this.showUploadPaySlip = true;
      document.querySelector("html").classList.add("noScroll");
    },
    closeSalaryUpload() {
      this.showUploadPaySlip = false;
      document.querySelector("html").classList.remove("noScroll");
    },
    closeBasicDetailsModal() {
      this.modal.basicDetails = false;
      document.querySelector("html").classList.remove("noScroll");
    },
    saveBasicDetails() {
      this.modal.basicDetails = false;
      location.reload();
      document.querySelector("html").classList.remove("noScroll");
    },
    editBasicDetails() {
      this.modal.basicDetails = true;
      document.querySelector("html").classList.add("noScroll");
    },
    closeSalaryDetailsModal() {
      this.modal.salaryDetails = false;
      document.querySelector("html").classList.remove("noScroll");
    },
    saveSalaryDetails() {
      this.modal.salaryDetails = false;
      location.reload();
      document.querySelector("html").classList.remove("noScroll");
    },
    editSalaryDetails() {
      this.modal.salaryDetails = true;
      document.querySelector("html").classList.add("noScroll");
    },
    getNumberOfDays(created_at) {
      if (!created_at) {
        return "";
      }
      const date1 = dayjs(created_at);
      const date2 = dayjs();
      let hours = date2.diff(date1, "hours");
      const days = Math.floor(hours / 24);
      hours = hours - days * 24;
      return days > 60 ? "Overdue" : 60 - days + " days left";
    },
    showProfileImageModal() {
      this.showProfileImageModalVar = true;
      // const $this = this;
      // this.$buefy.modal.open({
      //   parent: this,
      //   component: ProfileImageModalVue,
      //   props: {
      //     userDetails: this.userDetails,
      //   },
      //   events: {
      //     updateUserDetails() {
      //       $this.getUserDetails();
      //     },
      //   },
      //   hasModalCard: true,
      //   customClass: "custom-class custom-class-2",
      //   trapFocus: true,
      // });
    },
    getUserDetails() {
      let self = this;
      api
        .get(`api/v1/user/profile`)
        .then((response) => {
          this.$store.commit("setUser", response.data);
          self.userDetails = response.data;
          self.userDetails.work_ex.forEach((user) => {
            let diff;
            if (!user.end_date) {
              diff = (
                (Date.now() - Date.parse(user.start_date)) /
                31536000000
              ).toString();
            } else {
              diff = (
                (Date.parse(user.end_date) - Date.parse(user.start_date)) /
                31536000000
              ).toString();
            }

            let years = parseInt(diff[0]);
            if (years < 1) {
              years = "";
            } else if (years == 1) {
              years = "1 Year ";
            } else {
              years = `${years} Years `;
            }

            let months = Math.round(parseInt(diff.slice(2, 4)) / 10);

            if (months < 1) {
              months = "";
            } else if (months == 1) {
              months = `1 Month`;
            } else {
              months = `${months} Months`;
            }
            self.jobDuration.push(years + months);
          });

          self.jobDuration = self.jobDuration.reverse();

          self.salaryDetails = self.userDetails.salary.filter(
            (salary) => salary.is_current == true
          )[0];

          self.verification_status = self.userDetails.verification_status;

          self.linkedinDetails = {
            education: self.userDetails.education,
            work_ex: self.userDetails.work_ex,
            gender: self.userDetails.gender,
            // TODO: Check if we still getting these details outside of work_ex
            location: self.userDetails.location,
            total_experience: self.userDetails.total_experience,
          };
          self.salaryDetailsAvailable =
            self.userDetails.salary &&
            Array.isArray(self.userDetails.salary) &&
            self.userDetails.salary.length;
          self.basicDetailsAvailable =
            self.userDetails.work_ex &&
            Array.isArray(self.userDetails.work_ex) &&
            self.userDetails.work_ex.length;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getInsightsHistogram() {
      api
        .get(`api/v1/insights/uncovor_histogram`)
        .then((response) => {
          this.histogramData = response.data.data.histogram;
          this.histogramDataLoaded = true;
          this.chartData.labels = this.histogramData.bins;
          this.chartData.dataInput = this.histogramData.values;
          this.chartData.median = closestIndex(
            this.histogramData.median,
            this.chartData.labels
          );
          this.chartData.threequarters.scale = closestIndex(
            this.histogramData.threequarters,
            this.chartData.labels
          );
          this.chartData.threequarters.value = `${this.histogramData.threequarters}%`;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getInsightsHistogramShow() {
      api
        .get(`api/v1/insights/uncovor_histogram/show`)
        .then((response) => {
          this.histogramShow = response.data.data || {};

          // TODO - Temp
          // this.histogramShow.show_histogram = true;
          if (this.histogramShow.show_histogram) {
            this.getInsightsHistogram();
          }
        })
        .catch(console.warn);
    },
    // getInsightsHome() {
    //   api
    //     .get(`api/v1/insights/home`)
    //     .then((response) => {
    //       const data = response.data.data;
    //       if (data && typeof data === "string") {
    //         this.insightsHtml = data;
    //       } else if (data && Array.isArray(data) && data.length) {
    //         this.insightsHtml = data[0];
    //       }
    //     })
    //     .catch(console.warn);
    // },
    onInvite() {
      const refUrl =
        this.userDetails.referral_code &&
        `https://uncovor.com/#/?ref=${this.userDetails.referral_code}`;
      if (refUrl) {
        const text = `Know your self worth: ${encodeURIComponent(refUrl)}`;
        const whatsappUrl = `https://wa.me/?text=${text}`;
        window.open(whatsappUrl, "_blank");
      }
    },
  },
  computed: {
    invitePercentage() {
      const ref_completed = this.histogramShow.referrals_completed || 0;
      const ref_required = this.histogramShow.referrals_required || 0;
      if (ref_completed > ref_required) {
        return 100;
      } else {
        return (
        ((this.histogramShow.referrals_completed || 0) /
            (this.histogramShow.referrals_required || 0)) *
          100
        ).toFixed(3);
      }
    },
  },
  created() {
    this.getInsightsHistogramShow();
    this.getUserDetails();
    // this.getInsightsHome();
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/mixins';
::v-deep {
  .left_part {
    flex-direction: column;
    justify-content: space-between;
    width: 30%;

    .left_part--title {
      margin: 0 33px 0 33px;
      height: 40px;
    }
    .left_part--image {
      height: calc(100% - 40px);
      flex-direction: column;
      justify-content: space-between;

      p {
        line-height: 20px;
        margin: 0 33px 20px 39px;
      }

      img {
        margin-bottom: -2px;
      }
    }
  }

  .modal_content_wrapper {
    max-width: 80vw !important;
    padding-top: 2% !important;
    overflow-y: scroll;

    .default_content {
      width: 70% !important;
    }

    .form-fields {
      padding: 0 !important;
    }
  }

  .edit-salary-modal {
    .left_part--title {
      font-size: 28px !important;
      margin: 0 33px 0px 33px !important;
    }
  }

  @media (max-width: 768px) {
    .modal_content_wrapper {
      max-width: 100% !important;

      .modal_content .left_part {
        .left_part--title {
          margin: 7px 33px 0px 33px !important;
        }

        .left_part--image {
          p {
            margin: 0 33px 20px 35px;
          }

          img {
            max-height: 300px;
            max-width: fit-content;
          }
        }
      }
    }
  }
}
.userProfile {
  background: var(--bg);
  @include respond(sm) {
    .userTitle {
      margin: 0 20px 24px 20px !important; // SCREEN-PADDING
    }
  }

  .userTitle,
  .userTitle span {
    font-family: BrotherLine !important;
    position: relative;
    font-size: 94px;
    text-align: center;
    font-weight: 400;
    color: white;
    margin-bottom: 80px;
  }

  .userTitle span {
    color: var(--bg-accent);
  }

  .completeProfile {
    margin: 0 40px;
    @include respond(sm) {
      margin: 0 20px; // SCREEN-PADDING
    }
    h4 {
      color: white;
      font-size: 24px;
      text-align: left;
      margin-bottom: 20px;
    }

    .accordion {
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      background: #f1f2e4;

      .accordion-head {
        display: flex;
        justify-content: space-between;
        padding: 40px 0 80px 0;

        .profileStatus {
          width: 60%;
          display: flex;
          margin-left: 6%;
          span {
            padding-right: 20%;
            font-size: 20px;
            font-weight: 600;
            position: relative;

            &::before {
              content: "";
              display: block;
              width: 28px;
              height: 28px;
              background: #d4d4d4;
              border-radius: 100%;
              position: absolute;
              bottom: -48px;
              z-index: 999;
              border: 4px solid #f0f2e3;
            }

            &:after {
              content: "";
              width: 100%;
              height: 3px;
              background: #a7a99f;
              position: absolute;
              bottom: -36px;
              left: 0;
            }
          }

          .status-seasoned {
            &::before {
              left: 26px;
            }

            &:after {
              width: calc(100% + 32px);
              left: 0px;
            }
          }

          .status-ninja {
            padding-right: 0;
            &::before {
              right: 0;
            }
          }

          &.rookie {
            .status-rookie {
              &::before {
                background: var(--bg-accent);
              }
            }
          }

          &.seasoned {
            .status-rookie {
              &::before {
                background: var(--bg-accent);
              }

              &::after {
                z-index: 11;
                left: 32px;
                background: var(--bg-accent);
              }
            }

            .status-seasoned {
              &::before {
                background: var(--bg-accent);
              }
            }
          }

          &.ninja {
            .status-rookie {
              &::before {
                background: var(--bg-accent);
              }

              &::after {
                z-index: 11;
                left: 32px;
                background: var(--bg-accent);
              }
            }

            .status-seasoned {
              &::before {
                background: var(--bg-accent);
              }

              &::after {
                z-index: 11;
                background: var(--bg-accent);
              }
            }

            .status-ninja {
              &::before {
                background: var(--bg-accent);
              }
            }
          }
        }

        .statusDays {
          align-items: center;
          border-radius: 8px;
          margin-bottom: -38px;
          margin-top: 6px;
          position: relative;
          display: flex;
          height: 53px;
          padding: 0 48px;
          @include respond(sm) {
            width: 100%;
            padding: 0 48px !important;
            margin-left: 0 !important;
          }

          .time-icon {
            background-repeat: no-repeat;
            height: 18px;
            width: 18px;
            margin-right: 10px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='17' viewBox='0 0 17 17' fill='none'%3E%3Cpath d='M8.5 16C12.6421 16 16 12.6421 16 8.5C16 4.35786 12.6421 1 8.5 1C4.35786 1 1 4.35786 1 8.5C1 12.6421 4.35786 16 8.5 16Z' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M10.75 11.5039L8.9395 9.69341C8.65818 9.41217 8.50008 9.0307 8.5 8.63291V4.00391' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
          }
        }

        .accordion-dropdown {
          display: block;
          height: 100%;
          padding: 21px 24px;
          background: #dbdeba;
          align-items: center;
          margin-right: 6%;
          margin-left: -6%;
          cursor: pointer;
          border-radius: 8px;
          margin-bottom: -38px;
          margin-top: 6px;
          position: relative;
          &:after {
            content: "";
            width: 16px;
            height: 10px;
            display: block;
            transition: 0.4s ease;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='10' viewBox='0 0 16 10' fill='none'%3E%3Cpath opacity='0.7' d='M14.0469 1.19217L8 7.19272L1.95309 1.19217C1.73853 0.977654 1.44753 0.857141 1.14409 0.857141C0.840662 0.857141 0.549657 0.977654 0.335098 1.19217C0.120538 1.40669 0 1.69763 0 2.00101C0 2.30438 0.120538 2.59532 0.335098 2.80984L7.191 9.66437C7.29715 9.77076 7.42324 9.85517 7.56206 9.91276C7.70088 9.97036 7.8497 10 8 10C8.1503 10 8.29912 9.97036 8.43794 9.91276C8.57676 9.85517 8.70286 9.77076 8.809 9.66437L15.6649 2.80984C15.7711 2.70362 15.8554 2.57752 15.9129 2.43874C15.9704 2.29996 16 2.15122 16 2.00101C16 1.85079 15.9704 1.70205 15.9129 1.56327C15.8554 1.42449 15.7711 1.29839 15.6649 1.19217C15.5587 1.08595 15.4325 1.0017 15.2937 0.944212C15.1549 0.886727 15.0062 0.857141 14.8559 0.857141C14.7057 0.857141 14.5569 0.886727 14.4181 0.944212C14.2793 1.0017 14.1531 1.08595 14.0469 1.19217Z' fill='black'/%3E%3C/svg%3E");
          }
          @include respond(sm) {
            right: 0 !important;
          }
        }
      }

      .accordion-body {
        background: white;
        display: none;

        border-radius: 0 0 20px 20px;

        .list {
          display: flex;
          flex-direction: column;
          padding: 40px 80px;

          .list-item {
            display: flex;
            width: 100%;
            justify-content: space-between;

            &:first-child {
              margin-bottom: 24px;
              padding-bottom: 24px;
              border-bottom: 1px solid #d9d9d9;
            }

            .list-logo {
              width: 72px;
            }

            .list-description {
              width: 74%;
              text-align: left;
              display: flex;
              flex-direction: column;
              justify-content: center;
              @include respond(sm) {
                &.pl-28 {
                  padding-left: 0;
                }
              }

              h4 {
                color: black;
                margin-bottom: 8px;
              }
            }

            .list-cta {
              display: flex;
              align-items: center;

              .btn {
                background: var(--bg);
                color: white;
                padding: 16px;
                line-height: 125%;
                border-radius: 8px;
                width: 240px;
                cursor: pointer;

                &.disabled {
                  opacity: 0.7;
                  cursor: no-drop;
                }
              }
              &__container {
                width: 240px;
                @include respond(sm) {
                  margin-top: 20px;
                }
                @include respond(md) {
                  width: 100%;
                }
              }
            }
            &.salary-slip .list-cta .btn {
              @include respond(sm) {
                margin-top: 20px;
              }
            }
          }
        }
      }

      &.open {
        .accordion-dropdown:after {
          transform: rotate(180deg);
          transition: 0.4s ease;
        }

        .accordion-body {
          display: block;
        }
      }
    }
    &__did_you_know {
      margin: 30px 80px 40px 80px;
      border: 1px solid #c39f58;
      border-radius: 16px;
      padding: 30px;
      text-align: left;
      position: relative;
      background: linear-gradient(270deg, #f5e9d1, transparent);
      @include respond(md) {
        margin: 30px 0 0 0;
      }
      &--feather {
        position: absolute;
        right: -14px;
        bottom: 0;
      }
      p {
        margin-top: 10px;
      }
    }
  }

  .basicDetails {
    margin: 60px 40px;
    @include respond(sm) {
      margin: 60px 20px; // SCREEN-PADDING
    }

    h4 {
      color: white;
      font-size: 24px;
      text-align: left;
      margin-bottom: 20px;
    }

    .container-head {
        padding: 30px 80px 40px 80px !important;
        position: relative;

      @include respond(lg) {
        padding: 8% 10% 120px 10% !important;
      }
      @include respond(sm) {
        padding: 8% 10% 30% 10% !important;
      }
      .edit-section {
        @include respond(lg) {
          position: absolute;
          width: 50%;
          margin-top: 240px;
          margin-left: 151px;
        }
        @include respond(md) {
          margin-left: 0;
        }
      }
    }
    .btn {
      &.disabled {
        opacity: 0.5;
        cursor: no-drop;
        pointer-events: none;
      }
    }
  }

  .invite-btn {
    &__progress-bar {
      width: 54%;
      background-color: #d4d7ac;
      border-radius: 16px;
      height: 15px;
      @include respond(md) {
        width: 75%;
      }
    }

    &__progress-bar-fill {
      display: block;
      height: 15px;
      background-color: #8f981f;
      border-radius: 16px;
      transition: width 500ms ease-in-out;
    }

    &__progress {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 15px;
    }
    &__invited {
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  .container {
    background: #ffffff;
    border-radius: 20px;

    // TODO - fix this, not able to find max-width media query for this
    max-width: 100%;
    width: 0 40px;

    .container-head {
      background: #f1f2e4;
      padding: 40px 0 0 80px;
      display: flex;
      align-items: center;
      border-radius: 20px 20px 0 0;

      .container-head-left {
        text-align: left;
        width: 75%;
        padding-bottom: 40px;

        h4 {
          font-size: 28px;
          font-weight: bold;
          color: black;
          margin-bottom: 12px;
        }

        p {
          opacity: 0.5;
          color: black;
        }

        .invite-friends {
          background: url("../../assets/images/profile/invite_blurred.png");
          height: 240px;
          width: 70%;
          background-size: contain;
          background-repeat: no-repeat;
          margin-top: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          @include respond(sm) {
            height: 180px;
          }

          .btn-container {
            width: 260px;
            text-align: center;
            padding: 10px;
            background-color: white;
            box-shadow: 0px 0px 4px #8d8d8d;
            border-radius: 10px;
            // .btn {
            //   border-radius: 8px;
            //   background: black;
            //   color: white;
            //   font-weight: 600;
            //   width: fit-content;
            //   padding: 12px 22px;
            //   cursor: pointer;
            // }
          }
        }
      }

      .container-head-right {
        position: relative;
        width: 25%;

        // img {
        //   position: absolute;
        //   right: 0;
        //   top: -129px;
        // }

        .waitlist {
          border-radius: 16px;
          padding: 30px;
          text-align: left;
          position: relative;
          overflow: hidden;
          background: #dee0bd;

          &:after {
            content: "";
            width: 68px;
            overflow: hidden;
            background-repeat: no-repeat;
            position: absolute;

            background: url("../../assets/images/profile/invite_bg.png");
            height: 120px;
            right: 0;
            bottom: 0;
            background-position: center;
            background-size: cover;
          }

          h4 {
            color: black;
            font-size: 34px;
            font-weight: bold;
            margin-bottom: 0;
          }

          span {
            font-size: 20px;
            font-weight: 600;
            margin: 6px 0 12px;
            display: block;
          }

          p {
            opacity: 0.8;
            line-height: 125%;
            width: 75%;
          }

          &__btn-container {
            padding-top: 15px;
            :deep(.my_button_container button) {
              padding: 15px 10px;
            }
          }
        }
      }

      .profile-photo {
        width: 120px;
        position: relative;
        line-height: 1;
        font-size: 0;
        border-radius: 100%;
        overflow: hidden;
        z-index: 1;
        &:hover {
          .edit {
            opacity: 1;
          }
        }
        &:before {
          position: absolute;
          content: "";
          width: 120px;
          height: 120px;
          bottom: 0;
          left: 0;
          right: 0;
          background: #fddb3a;
          border-radius: 100%;
          z-index: -1;
          @include respond(md) {
            width: 100px;
            height: 78px;
          }
        }
        .edit {
          top: calc(50% - 21px);
          position: absolute;
          width: 42px;
          height: 42px;
          display: block;
          background: #f1f2e4;
          border-radius: 100%;
          bottom: auto;
          left: calc(50% - 21px);
          display: flex;
          align-items: center;
          justify-content: center;
          color: #000000;
          font-size: 16px;
          opacity: 0;
        }
        img {
          width: 120px;
        }
      }

      .profile-details {
        padding-left: 32px;
        padding-top: 16px;
        width: 70%;
        text-align: left;
        h4 {
          font-size: 40px;
          font-weight: 700;
          color: black;
          margin-bottom: 0;
        }
        .yoe {
          opacity: 0.5;
          font-size: 24px;
          margin-bottom: 10px;
          display: block;
        }
        .linkedin {
          display: block;
          text-align: left;
          padding-left: 26px;
          font-size: 20px;
          position: relative;
          text-decoration: none;
          color: #000000bf;
          width: fit-content;
          cursor: pointer !important;

          &:before {
            background: url("../../assets/images/waitlist/linkedin.svg");
            content: "";
            display: block;
            width: 22px;
            height: 22px;
            top: 2px;
            position: absolute;
            left: 0;
            background-size: cover;
            border-radius: 100%;
          }
        }
      }

      .edit-section__button {
        display: flex;
        min-width: 200px;
        justify-content: center;
        svg {
          margin-right: 5px;
        }
      }
    }

    .container-body {
      @include respond(lg) {
        flex-direction: column;
      }
      .container-body-left {
        width: 70%;
        @include respond(lg) {
          width: 100%;
        }
      }

      .container-body-right {
        padding-right: 4%;
        width: 28%;
        margin-top: 4%;
        &.waitlist_position_widget {
          @include respond(lg) {
            width: 100%;
            padding: 0 80px 40px;
            margin-top: 0;
          }
          @include respond(md) {
            width: 100%;
            padding: 0 10% 40px;
            margin-top: 0;
          }
        }

        .waitlist {
          border: 1px solid #c39f58;
          border-radius: 16px;
          padding: 30px;
          text-align: left;
          position: relative;
          overflow: hidden;
          background: linear-gradient(270deg, #f5e9d1, transparent);

          &:after {
            content: "";
            width: 68px;
            overflow: hidden;
            background-repeat: no-repeat;
            position: absolute;

            background: url("../../assets/images/profile/invite_bg.png");
            height: 120px;
            right: 0;
            bottom: 0;
            background-position: center;
            background-size: cover;
          }

          h4 {
            color: black;
            font-size: 34px;
            font-weight: bold;
            margin-bottom: 0;
          }

          span {
            font-size: 20px;
            font-weight: 600;
            margin: 6px 0 12px;
            display: block;
          }

          p {
            opacity: 0.8;
            line-height: 125%;
            width: 75%;
          }

          &__btn-container {
            padding-top: 15px;
            :deep(.my_button_container button) {
              padding: 15px 10px;
            }
          }
        }
      }

      display: flex;
      .education-details {
        margin-bottom: -24px;
        h4 {
          padding: 40px 80px 0;
          margin-bottom: 0;
          color: black;
          opacity: 0.5;
        }
      }
    }

    .list {
      display: flex;
      flex-direction: column;
      padding: 26px 80px;

      .list-item {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 20px;

        // &:first-child {
        //   margin-bottom: 24px;
        //   padding-bottom: 24px;
        // }

        .list-logo {
          width: 72px;
        }

        .list-description {
          width: calc(100% - 72px);
          padding-left: 28px;
          text-align: left;
          display: flex;
          flex-direction: column;
          justify-content: center;

          h4 {
            color: black;
            margin-bottom: 8px;
            padding: 0;
            opacity: 1;
            font-weight: 500;
          }
        }
      }
    }
  }
  .we-details {
    h4 {
      padding: 40px 80px 0;
      margin-bottom: 0;
      color: black;
      opacity: 0.5;
    }

    .list-item {
      margin-bottom: 24px;
    }

    .list-logo img {
      width: 72px;
      border-radius: 12px;
    }
  }

  .salaryDetails {
    margin: 0 40px;
    @include respond(sm) {
      margin: 0 20px; // SCREEN-PADDING
    }
    h4 {
      color: white;
      font-size: 24px;
      text-align: left;
      margin-bottom: 20px;
    }
    .container-head {
      align-items: end;
      @include respond(md) {
        padding-bottom: 0 !important;
      }
      .container-head-left {
        @include respond(md) {
          // padding-bottom: 0 !important; // TODO check side effect
        }
        @include respond(lg) {
          &.histo-loaded {
            width: 100%;
          }
        }
      }
      .container-head-right {
        position: relative;
        width: 25%;
        &.histo-loaded .edit-section__button {
          min-width: 0;
        }
        @include respond(md) {
          display: none;
          &.histo-loaded {
            .waitlist {
              span {
                font-size: 16px;
              }
              p {
                font-size: 14px;
              }
            }
          }
        }
        @include respond(lg) {
          &.histo-loaded {
            display: contents;
            .edit-section {
              margin-bottom: 30px;
            }
          }
        }

        img {
          position: absolute;
          right: 0;
          width: 208px;
          bottom: -46px;
          @include respond(lg) {
            width: 152px;
            bottom: -33px;
          }
        }
      }
    }
    .container-head.chart-head {
      padding: 50px 80px 40px 80px;
      @include respond(md) {
        padding: 10% !important;
      }
      @include respond(lg) {
        display: block;
        width: 100%;
      }
    }
    .container-body {
      text-align: left;
      flex-direction: column;
      padding: 32px 0 26px;
      position: relative;

      h4 {
        padding-left: 80px;
        font-size: 24px;
        color: #444;
        margin-top: 20px;
      }

      .list-group {
        width: 100%;

        .list {
          flex-direction: row;

          .list-item {
            flex-direction: column;
            width: 25%;

            @include respond(lg) {
              width: 100%;
            }

            span {
              font-size: 26px;
              font-weight: bold;
            }

            p {
              color: black;
              opacity: 0.5;
              font-weight: 500;
              font-size: 18px;
              margin-top: 6px;
            }
          }
        }
      }
    }

    .edit-salary-btn {
      position: absolute;
      bottom: 52px;
      right: 80px;
      background: #0000001f;
      padding: 14px 24px;
      border-radius: 60px;
      font-weight: 600;
      color: #00000099;
      cursor: pointer;
      pointer-events: none;
    }
    &__chart-container {
      width: 90%;
      margin-top: 50px;
      @include respond(lg) {
        width: 100%;
      }
    }
    .edit-section {
      position: inherit;
      margin-bottom: 20%;
    }
  }

  .placeholder {
    color: white;
    margin: 12% 0;
    font-size: 42px;
  }
}

@media (max-width: 768px) {
  .userProfile {
    padding: 0;

    .userTitle {
      // padding: 0 8%;
      font-size: 42px !important;
      text-align: left !important;
      margin: 0 40px 24px 40px;
      span {
        font-size: 42px !important;
        text-align: left !important;
      }
    }

    .completeProfile {
      // padding: 0 8%;

      h4 {
        font-size: 18px;
      }

      .accordion {
        .accordion-head {
          flex-direction: column;
          padding: 40px 0 40px 0;
          position: relative;

          .profileStatus {
            width: 100%;
            margin-left: 0;
            justify-content: center;
            span {
              font-size: 14px;

              &:before {
                // width: 14px;
                // height: 14px;
                bottom: -37px;
              }

              &:after {
                height: 2px;
                bottom: -24px;
              }
            }

            .status-seasoned::before {
              left: 18px;
            }
          }

          .statusDays {
            width: 55% !important;
            margin: 54px 0% 0 10% !important;
            padding: 16px 30px 16px 50px;
          }

          .accordion-dropdown {
            // width: 20%;
            position: absolute;
            right: 38px;
            bottom: 40px;
            height: 48px;
            margin-bottom: 0;
            margin-right: 10%;

            &:after {
              margin-left: 2px;
              margin-top: -1px;
            }
          }
        }

        .accordion-body {
          padding: 10%;

          .list {
            padding: 0;

            .list-item {
              position: relative;
              flex-direction: column;

              .list-logo {
                width: 42px;

                img {
                  width: 100%;
                }
              }

              .list-description {
                position: absolute;
                margin-left: 60px;

                h4 {
                  font-size: 16px;
                  font-weight: 500;
                  line-height: 18px;
                }

                span {
                  font-size: 14px;
                  line-height: 18px;
                }
              }

              .list-cta {
                margin-top: 68px;
                width: 100%;

                .btn {
                  width: 100%;
                  padding: 14px 16px;
                }
              }

              &.salary-slip {
                .list-cta {
                  margin-top: 50px;
                }
              }
            }
          }
        }
      }
    }

    .basicDetails {
      // padding: 0 8%;

      h4 {
        font-size: 18px;
      }

      .container-head {
        // padding: 10% 10% 100px 10% !important;
        position: relative;

        .profile-photo {
          width: 64px;

          img {
            width: 64px;
          }
        }

        .profile-details {
          padding-left: 10px;
          padding-top: 0;
          .yoe {
            font-size: 12px;
            line-height: 14px;
          }

          h4 {
            font-size: 16px;
            margin-bottom: 6px;
          }

          .linkedin {
            font-size: 12px;
            padding-left: 18px;
            &:before {
              width: 16px;
              height: 16px;
              // top: -3px;
            }
          }
        }

        .edit-section {
          position: absolute;
          width: 80%;
          margin-top: 190px;

          // &__button {
          //   width: 100%;
          //   padding: 14px 16px 14px 32px;
          // }
        }
      }

      .container-body {
        flex-direction: column;

        .container-body-left {
          width: 100%;

          .education-details {
            padding: 40px 10% 0;
            margin-bottom: 0;

            h4 {
              padding: 0px 0 20px;
            }

            .list {
              padding: 0;

              .list-item {
                justify-content: flex-start;
                margin-bottom: 12px;

                .list-description {
                  width: calc(100% - 42px);
                  padding-left: 20px;

                  h4 {
                    padding-bottom: 0;
                    font-size: 14px;
                    line-height: 18px;
                  }

                  span {
                    font-size: 12px;
                    line-height: 16px;
                  }
                }
              }

              .list-logo {
                width: 42px;

                img {
                  width: 42px;
                }
              }
            }
          }

          .we-details {
            padding: 10px 10% 0;

            h4 {
              padding: 10px 0 20px;
            }

            .list {
              padding: 0;

              .list-item {
                justify-content: flex-start;
                margin-bottom: 12px;

                .list-description {
                  width: calc(100% - 42px);
                  padding-left: 20px;

                  h4 {
                    padding-top: 0;
                    padding-bottom: 0;
                    font-size: 14px;
                    line-height: 18px;
                  }

                  span {
                    font-size: 12px;
                    line-height: 16px;
                  }
                }
              }
            }

            .list-logo {
              width: 42px;

              img {
                width: 42px;
              }
            }
          }
        }

        .container-body-right {
          width: 100%;
          padding: 10%;
          margin-top: 0;

          .waitlist {
            h4 {
              font-size: 28px;
            }

            span {
              font-size: 16px;
            }

            p {
              font-size: 14px;
            }
          }
        }
      }
    }

    .salaryDetails {
      // padding: 0 8%;

      h4 {
        font-size: 18px;
      }

      .container-head {
        padding: 10%;
        flex-direction: column;

        .container-head-left {
          width: 100%;

          h4 {
            font-size: 18px;
          }

          p {
            font-size: 14px;
            line-height: 16px;
          }

          .invite-friends {
            width: 100%;
            align-items: flex-start;

            .btn {
              font-size: 14px;
            }
          }
        }

        .container-head-right {
          height: 100px;
          width: 100%;

          img {
            width: 100%;
            height: auto;
          }
        }
      }

      .container-body {
        padding: 32px 0 !important;
        h4 {
          padding-left: 10%;
          font-size: 18px;
        }

        .list {
          padding: 0 10%;
          flex-direction: column !important;

          .list-item {
            width: 100% !important;
            margin-bottom: 25px;

            span {
              font-size: 20px !important;
            }

            p {
              font-size: 14px !important;
            }
          }
        }

        .edit-salary-btn {
          bottom: 24px;
          left: 10%;
          right: auto;
        }
      }
    }
  }
  @include respond(sm) {
      .userProfile .completeProfile .accordion .accordion-head .statusDays {
      margin-left: 0 !important;
    }
  }
}

// Utility classes
.pl-28 {
  padding-left: 28px;
}
.pr-5 {
  padding-right: 5px;
}
</style>
