<template>
  <div ref="refModal" id="modal-container">
    <div class="modal-background" @click.prevent="submitIfOptionIsSelected">
      <div class="modal">
        <div class="main-wrapper">
          <div class="content">
            <div class="top">
              <div class="title">We’re sorry to see you go so soon!</div>
              <div class="info">
                We would like one last favor from you as it would really help us fine-tune our offering better. What describes you the best?
              </div>
            </div>
            <form ref="refUserPersonaForm" id="user-persona-form" class="bottom" @submit.prevent="submitSelfDescription" method="post">
              <label 
                v-for="describingOption in describingOptionsData"
                :for="describingOption.user_persona"
                :class="`user-describing-option ${describingOption.label === selectedOption?.label ? 'selected' : ''}`"
                :key="describingOption.label"
                @click="handleOptionSelect(describingOption)"
              >
                <img :src="describingOption.image" :alt="describingOption.label" />
                <span class="option-label">{{ describingOption.label }}</span>
                <input class="option-input" type="radio" name="user_persona" :checked="describingOption.label === selectedOption?.label" :id="describingOption.user_persona" :value="describingOption.user_persona"/>
              </label>
              <div v-if="showCustomDescriptionForm" class="custom-answer">
                <label for="custom-describe">What describes you the best?</label>
                <textarea name="description" id="custom-describe" cols="30" rows="10" placeholder="Start typing here..."></textarea>
                <Button :loading="isLoading" type="primary" buttonActionType="submit" @click.prevent="$refs.refUserPersonaForm.dispatchEvent(pseudoSubmitEvent)">Submit</Button>
              </div>
              <div v-else class="describe-manually-cta-wrapper" @click="showCustomDescriptionForm = true">
                <span class="describe-manually-cta">None of these categories describe me</span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from "../services/http-common";
import Button from './button.vue'

export default {
  data: function() {
    return {
      isLoading: false,
      showCustomDescriptionForm: false,
      selectedOption: null,
      refUserPersonaForm: null,
      pseudoSubmitEvent: new Event('submit'),
      describingOptionsData: [
        { image: require("../assets/images/user-persona/Image1.png"),
          label: "You’re bored and uncovering salaries seemed fun",
          user_persona: "BORED_BROWSERS",
        },
        { image: require("../assets/images/user-persona/Image2.png"),
          label: "You’re not actively looking out but curious to uncover salaries",
          user_persona: "PASSIVE_CURIOUS",
        },
        { image: require("../assets/images/user-persona/Image3.png"),
          label: "You’re not actively looking out but competitively driven to uncover salaries",
          user_persona: "ACTIVE_CURIOUS",
        },
        { image: require("../assets/images/user-persona/Image4.png"),
          label: "You’re frustrated with your company and are just sensing the market",
          user_persona: "PASSIVE_HUNTERS",
        },
        { image: require("../assets/images/user-persona/Image5.png"),
          label: "You are actively looking out for jobs and this data would help",
          user_persona: "ACTIVE_HUNTERS",
        },
        { image: require("../assets/images/user-persona/Image6.png"),
          label: "You have a job offer and are looking to negotiate the best offer",
          user_persona: "NEGOTIATOR",
        },
      ]
    };
  },
  components: {
    Button
  },
  computed: {
    isDefaultSlotExists() {
      return !!this.$slots.default;
    },
  },
  methods: {
    handleOptionSelect: function(describingOption){      
      this.showCustomDescriptionForm = true 
      this.selectedOption = describingOption
    },  
    submitSelfDescription: async function (e) {
      try {
        this.isLoading = true
        await api.post('api/v1/user/user_persona',
          new FormData(e.target),
          {
            headers: {
              'accept': 'application/json',
              'Content-Type': 'application/json'
            }
          }
        )
        this.$refs.refModal.classList.add('out')
        this.$emit('userPersonaSubmit')
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    submitIfOptionIsSelected: function(e){
      if(this.selectedOption && e.target.classList.contains('modal')){
        document.querySelector('#user-persona-form').dispatchEvent(this.pseudoSubmitEvent)
      }
    }
  },
  created() {},
};
</script>

<style lang="scss">
@import "../assets/scss/modal";
#modal-container {
  .main-wrapper {
    position: relative;
    background: #F1F2E4;
    border-radius: 20px;
    width: 100%;
    max-width: 900px;
    max-height: 90vh;
    margin: 5vh auto;
    padding: 40px;
    overflow: auto;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
    .content {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      height: 100%;
      font-family: Oxanium;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      color: #333;
      text-align: left;
      z-index: 2;
      .top {
        width: 100%;
        text-align: center;
        margin-bottom: 46px;
        .title {
          font-size: 32px;
          font-weight: 700;
          line-height: 40px;
          color: #000;
          margin-bottom: 12px;
        }
        .info {
          font-weight: 500;
          line-height: 24px;
          max-width: 645px;
          margin: auto;
        }
      }
      .bottom {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        .user-describing-option{
          display: flex;
          position: relative;
          overflow: visible;
          min-width: 251px;
          height: 123px;
          align-items: center;
          background-color: #FFFFFF;
          border: 1px solid #DDDDDD;
          border-radius: 16px;
          cursor: pointer;
          transition: all .1s ease-in-out;
          &:nth-of-type(1),&:nth-of-type(2),&:nth-of-type(3){
            margin-bottom: 53px;
          }
          &:hover{
            background-color: #f8f8f8;
            box-shadow: 0px 0px 10px .1px rgba(#333, .1);
          }
          &.selected{
            background-color: #dadada;
            box-shadow: 0px 0px 10px .1px rgba(#333, .1);
          }
          img{
            align-self: flex-end;
          }
          .option-label{
            font-size: 14px;
            max-width: 140px;
            padding-left: 8px;
          }
          .option-input{
            visibility: hidden;
            width: 0;
            height: 0;
          }
          ~ .option-input:checked ::after{
            content: '';
            width: 100%;
            height: 100%;
            background-color: #000000;
          }
        }
        .custom-answer{
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-top: 40px;
          label{
            font-weight: 500;
            line-height: 24px;
            margin-bottom: 16px;
          }
          textarea{
            background: #FFFFFF;
            border: 1px solid #DDDDDD;
            border-radius: 16px;
            height: 120px;
            padding: 24px;
            margin-bottom: 24px;
            line-height: 22px;
            color: rgba(#333, .4);
            &::placeholder{
              color: rgba(#333, .4);
            }
            &:focus{
              outline: none;
              border-color: rgba(#333, .4);
            }
          }
          .my_button_container{
            align-self: flex-end;
            button{
              width: 160px;
              cursor: pointer;
              background: #000000;
              border-radius: 8px;
              text-align: center;
              color: #FFFFFF;
              border: none;
              transition: transform .2s ease-in-out, opacity .2s ease-in-out;
              &:active{
                opacity: .8;
                transform: scale(.98);
              }
            }
          }
        }
        .describe-manually-cta-wrapper{
          margin-top: 22px;
          text-align: center;
          width: 100%;
          .describe-manually-cta {
            cursor: pointer;
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            position: relative;
            &::after {
              content: '';
              position: absolute;
              bottom: -5px;
              left: 0;
              width: 100%;
              opacity: 0.4;
              border: 1px dashed #000000;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  #modal-container{
    .main-wrapper {
      padding: 40px 20px;
      width: calc(100% - 40px);
      margin: 10vh 20px;
      height:  80vh;
      .content{
        .bottom{
          padding-bottom: 30px;
          .user-describing-option{
            width: 100%;
            padding-right: 10px;
            margin-bottom: 30px!important;
            .option-label{
              max-width: 100%;
            }
          }
          .custom-answer{
            margin-top: 0px;
          }
          .describe-manually-cta-wrapper{
            margin-top: 0px;
          }
        }
      }
    }
  }
}
</style>
