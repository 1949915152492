<template>
  <div class="home_sections">
    <div class="section_wrapper">
      <div
        id="how-it-works"
        class="section_info --leftText --how-it-works has-shapes"
      >
        <div class="content_wrapper">
          <div class="title"><span>How will</span> Uncovor work?</div>
          <div class="grid">
            <div class="grid-item">
              <div class="grid-item-top">
                <img src="../../assets/images/home/how-it-works/1.png" />
              </div>
              <div class="grid-item-bottom">Create your profile</div>
            </div>
            <div class="grid-item">
              <div class="grid-item-top">
                <img src="../../assets/images/home/how-it-works/2.png" />
              </div>
              <div class="grid-item-bottom">Upload Offer Letter</div>
            </div>
            <div class="grid-item">
              <div class="grid-item-top">
                <img src="../../assets/images/home/how-it-works/3.png" />
              </div>
              <div class="grid-item-bottom">Get Offer Verified</div>
            </div>
            <div class="grid-item">
              <div class="grid-item-top">
                <img src="../../assets/images/home/how-it-works/4.png" />
              </div>
              <div class="grid-item-bottom">Uncovor Real Salaries!</div>
            </div>
          </div>
        </div>
      </div>
      <ShapeF1 class="svg-element bottom-shape priamry_color" />
    </div>
    <div class="section_wrapper">
      <div id="what-we-offer" class="section_info --leftText --uncover">
        <div class="content_wrapper">
          <div class="section_left" data-aos="fade-up">
            <div class="title"><span>Uncover</span> Verified Salaries</div>
            <div class="description">
              Don’t take ‘blind’ opinions or unreliable data points from anyone.
              Every salary at Uncovor is verified.
            </div>
          </div>
          <div class="section_right">
            <div class="image_wrapper">
              <div class="image_wrapper-bg" />
              <img
                data-aos="fade-up"
                loading="lazy"
                src="@/assets/images/home/gifs/reveal.gif"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section_wrapper" style="z-index: 10">
      <ShapeF2 class="svg-element top-shape" />
      <div class="section_info --rightText --anonymous has-shapes">
        <div class="content_wrapper">
          <div class="section_left">
            <div class="image_wrapper">
              <div class="image_wrapper-bg" />
              <img
                data-aos="fade-up"
                loading="lazy"
                src="@/assets/images/home/gifs/anonymous.gif"
              />
            </div>
          </div>
          <div class="section_right" data-aos="fade-up">
            <div class="title"><span>Anonymous</span>Browsing</div>
            <div class="description">
              At Uncovor your identity remains hidden. Neither your company nor
              your friends can find out it’s you.
            </div>
          </div>
        </div>
      </div>
      <ShapeF3 class="svg-element bottom-shape" />
    </div>
    <div class="section_wrapper">
      <div class="section_info --leftText --benchmark">
        <div class="content_wrapper">
          <div class="section_left" data-aos="fade-up">
            <div class="title"><span>Benchmark</span>against peers</div>
            <div class="description">
              Life is simpler when everyone knows everyone’s salary. Uncovor is
              the only place you can find a long-lost friend’s salary.
            </div>
          </div>
          <div class="section_right">
            <div class="image_wrapper">
              <div class="image_wrapper-bg" />
              <img
                data-aos="fade-up"
                loading="lazy"
                src="@/assets/images/home/gifs/benchmark.gif"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section_wrapper">
      <ShapeF4 class="svg-element top-shape" />
      <div class="section_info --rightText --discover">
        <div class="content_wrapper">
          <div class="section_left">
            <div class="image_wrapper">
              <div class="image_wrapper-bg" />
              <img
                data-aos="fade-up"
                loading="lazy"
                src="@/assets/images/home/gifs/discover.gif"
              />
            </div>
          </div>
          <div class="section_right" data-aos="fade-up">
            <div class="title"><span>Discover the</span>Best Pay Masters</div>
            <div class="description">
              To FAANG or not to FAANG, Uncovor helps unearth the hottest pay
              masters and wealth creators.
            </div>
          </div>
        </div>
      </div>
      <ShapeF5 class="svg-element bottom-shape" />
    </div>
    <div class="section_wrapper">
      <div class="section_info --leftText --negotiate">
        <div class="content_wrapper">
          <div class="section_left" data-aos="fade-up">
            <div class="title"><span>Negotiate,</span>Hikes Better</div>
            <div class="description">
              Information is power and discussion forums can’t teach you how to
              negotiate. Credible, crunched information at Uncovor can.
            </div>
          </div>
          <div class="section_right">
            <div class="image_wrapper">
              <div class="image_wrapper-bg" />
              <img
                data-aos="fade-up"
                loading="lazy"
                src="@/assets/images/home/gifs/negotiate.gif"
              />
            </div>
          </div>
        </div>
      </div>
      <ShapeF6 class="svg-element last-shape" />
    </div>
  </div>
</template>

<script>
import ShapeF1 from "../../assets/svg/section-shapes/F1.svg?inline";
import ShapeF2 from "../../assets/svg/section-shapes/F2.svg?inline";
import ShapeF3 from "../../assets/svg/section-shapes/F3.svg?inline";
import ShapeF4 from "../../assets/svg/section-shapes/F4.svg?inline";
import ShapeF5 from "../../assets/svg/section-shapes/F5.svg?inline";
import ShapeF6 from "../../assets/svg/section-shapes/F6.svg?inline";
import AOS from "aos";
export default {
  data() {
    return {};
  },
  components: {
    ShapeF1,
    ShapeF2,
    ShapeF3,
    ShapeF4,
    ShapeF5,
    ShapeF6,
  },
  computed: {},
  methods: {},
  mounted: () => {
    AOS.init({
      delay: 200,
      duration: 400, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: true,
    });
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/mixins';
.home_sections {
  position: relative;
  z-index: 3;
  .section_wrapper {
    position: relative;
    .section_info {
      &.has-shapes {
        padding-top: 12%;
        padding-bottom: 18%;
      }

      &.--uncover {
        .image_wrapper-bg {
          z-index: 1;
        }
        img {
          z-index: 2;
        }
      }
      &.--anonymous {
        padding-top: 22%;
        padding-bottom: 7%;
      }
      &.--benchmark {
        padding-top: 2%;
        padding-bottom: 0%;
      }
    }
    .svg-element {
      position: absolute;
      z-index: 1;
      width: 100%;
      left: 0;
      &.top-shape {
        top: -5px;
      }
      &.bottom-shape {
        bottom: -5px;
      }
      &.last-shape {
        bottom: -111px;
        @include respond(md) {
          bottom: -95px;
        }
        @include respond(sm) {
          bottom: -42px;
        }
      }
    }
  }
}

.section_info {
  padding: 6%;
  display: flex;
  align-items: center;
  text-align: left;
  color: var(--bg-accent-text);

  .title {
    font-weight: 1000;
    font-size: 58px !important;
    line-height: 130%;
    span {
      font-family: BrotherLine !important;
      font-weight: 400;
      font-size: 100px;
      display: block;
      margin-bottom: 18px;
    }
  }

  .description {
    font-weight: normal;
    font-size: 24px;
    line-height: 140%;
    max-width: 500px;
    margin-top: 28px;
  }

  .content_wrapper {
    display: flex;
    width: 100%;

    .section_right {
      width: 50%;
      display: flex;
      justify-content: flex-end;
    }

    .section_left {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-content: flex-start;
      justify-content: center;
    }
  }

  .image_wrapper {
    max-height: 540px;
    max-width: 540px;
    min-width: 340px;
    min-height: 340px;
    height: 100%;
    width: 100%;
    position: relative;

    .image_wrapper-bg {
      height: 100%;
      width: 100%;
      margin-top: -12%;
      // z-index: 1;
      background: #2b2b2b;
      position: absolute;
      border-radius: 100%;
    }

    img {
      width: 100%;
      // z-index: 2;
      position: relative;
    }
  }

  &.--leftText {
    background: var(--bg-accent);
    padding: 6% 6% 8% 6%;

    .content_wrapper {
      display: flex;
    }

    .section_right {
      .image_wrapper {
        img {
        }
      }
    }
  }

  &.--rightText {
    color: var(--bg-text);
    background: var(--bg-accent);
    position: relative;

    .content_wrapper {
      display: flex;
    }

    .image_wrapper {
      .image_wrapper-bg {
        background: #fddb3a;
      }

      img {
        margin-left: 4%;
      }
    }

    .section_left {
      width: 50%;
    }

    .section_right {
      padding-left: 12%;
      flex-direction: column;
      align-content: flex-start;
      justify-content: start;
    }

    .title,
    .description {
      color: #181815;
    }
  }

  &.--how-it-works {
    padding-top: 12%;
    padding-bottom: 18%;

    .content_wrapper {
      flex-direction: column;

      .title {
        text-align: center;
        margin-bottom: 60px;
      }
    }
    .grid {
      display: flex;

      .grid-item {
        width: 25%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .grid-item-top {
          position: relative;

          &:before {
            content: "1";
            display: flex;
            background: white;
            position: absolute;
            font-size: 24px;
            font-weight: 500;
            height: 50px;
            top: 24px;
            left: -20px;
            width: 50px;
            border: 8px solid var(--bg-accent);
            border-radius: 100%;
            align-items: center;
            justify-content: center;
          }
        }

        &:nth-child(2) {
          .grid-item-top:before {
            content: "2";
          }
        }
        &:nth-child(3) {
          .grid-item-top:before {
            content: "3";
          }
        }
        &:nth-child(4) {
          .grid-item-top:before {
            content: "4";
          }
        }

        .grid-item-bottom {
          font-size: 24px;

          margin-top: 24px;

          text-align: center;
          width: 50%;
          line-height: 30px;
          font-weight: 500;
        }

        img {
          height: 180px;
        }

        &:last-child {
          img {
            height: 220px;
            margin-top: -40px;
          }
        }

        &:nth-child(1) {
          .grid-item-bottom {
            margin-left: -14%;
          }
        }

        &:nth-child(2) {
          .grid-item-bottom {
            margin-left: -6%;
          }
        }

        &:nth-child(3) {
          .grid-item-bottom {
            margin-left: -6%;
          }
        }
      }
    }
  }

  &.--uncover {
    padding: 2% 6% 0% 6%;
    position: relative;

    .content_wrapper {
      // z-index: 999999;
    }

    .image_wrapper {
      img {
        margin-left: -15%;
        margin-bottom: 20px;
      }
    }

    &.section_info.--leftText {
      background: var(--bg);
    }

    .title {
      color: var(--bg-accent);
    }

    .description {
      color: white;
    }

    .image_wrapper-bg {
      background: #2b2b2b;
    }
  }

  &.--anonymous {
    padding-top: 18%;

    .content_wrapper {
      .section_right {
        margin-top: 4%;
      }
    }

    .image_wrapper {
      img {
        margin-left: 10%;
        width: 80%;
        margin-bottom: 4%;
      }
    }

    &:after,
    &:before {
      background: none;
    }
  }

  &.--discover {
    padding: 14% 6% 8% 6%;
    // margin-top: 100px;

    .content_wrapper {
      // z-index: 99999;
    }

    &:before {
      background: none;
    }

    &:after {
      background: none;
    }

    .section_right {
      padding-top: 10%;
    }

    .image_wrapper {
      img {
        margin-top: -22%;
      }
    }
  }

  &.--genuine {
    padding: 6% 6% 8% 6%;

    .section_left {
      margin-top: -15%;
    }

    .image_wrapper-bg {
      margin-top: 12%;
    }
  }

  &.--benchmark {
    padding: 6% 6% 0% 6%;
    position: relative;
    background: var(--bg);

    .section_left {
      margin-top: -10%;
    }

    .image_wrapper {
      margin-top: 10%;
      max-width: 840px;

      .image_wrapper-bg {
        width: 80%;
        margin-left: 14%;
        margin-top: -12%;
      }
      img {
        width: 120%;
        margin-left: -10%;
        margin-top: -10%;
      }
    }

    .title {
      color: var(--bg-accent);
    }

    .description {
      color: white;
    }
  }

  &.--negotiate {
    position: relative;
    // z-index: 999999;
    display: block !important;
    width: 100%;
    margin: 0;
    margin-bottom: -2%;
    padding: 6% 6% 0% 6%;
    background: var(--bg);

    .image_wrapper {
      // z-index: 9999;

      img {
        margin-top: -12%;
      }
    }

    .background_wrapper {
      background: var(--bg);
      padding: 0% 6% 0 6%;
      margin-bottom: -200px;
      // z-index: 9999;
    }

    .section_right {
      justify-content: center;
    }

    .section_left {
      margin-top: -10%;
    }

    .title {
      color: var(--bg-accent);
    }

    .description {
      color: white;
    }
  }

  .title {
    font-weight: 800;
    font-size: 45px;
    line-height: 60px;
  }
}

@media (max-width: 768px) {
  .content_wrapper {
    flex-direction: column;
  }

  .section_info {
    .title {
      font-size: 26px !important;
      line-height: 46px !important;

      span {
        font-size: 56px !important;
        margin-bottom: 0 !important;
      }
    }

    .description {
      font-size: 14px !important;
      margin-top: 8px !important;
    }

    .image_wrapper {
      img {
        margin-left: 0 !important;
      }
    }

    &.--anonymous {
      padding: 120px 10% 0 10% !important;

      .image_wrapper {
        margin: 60px -12px -20px -12px;

        img {
          margin-left: 4% !important;
        }
      }
    }

    &.--benchmark {
      // z-index: 999999;
      padding-bottom: 0 !important;

      .image_wrapper {
        margin-bottom: -32px !important;
        .image_wrapper-bg {
          height: 80%;
        }

        img {
          margin-left: -7% !important;
        }
      }

      .section_info {
        padding: 80px 10% 8% 10% !important;
      }

      .section_left {
        margin-top: 0% !important;
      }
    }

    &.--discover {
      padding: 24px 10% 0% 10% !important;
      margin-top: 0 !important;

      .section_info {
        padding: 0px 10% 10% 10% !important;
      }

      .section_left {
        margin: 80px -12px 0px -12px;
      }

      .image_wrapper img {
        margin-left: 4% !important;
      }
    }

    &.--negotiate {
      padding: 100px 10% 0% 10% !important;

      .section_right {
        padding-left: 0;
        margin-top: 80px;
      }

      .background_wrapper {
        margin-bottom: 0px !important;
      }
    }

    &.--uncover {
      img {
        transform: translateX(5%) !important;
        margin-top: 10% !important;
        width: 75% !important;
      }

      .image_wrapper {
        &:after {
          bottom: 40px !important;
        }
      }

      .image_wrapper-bg {
        height: 80% !important;
        width: 80% !important;
        margin-top: 0% !important;
        margin-left: 15% !important;
      }

      .section_right {
        margin-bottom: -90px;
      }
    }

    &.--genuine {
      padding: 140px 10% 10% 10% !important;

      .image_wrapper {
        margin: 20px -12px -60px -12px;
        .image_wrapper-bg {
          height: 80%;
        }
      }
    }

    &.--rightText {
      .section_right {
        padding-left: 0 !important;
      }

      .content_wrapper {
        flex-direction: column-reverse;
      }
    }

    .section_left {
      width: 100% !important;
      margin-top: 10%;
    }

    .section_right {
      width: 100% !important;
      margin-top: 10%;
    }
  }

  .--how-it-works {
    .grid {
      flex-direction: column !important;
      align-items: center !important;

      .grid-item {
        width: 100% !important;
        margin-bottom: 36px;

        .grid-item-top {
          margin-left: 14%;
        }

        .grid-item-bottom {
          margin-left: 4% !important;
          font-size: 20px !important;
          line-height: 24px !important;
          margin-top: 14px !important;
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1360px) {
  .section_info {
    padding-right: 10% !important;
    padding-left: 10% !important;
    .title {
      font-size: 42px !important;
      line-height: 42px !important;

      span {
        font-size: 72px !important;
      }
    }

    .description {
      max-width: 360px;
    }

    &.--anonymous .image_wrapper {
      img {
        // margin-bottom: 14% !important;
      }

      &:after {
        width: 80%;
        bottom: 10%;
        left: 10%;
      }
    }

    &.--discover {
      .image_wrapper {
        transform: scale(0.8);
      }
    }
  }
}

@media (min-width: 1360px) and (max-width: 1440px) {
  .section_info {
    padding-right: 10% !important;
    padding-left: 10% !important;

    .description {
      font-size: 20px;
    }

    .title {
      font-size: 52px !important;
      line-height: 52px !important;

      span {
        font-size: 90px !important;
      }
    }
  }

  &.--discover {
    .image_wrapper {
      transform: scale(0.8);
    }
  }
}
</style>
