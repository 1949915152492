<template>
  <div :style="{width}" :class="['my_button_container', { isLoading: loading } ]">
    <button :type="buttonActionType" :class="type" v-bind="$attrs" v-on="$listeners">
      <template v-if="loading">
        <div :class="type" class="button-loader"></div>
      </template>
      <slot v-else></slot>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "primary", //accent
    },
    buttonActionType: {
      type: String,
      default: "button"
    },
    loading: {
      type: Boolean,
      default: false,
    },
    width: {
      types: [null, String, Number],
      default: null
    }
  },
};
</script>

<style lang="scss" scoped>
@import '../assets/scss/mixins';
.my_button_container {
  display: inline-block;
  &.isLoading {
    button {
      padding: 0 24px;
      .button-loader {
        margin: 0 auto;
        -webkit-animation: spinAround 500ms infinite linear;
        animation: spinAround 500ms infinite linear;
        border: 2px solid #dbdbdb;
        border-radius: 9999px;
        border-right-color: #ffffff;
        border-top-color: white;
        content: "";
        display: block;
        height: 1em;
        position: relative;
        width: 1em;
      }
    }
  }
  button {
    height: 55px;
    width: 100%;
    padding: 15px 24px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    outline: none;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    transition: 0.3s all ease-in-out;
    @include respond(md) {
      padding: 15px 10px;
      font-size: 14px;
    }

    &.primary:not(:disabled) {
      background: var(--bg);
      //   color: var(--bg-text);
      color: white;
      &:hover,
      &:focus {
        color: white;
        box-shadow: 0px 0px 5px #8d8d8d;
        background: #333333;
      }
    }
    &.accent:not(:disabled) {
      background: var(--bg-accent);
      color: var(--bg--accent-text);
      &:hover,
      &:focus {
        color: black;
        box-shadow: 0px 0px 5px #8d8d8d;
      }
      // &:focus {
      //   background: white;
      // }
    }
    &.tertiary:not(:disabled) {
      background: var(--tertiary);
      color: var(--bg--accent-text);
      &:hover,
      &:focus {
        color: black;
        box-shadow: 0px 0px 5px #8d8d8d;
      }
    }
    &.quaternary:not(:disabled) {
      background: var(--quaternary);
      color: var(--bg--accent-text);
      &:hover,
      &:focus {
        color: black;
        box-shadow: 0px 0px 5px #8d8d8d;
      }
    }
    &:disabled {
      background: #e2e2e2;
      cursor: no-drop;
    }
  }
}
</style>
