<template>
  <Bar
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
import { Bar } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import chartjsPluginAnnotation from "chartjs-plugin-annotation";
ChartJS.register(
  chartjsPluginAnnotation,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);
export default {
  name: "BarChart",
  components: { Bar },
  props: {
    chartId: {
      type: String,
      default: "bar-chart",
    },
    labelBg: {
      type: String,
      default: "#FFFFFF",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 800,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Object,
      default: () => {},
    },
    labels: {
      type: Array,
      default: () => [],
    },
    dataInput: {
      type: Array,
      default: () => [],
    },
    median: {
      type: Number,
      default: 5,
    },
    threequarters: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      chartData: {
        labels: this.labels || [2, 9, 16, 23, 30, 37],
        datasets: [
          {
            label: "",
            backgroundColor: "#cbd83f",
            borderRadius: 6,
            data: this.dataInput || [1, 5, 23, 65, 108],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        scales: {
          x: {
            grid: {
              display: false,
            },
          },
          y: {
            grid: {
              display: false,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false, // <-- this option disables tooltips
          },
          autocolors: false,
          annotation: {
            annotations: [
              {
                type: "line",
                borderColor: "black",
                borderDash: [6, 6],
                borderWidth: 1,
                xMax: this.median,
                xMin: this.median,
                xScaleID: "x",
                yMax: 0,
                yMin: 110,
                yScaleID: "y",
                label: {
                  display: true,
                  backgroundColor: this.labelBg,
                  color: "black",
                  content: "Median",
                  position: "start",
                },
              },
              {
                type: "line",
                borderColor: "black",
                borderDash: [6, 6],
                borderWidth: 1,
                xMax: this.threequarters.scale,
                xMin: this.threequarters.scale,
                xScaleID: "x",
                yMax: 0,
                yMin: 110,
                yScaleID: "y",
                label: {
                  display: true,
                  backgroundColor: this.labelBg,
                  color: "black",
                  content: this.threequarters.value,
                  position: "start",
                },
              },
            ],
          },
        },
      },
    };
  },
};
</script>
