const firebaseConfig = {
  apiKey: "AIzaSyAEJsPbXTPPFxOMKamnkLdCBNWTPk375T4",
  authDomain: "uncovor-20756.firebaseapp.com",
  projectId: "uncovor-20756",
  storageBucket: "uncovor-20756.appspot.com",
  messagingSenderId: "541207820413",
  appId: "1:541207820413:web:b8cd637ab3d75121281914",
  measurementId: "G-ZGMQ61T9JP",
};
export default firebaseConfig;
