var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer_canvas",attrs:{"id":"surprise-me"}},[_c('img',{staticClass:"canvas_img",attrs:{"src":require("@/assets/images/home/home-tsoa-canvas.png")}}),(this.bubbles[_vm.randomNumber])?_c('div',{ref:"bubble",staticClass:"bubble_xD active",style:('background:url(' +
      require('../../assets/images/home/tsoa-canvas/' +
        this.bubbles[_vm.randomNumber].id +
        '.svg') +
      ';width: ' +
      this.bubbles[_vm.randomNumber].size[0] +
      'px; height :' +
      this.bubbles[_vm.randomNumber].size[1] +
      'px;' +
      ' left: ' +
      this.bubbles[_vm.randomNumber].position[0] +
      '%; top :' +
      this.bubbles[_vm.randomNumber].position[1] +
      '%;')},[_vm._v(" "+_vm._s(this.bubbles[_vm.randomNumber].text)+" ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }