<template>
  <div
    v-if="show"
    :class="`${customClass} my_modal_wrapper my_modal_container`"
  >
    <div @click="$emit('closeModal')" class="modal_back_drop"></div>
    <div class="modal_content_wrapper">
      <div class="modal_content" :class="{ showLeftPart: showLeftPart }">
        <div class="modal_close" @click="$emit('closeModal')"></div>
        <div v-if="showLeftPart" class="left_part">
          <div class="left_part--title">{{ title }}</div>
          <div class="left_part--image">
            <slot name="image"></slot>
          </div>
        </div>
        <div class="default_content">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showLeftPart: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    customClass: {
      type: String,
      default: "",
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isDefaultSlotExists() {
      return !!this.$slots.default;
    },
  },
  watch: { 
    show: function(newVal) { // watch it
      const htmlElem = document.querySelector("html");
      if (newVal) {
        htmlElem.classList.add("noScroll")
      } else {
        htmlElem.classList.remove("noScroll")
      }
    }
  },
  created() {},
  destroyed() {
    document.querySelector("html").classList.remove("noScroll");
  }
};
</script>

<style lang="scss" scoped>
.my_modal_wrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  .modal_back_drop {
    position: absolute;
    background: #000000cf;
    height: 100%;
    width: 100%;
  }
  .modal_content_wrapper {
    position: relative;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    max-width: 750px;
    padding-top: 4%;
    .modal_content {
      background: white;
      border-radius: 20px;
      overflow: hidden;
      position: relative;
      width: 100%;
      .modal_close {
        display: block !important;
        position: absolute;
        width: 25px;
        height: 25px;
        border-radius: 8px;
        top: -6px;
        right: -6px;
        z-index: 9999;
        cursor: pointer;

        &:before {
          content: "";
          display: block;
          width: 25px;
          position: fixed;
          z-index: 9999;
          height: 25px;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='25' viewBox='0 0 25 25' fill='none' role='img' class='icon icon-24 lazyloaded'%3E%3Crect x='3' y='5' width='18' height='16' fill='white'%3E%3C/rect%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.5051 0.135254C14.9528 0.135152 17.3455 0.860879 19.3807 2.22066C21.416 3.58044 23.0022 5.51321 23.939 7.77453C24.8757 10.0359 25.1209 12.5242 24.6434 14.9248C24.166 17.3255 22.9873 19.5306 21.2566 21.2614C19.5259 22.9922 17.3207 24.1709 14.9201 24.6485C12.5195 25.126 10.0311 24.881 7.76979 23.9443C5.50843 23.0076 3.57561 21.4214 2.21576 19.3862C0.855913 17.3511 0.130107 14.9583 0.130127 12.5107C0.130141 9.22858 1.43392 6.08092 3.75467 3.76009C6.07542 1.43926 9.22304 0.135377 12.5051 0.135254V0.135254ZM7.13525 16.4156C6.9463 16.6101 6.84148 16.8711 6.84346 17.1423C6.84544 17.4135 6.95406 17.6729 7.14583 17.8647C7.3376 18.0564 7.59711 18.1649 7.86827 18.1668C8.13943 18.1687 8.40044 18.0638 8.59488 17.8748L12.4997 13.9699L16.4103 17.8805C16.6047 18.0698 16.8659 18.1749 17.1372 18.1731C17.4085 18.1712 17.6682 18.0626 17.8601 17.8707C18.0519 17.6788 18.1605 17.4191 18.1623 17.1478C18.164 16.8765 18.0589 16.6153 17.8695 16.421L13.9592 12.5106L17.8847 8.58516C18.072 8.39039 18.1754 8.12994 18.1727 7.85976C18.1701 7.58958 18.0616 7.33122 17.8705 7.14017C17.6795 6.94911 17.4211 6.8406 17.1509 6.83794C16.8808 6.83529 16.6203 6.93869 16.4255 7.12594L12.4997 11.051L8.57998 7.13125C8.38644 6.93769 8.12393 6.82894 7.8502 6.82892C7.57647 6.82891 7.31394 6.93763 7.12038 7.13118C6.92681 7.32472 6.81806 7.58724 6.81804 7.86097C6.81803 8.13469 6.92675 8.39722 7.1203 8.59078L11.0402 12.5106L7.13525 16.4156Z' fill='%23979797'%3E%3C/path%3E%3C/svg%3E");
        }
      }
      &.showLeftPart {
        display: flex;
        .default_content {
          padding: 40px 32px;
          width: 420px;
          position: relative;
          min-height: 50vh;
        }
      }
      .left_part {
        background: #f1f2e4;
        padding-top: 40px;
        width: 320px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .left_part--title {
          font-weight: 700;
          font-size: 30px;
          line-height: 38px;
          margin: 0 33px 20px 33px;
        }
        .left_part--image {
          text-align: left;
          display: flex;
        }
      }
    }
  }

  &.videoModal {
    .modal_content_wrapper {
      width: 72vw;
      max-width: 72vw;
      margin: 14vh 14vw;
      display: block;
      padding-top: 0;
    }
  }
}

@media (max-width: 768px) {
  .modal_content_wrapper {
    padding-top: 0 !important;

    .modal_content {
      flex-direction: column;
      height: 100vh;
      border-radius: 0 !important;
      width: 100%;
      overflow: scroll !important;

      .left_part {
        width: 100% !important;
        padding-top: 60px !important;
        .left_part--title {
          text-align: left;
          font-size: 24px !important;
          margin: 8px 33px 20px 33px !important;
        }

        .bottom_image {
          width: 50% !important;
          margin-bottom: -2px !important;
        }

        .top_image {
          margin-left: 38%;
          margin-bottom: -16%;
        }
      }

      .modal_close {
        display: block !important;
        position: fixed !important;
        width: 36px !important;
        height: 36px !important;
        background: #d6d9b0;
        border-radius: 8px;
        top: 16px !important;
        left: 34px;

        &:before {
          content: "";
          display: block;
          width: 20px !important;
          height: 20px !important;
          margin-top: 8px;
          margin-left: 8px;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath d='M13.8498 2.48984C13.4415 2.08151 12.7831 2.08151 12.3748 2.48984L5.4498 9.41484C5.1248 9.73984 5.1248 10.2648 5.4498 10.5898L12.3748 17.5148C12.7831 17.9232 13.4415 17.9232 13.8498 17.5148C14.2581 17.1065 14.2581 16.4482 13.8498 16.0398L7.81647 9.99818L13.8581 3.95651C14.2581 3.55651 14.2581 2.88984 13.8498 2.48984Z' fill='black'/%3E%3C/svg%3E") !important;
        }
      }

      .default_content {
        width: 100% !important;
        min-height: 50vh !important;
        background: white !important;
        margin-top: -14px !important;
        border-radius: 16px 16px 0 0 !important;
      }
    }
  }

  .videoModal {
    width: 100%;

    .modal_content {
      height: 50vh;
      background: none !important;

      .modal_close {
        width: 24px !important;
        height: 24px !important;
        top: -42px !important;
        right: 10px !important;
        left: auto !important;
        z-index: 99;

        &:before {
          width: 25px !important;
          height: 25px !important;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='25' viewBox='0 0 25 25' fill='none' role='img' class='icon icon-24 lazyloaded'%3E%3Crect x='3' y='5' width='18' height='16' fill='white'%3E%3C/rect%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.5051 0.135254C14.9528 0.135152 17.3455 0.860879 19.3807 2.22066C21.416 3.58044 23.0022 5.51321 23.939 7.77453C24.8757 10.0359 25.1209 12.5242 24.6434 14.9248C24.166 17.3255 22.9873 19.5306 21.2566 21.2614C19.5259 22.9922 17.3207 24.1709 14.9201 24.6485C12.5195 25.126 10.0311 24.881 7.76979 23.9443C5.50843 23.0076 3.57561 21.4214 2.21576 19.3862C0.855913 17.3511 0.130107 14.9583 0.130127 12.5107C0.130141 9.22858 1.43392 6.08092 3.75467 3.76009C6.07542 1.43926 9.22304 0.135377 12.5051 0.135254V0.135254ZM7.13525 16.4156C6.9463 16.6101 6.84148 16.8711 6.84346 17.1423C6.84544 17.4135 6.95406 17.6729 7.14583 17.8647C7.3376 18.0564 7.59711 18.1649 7.86827 18.1668C8.13943 18.1687 8.40044 18.0638 8.59488 17.8748L12.4997 13.9699L16.4103 17.8805C16.6047 18.0698 16.8659 18.1749 17.1372 18.1731C17.4085 18.1712 17.6682 18.0626 17.8601 17.8707C18.0519 17.6788 18.1605 17.4191 18.1623 17.1478C18.164 16.8765 18.0589 16.6153 17.8695 16.421L13.9592 12.5106L17.8847 8.58516C18.072 8.39039 18.1754 8.12994 18.1727 7.85976C18.1701 7.58958 18.0616 7.33122 17.8705 7.14017C17.6795 6.94911 17.4211 6.8406 17.1509 6.83794C16.8808 6.83529 16.6203 6.93869 16.4255 7.12594L12.4997 11.051L8.57998 7.13125C8.38644 6.93769 8.12393 6.82894 7.8502 6.82892C7.57647 6.82891 7.31394 6.93763 7.12038 7.13118C6.92681 7.32472 6.81806 7.58724 6.81804 7.86097C6.81803 8.13469 6.92675 8.39722 7.1203 8.59078L11.0402 12.5106L7.13525 16.4156Z' fill='%23979797'%3E%3C/path%3E%3C/svg%3E") !important;
        }
      }

      .default_content {
        background: none !important;
        margin-top: 0 !important;

        min-height: 30vh !important;
      }
    }

    .modal_content_wrapper {
      width: 100% !important;
      max-width: 88vw !important;
      margin: 8vh 6vw !important;
      height: 50vh !important;
    }

    iframe {
      width: 100% !important;
      height: 360px !important;
      border: none !important;
    }
  }
}
</style>
