<template>
  <div>
    <MyModal
      :show="show"
      :showLeftPart="!showFinale"
      :title="showFinale ? '' : 'Move to the top of the waitlist'"
      v-on:closeModal="closeTheModal"
      :customClass="'upload_payslip_modal'"
    >
      <template v-if="!showFinale" v-slot:image>
        <img
          src="../assets/images/email-confirmation/get-started.png"
          alt=""
          srcset=""
        />
      </template>
      <div class="mymodal_content_wrapper">
        <template v-if="showFinale">
          <div class="final_content_wrapper">
            <div class="left_part">
              <div class="final_content_wrapper__left-part">
                <div class="primary_title">Boom! You have earned it!</div>
                <div class="secondary_title">
                  You will get 10,000 trons when we launch!
                </div>
                <a
                  href="https://twitter.com/uncovorapp"
                  target="_blank"
                  class="twitter_btn"
                >
                  <img
                    src="../assets/images/upload-payslip/twitter_icon.svg"
                  />Follow us!</a
                >
              </div>
            </div>
            <div class="right_part final_content_wrapper__right-part">
              <div class="img_bg"></div>
              <img src="../assets/images/upload-payslip/boom.png" alt="" />
            </div>
          </div>
        </template>
        <template v-else>
          <div class="upload_payslip_wrapper">
            <input
              ref="file_picker"
              type="file"
              class="file_picker"
              name="avatar"
              accept=".pdf"
              @change="onFileUpload"
            />
            <div @click="showFilePicker" class="border_box">
              <div class="top_part">
                <p
                  v-if="currentUplodedFile && currentUplodedFile.name"
                  class="title"
                >
                  {{ currentUplodedFile.name }}
                </p>
                <p v-else class="title">Upload your recent salary slip</p>
                <div class="images_container">
                  <template v-if="currentUplodedFile.type">
                    <img
                      class="white_bg"
                      style="max-height: 114px; margin-top: 16px"
                      v-if="currentUplodedFile.type.indexOf('pdf') !== -1"
                      src="../assets/images/upload-payslip/pdf.svg"
                      alt=""
                    />
                    <img
                      class="white_bg"
                      style="max-height: 114px; margin-top: 16px"
                      v-else
                      src="../assets/images/upload-payslip/jpg.svg"
                      alt=""
                    />
                  </template>
                  <template v-else>
                    <img
                      style="margin-bottom: -6px"
                      src="../assets/images/upload-payslip/file.svg"
                      alt=""
                    />
                    <img
                      style="margin-bottom: 0px"
                      src="../assets/images/upload-payslip/close-eyes.svg"
                      alt=""
                    />
                  </template>
                </div>
              </div>
              <div class="bottom_part">
                <template v-if="currentUplodedFile.type">
                  <img
                    src="../assets/images/upload-payslip/reload.svg"
                    alt=""
                  />
                  <span>Replace file</span>
                </template>
                <template v-else> Supported file formats: .pdf </template>
              </div>
            </div>
          </div>

          <MyButton
            @click="uploadTheFileToServer"
            :disabled="!enableUploading"
            :loading="showButtonLoading"
            type="primary"
            class="button_container upload_payslip"
          >
            Upload Now
          </MyButton>
        </template>
      </div>
    </MyModal>
  </div>
</template>

<script>
import MyModal from "../components/modal.vue";
import MyButton from "../components/button.vue";
import AOS from "aos";
import { api } from "../services/http-common.js";

export default {
  props: {
    show: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showFinale: false,
      currentUplodedFile: {},
      showButtonLoading: false,
      currentFileUploadStatus: "PENDING",
    };
  },
  components: {
    MyModal,
    MyButton,
  },
  computed: {
    enableUploading() {
      return this.currentUplodedFile.type;
    },
  },
  methods: {
    showFilePicker() {
      if (this.showButtonLoading) {
        return false;
      }
      this.$refs["file_picker"].click();
    },
    uploadTheFileToServer() {
      const self = this;
      self.showButtonLoading = true;

      const filesList = this.$refs.file_picker.files;

      const formData = new FormData();
      formData.append("file_obj", filesList[0]);

      api
        .post("/api/v1/salary/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          self.showButtonLoading = false;
          self.showFinale = true;
          this.$emit('uploaded')
          // console.log(response);
          self.currentFileUploadStatus = "UPLOADED";
          // window.location.reload();
        })
        .catch((error) => {
          self.currentFileUploadStatus = "FAILED";
          self.showButtonLoading = false;
          console.log(error);
        });
    },
    onFileUpload() {
      // console.log(ev, "ev");
      const filesList = this.$refs.file_picker.files;
      let currentFile;
      if (filesList && filesList[0]) {
        currentFile = filesList[0];
        this.currentUplodedFile = currentFile || {};
        // console.log(this.currentUplodedFile, "file");
      }

      // const reader = new FileReader();
      // reader.onloadend = () => {
      //   // use a regex to remove data url part
      //   const base64String = reader.result
      //     .replace("data:", "")
      //     .replace(/^.+,/, "");

      //   // log to console
      //   // logs wL2dvYWwgbW9yZ...
      //   console.log(base64String, "b64");
      // };

      // reader.readAsDataURL(this.currentUplodedFile, "reader");
    },
    closeTheModal() {
      this.$emit("closeModal", { status: this.currentFileUploadStatus });
    },
  },
  mounted: () => {
    AOS.init({
      delay: 200,
      duration: 400, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: true,
    });
  },
};
</script>

<style lang="scss" scoped>
@import '../assets/scss/mixins';
.upload_payslip_wrapper {
  background: #f0ff56;
  border-radius: 16px;
  padding: 24px;
  text-align: center;
  .file_picker {
    visibility: hidden;
    height: 0;
    width: 0;
  }
  .border_box {
    cursor: pointer;
    border: 2px dashed #cbd83f;
    box-sizing: border-box;
    border-radius: 12px;
    margin-top: -18px;
    .top_part {
      .title {
        font-size: 16px;
        line-height: 23px;
        color: black;
        max-width: 150px;
        word-break: break-word;
        margin: 36px auto 6px;
      }
      .images_container {
        border-bottom: 2px dashed #cbd83f;
        img {
          vertical-align: bottom;
        }
        .white_bg {
          background: white;
          border-top-right-radius: 12px;
          border-top-left-radius: 12px;
        }
      }
    }
    .bottom_part {
      font-size: 14px;
      line-height: 23px;
      color: #000000;
      opacity: 0.6;
      padding: 12px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.button_container {
  width: 100%;
  margin-top: 25px;
  ::v-deep {
    button {
      width: 100%;
    }
  }
  @include respond(sm) {
    margin-bottom: 20px;
  }
}
.final_content_wrapper {
  display: flex;
  background: #f1f2e4;
  position: relative;
  height: 450px;
  max-height: 90vh;
  width: 80vw;
  @include respond(sm) {
    display: block;
    width: 100%;
    &__left-part {
      padding-top: 20px;
    }
    &__right-part {
      img {
        width: 90% !important;
      }
    }
  }
  .left_part {
    padding: 60px 40px;
    justify-content: space-evenly !important;
    .primary_title {
      font-size: 40px;
      font-weight: 700;
      margin-bottom: 30px;
      color: black;
      max-width: 200px;
      line-height: 46px;
    }
    .secondary_title {
      font-size: 30px;
      font-weight: 600;
      color: black;
      line-height: 38px;
      margin-bottom: 24px;
    }
    .twitter_btn {
      img {
        margin-right: 8px;
        margin-bottom: -3px;
      }
      background: #2aa9e0;
      text-decoration: none;
      padding: 12px 16px;
      border-radius: 40px;
      margin-top: 24px;

      width: fit-content;
      color: white;
      font-weight: 600;
      cursor: pointer !important;
    }
  }
  .right_part {
    position: relative;
    .img_bg {
      background: #f0ff56;
      width: 100%;
      height: 85%;
      position: absolute;
      bottom: 0;
      border-radius: 100%;
    }
    img {
      width: 100%;
      position: relative;
      height: 95%;
      right: -40px;
      bottom: -48px;
    }
  }
}

::v-deep {
  .upload_payslip_modal {
    .modal_content_wrapper {
      max-width: 720px !important;
    }

    .default_content {
      width: 420px !important;
    }
  }

  .button_container.upload_payslip button.primary {
    color: white !important;
  }

  .left_part--title {
    height: auto !important;
  }

  .left_part--image {
    justify-content: flex-end !important;
  }

  @media (max-width: 768px) {
    .left_part--title {
      height: auto !important;
      line-height: 30px !important;
    }

    .default_content {
      margin-bottom: 8% !important;
    }
  }
}
</style>
