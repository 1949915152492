var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home_sections"},[_c('div',{staticClass:"section_wrapper"},[_vm._m(0),_c('ShapeF1',{staticClass:"svg-element bottom-shape priamry_color"})],1),_vm._m(1),_c('div',{staticClass:"section_wrapper",staticStyle:{"z-index":"10"}},[_c('ShapeF2',{staticClass:"svg-element top-shape"}),_vm._m(2),_c('ShapeF3',{staticClass:"svg-element bottom-shape"})],1),_vm._m(3),_c('div',{staticClass:"section_wrapper"},[_c('ShapeF4',{staticClass:"svg-element top-shape"}),_vm._m(4),_c('ShapeF5',{staticClass:"svg-element bottom-shape"})],1),_c('div',{staticClass:"section_wrapper"},[_vm._m(5),_c('ShapeF6',{staticClass:"svg-element last-shape"})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section_info --leftText --how-it-works has-shapes",attrs:{"id":"how-it-works"}},[_c('div',{staticClass:"content_wrapper"},[_c('div',{staticClass:"title"},[_c('span',[_vm._v("How will")]),_vm._v(" Uncovor work?")]),_c('div',{staticClass:"grid"},[_c('div',{staticClass:"grid-item"},[_c('div',{staticClass:"grid-item-top"},[_c('img',{attrs:{"src":require("../../assets/images/home/how-it-works/1.png")}})]),_c('div',{staticClass:"grid-item-bottom"},[_vm._v("Create your profile")])]),_c('div',{staticClass:"grid-item"},[_c('div',{staticClass:"grid-item-top"},[_c('img',{attrs:{"src":require("../../assets/images/home/how-it-works/2.png")}})]),_c('div',{staticClass:"grid-item-bottom"},[_vm._v("Upload Offer Letter")])]),_c('div',{staticClass:"grid-item"},[_c('div',{staticClass:"grid-item-top"},[_c('img',{attrs:{"src":require("../../assets/images/home/how-it-works/3.png")}})]),_c('div',{staticClass:"grid-item-bottom"},[_vm._v("Get Offer Verified")])]),_c('div',{staticClass:"grid-item"},[_c('div',{staticClass:"grid-item-top"},[_c('img',{attrs:{"src":require("../../assets/images/home/how-it-works/4.png")}})]),_c('div',{staticClass:"grid-item-bottom"},[_vm._v("Uncovor Real Salaries!")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section_wrapper"},[_c('div',{staticClass:"section_info --leftText --uncover",attrs:{"id":"what-we-offer"}},[_c('div',{staticClass:"content_wrapper"},[_c('div',{staticClass:"section_left",attrs:{"data-aos":"fade-up"}},[_c('div',{staticClass:"title"},[_c('span',[_vm._v("Uncover")]),_vm._v(" Verified Salaries")]),_c('div',{staticClass:"description"},[_vm._v(" Don’t take ‘blind’ opinions or unreliable data points from anyone. Every salary at Uncovor is verified. ")])]),_c('div',{staticClass:"section_right"},[_c('div',{staticClass:"image_wrapper"},[_c('div',{staticClass:"image_wrapper-bg"}),_c('img',{attrs:{"data-aos":"fade-up","loading":"lazy","src":require("@/assets/images/home/gifs/reveal.gif")}})])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section_info --rightText --anonymous has-shapes"},[_c('div',{staticClass:"content_wrapper"},[_c('div',{staticClass:"section_left"},[_c('div',{staticClass:"image_wrapper"},[_c('div',{staticClass:"image_wrapper-bg"}),_c('img',{attrs:{"data-aos":"fade-up","loading":"lazy","src":require("@/assets/images/home/gifs/anonymous.gif")}})])]),_c('div',{staticClass:"section_right",attrs:{"data-aos":"fade-up"}},[_c('div',{staticClass:"title"},[_c('span',[_vm._v("Anonymous")]),_vm._v("Browsing")]),_c('div',{staticClass:"description"},[_vm._v(" At Uncovor your identity remains hidden. Neither your company nor your friends can find out it’s you. ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section_wrapper"},[_c('div',{staticClass:"section_info --leftText --benchmark"},[_c('div',{staticClass:"content_wrapper"},[_c('div',{staticClass:"section_left",attrs:{"data-aos":"fade-up"}},[_c('div',{staticClass:"title"},[_c('span',[_vm._v("Benchmark")]),_vm._v("against peers")]),_c('div',{staticClass:"description"},[_vm._v(" Life is simpler when everyone knows everyone’s salary. Uncovor is the only place you can find a long-lost friend’s salary. ")])]),_c('div',{staticClass:"section_right"},[_c('div',{staticClass:"image_wrapper"},[_c('div',{staticClass:"image_wrapper-bg"}),_c('img',{attrs:{"data-aos":"fade-up","loading":"lazy","src":require("@/assets/images/home/gifs/benchmark.gif")}})])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section_info --rightText --discover"},[_c('div',{staticClass:"content_wrapper"},[_c('div',{staticClass:"section_left"},[_c('div',{staticClass:"image_wrapper"},[_c('div',{staticClass:"image_wrapper-bg"}),_c('img',{attrs:{"data-aos":"fade-up","loading":"lazy","src":require("@/assets/images/home/gifs/discover.gif")}})])]),_c('div',{staticClass:"section_right",attrs:{"data-aos":"fade-up"}},[_c('div',{staticClass:"title"},[_c('span',[_vm._v("Discover the")]),_vm._v("Best Pay Masters")]),_c('div',{staticClass:"description"},[_vm._v(" To FAANG or not to FAANG, Uncovor helps unearth the hottest pay masters and wealth creators. ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section_info --leftText --negotiate"},[_c('div',{staticClass:"content_wrapper"},[_c('div',{staticClass:"section_left",attrs:{"data-aos":"fade-up"}},[_c('div',{staticClass:"title"},[_c('span',[_vm._v("Negotiate,")]),_vm._v("Hikes Better")]),_c('div',{staticClass:"description"},[_vm._v(" Information is power and discussion forums can’t teach you how to negotiate. Credible, crunched information at Uncovor can. ")])]),_c('div',{staticClass:"section_right"},[_c('div',{staticClass:"image_wrapper"},[_c('div',{staticClass:"image_wrapper-bg"}),_c('img',{attrs:{"data-aos":"fade-up","loading":"lazy","src":require("@/assets/images/home/gifs/negotiate.gif")}})])])])])
}]

export { render, staticRenderFns }