<template>
  <div class="home_wrapper">
    <TopHeader />

    <HomeSections />

    <FooterCanvas />

    <Footer />

    <!-- <ErrorModal 
      :show="true" 
      :className="'error-one'" 
      :title="'Oops. You have discovered an edge case!'"
      :info="'Our Uncovor minions have already taken a note of this and are working on it.'"
      :secondaryCta="true"
      :feedbackLink="true"
    /> -->
  </div>
</template>

<script>
import TopHeader from "./TopHeader.vue";
import HomeSections from "./HomeSections.vue";
import FooterCanvas from "./FooterCanvas.vue";
import AOS from "aos";
import { initializeApp } from "firebase/app";
import firebaseConfig from "../../helpers/firebaseConfig";
import Footer from "../../components/Footer.vue";
// import ErrorModal from "../../components/error-modal";

export default {
  components: {
    TopHeader,
    HomeSections,
    FooterCanvas,
    Footer,
    // ErrorModal,
  },
  mounted: () => {
    AOS.init({
      delay: 50,
      offset: 400,
      duration: 400, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: true,
    });
  },
  beforeCreate: () => {
    initializeApp(firebaseConfig);
  },
};
</script>

<style lang="scss" scoped>
.home_wrapper {
  overflow: hidden;

  .left_part_two_images {
    width: 100%;
    .top_image {
      display: block;
      margin: 0 auto;
    }
    .bottom_image {
      position: relative;
      bottom: -5px;
    }
  }
  .mymodal_content_wrapper {
    a {
      color: #9faa22;
    }
    .my_input_wrapper {
      margin-bottom: 24px;
      ::v-deep {
        input {
          padding: 16px;
          line-height: 19px;
        }
      }
    }
    .my_button_wrapper {
      ::v-deep {
        button {
          height: 56px;
          padding: 0 30px;
          box-sizing: border-box;
          width: 100%;
        }
      }
    }
    .terms_conditions {
      font-weight: 400;
      font-size: 12px;
      line-height: 24px;
      margin-top: 12px;
    }
    .resend_otp {
      text-align: left;
      margin-top: 16px;
      font-size: 16px;
      font-weight: 400;
      .counter {
        color: #9faa22;
      }
    }
  }
}
</style>
