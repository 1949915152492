<template>
  <div class="terms-conditions">
    <div class="header">
      <router-link to="/">
        <img src="../assets/images/logo_full.svg" alt="" srcset="" />
      </router-link>
    </div>

    <div class="content">
      <h1>Terms & Conditions</h1>
      <p>Last Updated: August 19, 2021</p>
      <div class="line-break"></div>
      <p>
        Welcome to Uncovor (“Uncovor”, “us” “we” or “our”) website. Please read
        these Terms of Service (the “Terms”) carefully because they govern your
        use of our websites including uncovor.com and any other websites that we
        may later own or operate (each, a “Site,” and collectively, the
        “Sites”); our mobile applications (“Apps”), our application program
        interfaces (“APIs”), our software development kits (“SDKs”) and our
        collaboration tools and other products and services we may later own or
        operate (collectively, with the Sites, Apps, APIs, and SDKs, the
        “Services”).
      </p>
      <section>
        <h2>1. Agreement to these Terms</h2>
        <p>
          By using the Services, you agree to be bound by these Terms. If you
          don’t agree to these Terms, do not use the Services. If you are
          accessing and using the Services on behalf of a company (such as your
          employer) or other legal entity, you represent and warrant that you
          have the authority to bind that company or other legal entity to these
          Terms. In that case, “you” and “your” will refer to that company or
          other legal entity.
        </p>
      </section>
      <section>
        <h2>2. Changes to the Terms or Services</h2>
        <p>
          We may modify the Terms at any time, at our sole discretion. If we do
          so, we will let you know either by posting the modified Terms on the
          Site or through other communications. If you continue to use the
          Services after such a change, you are indicating that you agree to the
          modified Terms. We may also change or discontinue all or any part of
          the Services, at any time and without notice, at our sole discretion.
        </p>
      </section>
      <section>
        <h2>3. Service Terms</h2>
        <p>You agree to comply with the Service Terms attached as Exhibit A.</p>
      </section>
      <section>
        <h2>4. License</h2>
        <p>
          Subject to the terms and conditions of these Terms, we hereby grant
          you a limited, non-exclusive, non-transferable, non-sublicensable
          license worldwide, with the exception of countries that are embargoed
          or designated as supporting terrorist activities by the United States
          Government, for you to access and use the Services in connection with
          your internal business operations.
        </p>
      </section>
      <section>
        <h2>5. Confidentiality</h2>
        <p>
          We (the “Discloser”) have disclosed or may disclose proprietary or
          non-public business, technical, financial, or other information
          (“Confidential Information”) to you (the “Recipient”). Our
          Confidential Information expressly includes non-public information
          regarding features, functionality, and performance of the Services,
          including security related information.
        </p>
        <p>
          The Recipient will use the Discloser’s Confidential Information only
          for the purpose of evaluating whether or not to use (or continue to
          use) the Services. The Recipient will not disclose the Discloser’s
          Confidential Information to parties other than the Recipient’s
          employees, contractors, affiliates, agents, or professional advisors
          (“Representatives”) who need to know it and who have a legal
          obligation to keep it confidential. The Recipient will ensure that its
          Representatives are subject to no less restrictive confidentiality
          obligations than those herein. Notwithstanding the foregoing, the
          Recipient may disclose the Discloser’s Confidential Information: (a)
          if directed by Discloser; or (b) to the extent required by applicable
          legal process, provided that the Recipient uses commercially
          reasonable efforts to (i) promptly notify the Discloser in advance, to
          the extent permitted by law, and (ii) comply with the Discloser’s
          reasonable requests regarding its efforts to oppose the disclosure.
          The obligations set forth herein will survive for so long as these
          Terms are in effect between the parties and for five years thereafter.
        </p>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  methods: {},
  computed: {},
};
</script>

<style lang="scss" scoped>
@import '../assets/scss/mixins';
.terms-conditions {
  .header {
    background: var(--bg);
    padding: 38px;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .content {
    text-align: left;
    padding: 70px 180px;
    @include respond(lg) {
      padding: 70px;
    }
    @include respond(sm) {
      padding: 20px; // SCREEN-PADDING
    }
    .line-break {
      border: 1px solid rgba(0, 0, 0, 0.15);
      margin: 48px 0;
    }
    section {
      margin-top: 60px;
      h2 {
        font-weight: 600;
        font-size: 32px;
      }
    }
  }
}
</style>
