<template>
  <div :class="`my_input_wrapper ${this.labeltype} ${this.className}`">
    <input
      :class="[{ focused }, className]"
      v-bind="$attrs"
      :name="`${this.id}`"
      :type="`${this.type}`"
      :id="`${this.id}`"
      v-on:keyup.enter="$emit('enterKey')"
      @focus="focused = true;$emit('inputFocus')"
      v-model="inputVal"
      v-on:blur="$emit('inputBlur')"
      autocomplete="off"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      focused: false,
    };
  },
  props: {
    type: {
      type: String,
      default: "text",
    },
    id: {
      type: String,
      default: "text",
    },
    labeltype: {
      type: String,
      default: "",
    },
    className: {
      type: String,
      default: "",
    },
    format: {
      type: String,
      default: ""
    },
    ["value"]: {
      type: String,
      default: "",
    },
  },
  computed: {
    inputVal: {
      get() {
        let val = this.value
        if(this.$props.format === "currency"){
          const currency = this.$props.labeltype
          const locale = `en-${currency.substring(0,2)}`
          const numbersOnlyString = val.replace(/\D/gi,'')
          const formattedValue = Number(numbersOnlyString).toLocaleString(locale, {style:"currency", currency,  minimumFractionDigits: 0}).substring(1)
          return formattedValue != 0 ? formattedValue : numbersOnlyString;
        } 
        else return val
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.my_input_wrapper {
  position: relative;
  height: 55px;
  &.phone {
    &:before {
      content: "+91";
      width: 50px;
      border-radius: 10px 0 0 10px;
      height: 100%;
      display: flex;
      position: absolute;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      left: 0;
      border: 1px solid #d1d1d1;
      background: #eeeeee;
    }

    input {
      padding-left: 64px !important;
    }
  }

  &.INR {
    &:before {
      content: "₹";
      width: 50px;
      font-size: 20px;
      border-radius: 10px 0 0 10px;
      height: 100%;
      display: flex;
      padding-top: 3px;
      position: absolute;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      left: 0;
      border: 1px solid #d1d1d1;
      background: #eeeeee;
    }

    input {
      padding-left: 64px !important;
    }
  }
  &.USD {
    &:before {
      content: "$";
      width: 50px;
      font-size: 20px;
      border-radius: 10px 0 0 10px;
      height: 100%;
      display: flex;
      padding-top: 2px;
      position: absolute;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      left: 0;
      border: 1px solid #d1d1d1;
      background: #eeeeee;
    }

    input {
      padding-left: 64px !important;
    }
  }

  &.percentage {
    &:before {
      content: "%";
      width: 50px;
      font-size: 20px;
      border-radius: 10px 0 0 10px;
      height: 100%;
      display: flex;
      padding-top: 2px;
      position: absolute;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      left: 0;
      border: 1px solid #d1d1d1;
      background: #eeeeee;
    }

    input {
      padding-left: 64px !important;
    }
  }

  &.error {
    input {
      border-color: red !important;
    }

    &:before {
      border-color: red !important;
    }
  }
  input {
    width: 100%;
    height: 100%;
    padding: 17px 24px 15px 18px;
    font-weight: 400;
    font-size: 16px;
    color: black;
    outline: none;
    border-radius: 10px;
    box-shadow: none;
    border: 1px solid rgba(0, 0, 0, 0.18);
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type="number"] {
      -moz-appearance: textfield;
    }

    &::placeholder {
      color: #adadad;
    }

    &:focus {
      border: 1px solid #0000004d !important;
    }
    &.error {
      border-color: red !important;
    }
  }
}
</style>
